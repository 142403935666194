import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import { useEffect, useState } from "react";
import {
  CubeIcon,
  MapPinIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import TableComponent from "../../../templates/TableComponent/TableComponent";
import {
  getFetch,
  getTokenFromLocalStorage,
} from "../../../../redux/functions/function";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getCreateAuctionTemplateDetails,
  getUtilities,
  setAuctionBuyerDeliveryLocationDetails,
  setAuctionBuyerMunicipality,
  setAuctionBuyerShippingDetails,
  setAuctionBuyerShippingMethod,
  setAuctionBuyerTemplateID,
  setAuctionSelectedCommodities,
  setDeliveryLocationList,
  setNextAuctionTemplateName,
  setUtilityCommodities,
  setUtilityUnits,
} from "../../../../redux/functions/storageSlice";
import BuyerShippingDetailsType from "../../../../model/types/BuyerShippingDetailsType";
import {
  getCommodities,
  getMunicipalitiesAPI,
  getReverseAuctionTemplateDetailsAPI,
  getUnits,
} from "../../../../redux/functions/API";
import DeliveryLocationModel from "../../../../model/interface/DeliveryLocationModel";
import BuyerSelectedItemsType from "../../../../model/types/BuyerSelectedItemsType";
import { CommodityTypeMethodModel } from "../../../../model/interface/CommodityTypeMethodModel";

const MyAuctionTemplateListComp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  const createAuctionTemplateDetails = useAppSelector(
    getCreateAuctionTemplateDetails
  );
  const [auctionTemplateList, setAuctionTemplateList] = useState<any[]>([]);
  const [isAuctionTemplateListLoading, setIsAuctionTemplateListLoading] =
    useState<boolean>(true);
  const [isCommoditiesLoading, setIsCommoditiesLoading] =
    useState<boolean>(true);
  const [listOfCommodities, setListOfCommodities] = useState(
    utilities.commodities
  );
  const [isUnitsLoading, setIsUnitsLoading] = useState<boolean>(true);
  const [listOfUnits, setListOfUnits] = useState(utilities.units);

  // cja: get delivery location (municipalities) according to the selected shipping method
  useEffect(() => {
    if (createAuctionTemplateDetails.shippingDetails.shipping_method.id !== 0) {
      let shippingMethodID =
        createAuctionTemplateDetails.shippingDetails.shipping_method.id;
      /*
        shippingMethodID Legend:
          1 - Delivery
          2 - Pick-up
      */
      if (shippingMethodID === 1) {
        getMunicipalitiesAPI(_token, 2)
          .then((data: any) => {
            let newMunicipalities: DeliveryLocationModel[] = [];
            data.forEach((municipality: any) => {
              let singleMunicipality: DeliveryLocationModel = {
                id: 0,
                name: "",
              };

              singleMunicipality = {
                id: municipality.id,
                name: municipality.location,
              };

              newMunicipalities = [...newMunicipalities, singleMunicipality];
            });

            dispatch(setDeliveryLocationList(newMunicipalities));
            //setListOfDeliveryLocations(newMunicipalities);
          })
          .catch((err) => console.log(err));
      } else if (shippingMethodID === 2) {
        getMunicipalitiesAPI(_token, 2)
          .then((data: any) => {
            let newMunicipalities: DeliveryLocationModel[] = [];
            data.forEach((municipality: any) => {
              let singleMunicipality: DeliveryLocationModel = {
                id: 0,
                name: "",
              };

              singleMunicipality = {
                id: municipality.id,
                name: municipality.location,
              };

              newMunicipalities = [...newMunicipalities, singleMunicipality];
            });

            dispatch(setDeliveryLocationList(newMunicipalities));
            //setListOfDeliveryLocations(newMunicipalities);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [
    createAuctionTemplateDetails.shippingDetails.shipping_method,
    _token,
    dispatch,
  ]);

  // cja: this useEffect hook will check if commodity utilities did not exist from redux
  useEffect(() => {
    // if commodity types is empty
    if (listOfCommodities.length === 0 && isCommoditiesLoading)
      getCommodities().then((data: any) => {
        dispatch(setUtilityCommodities(data));
        setListOfCommodities(data);
        setIsCommoditiesLoading(false);
      });
  }, [dispatch, listOfCommodities, isCommoditiesLoading]);

  // cja: this useEffect hook will check if unit utility did not exist from redux
  useEffect(() => {
    // if units is empty
    if (listOfUnits.length === 0 && isUnitsLoading)
      getUnits().then((data: any) => {
        dispatch(setUtilityUnits(data));
        setListOfUnits(data);
        setIsUnitsLoading(false);
      });
  }, [dispatch, listOfUnits, isUnitsLoading]);

  // cja: useEffect hook to fetch the auction template list through API
  useEffect(() => {
    if (auctionTemplateList.length === 0 && isAuctionTemplateListLoading) {
      getFetch(
        `${process.env.REACT_APP_API_URL}/reverse_auction_template/list/get?buyer_id=${userData._id}`,
        _token
      ).then((response) => {
        if (response.statusCode === 200) {
          setAuctionTemplateList(response.data.items);
          setIsAuctionTemplateListLoading(false);
          // set the next auction template if buyer creates new template
          dispatch(
            setNextAuctionTemplateName(
              `Quotation Template ${response.data.items.length + 1}`
            )
          );
        }
      });
    }
  }, [
    dispatch,
    auctionTemplateList,
    _token,
    isAuctionTemplateListLoading,
    userData._id,
  ]);

  // cja: flag for the active nav item
  const [activeNav, setActiveNav] = useState<string>("Petroleum");
  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const nav_items = [
    {
      text: "Petroleum",
    },
    {
      text: "Non-Petroleum",
    },
  ];
  const setActiveNavTab = (active_item: string) => {
    setActiveNav(active_item);
  };

  const tableHeader = [
    {
      name: "template_name",
      value: "Template Name",
      icon: CubeIcon,
    },
    {
      name: "commodities",
      value: "Commodities",
      icon: CubeIcon,
    },
    {
      name: "volume",
      value: "Volume",
      icon: Square3Stack3DIcon,
    },
    {
      name: "location",
      value: "Location",
      icon: MapPinIcon,
    },
  ];

  // created a function for handling the table row click
  const handleRowClick = (reverse_auction_template_id: number | string) => {
    getReverseAuctionTemplateDetailsAPI(_token, reverse_auction_template_id)
      .then((response: any) => {
        if (response.statusCode === 200) {
          saveReverseAuctionTemplateDetails(response.data.items)
            .then(() => {
              navigate("/b/reverse_auction_template");
            })
            .catch(() => {
              console.log("Error-001: Error occured will saving to redux.");
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveReverseAuctionTemplateDetails = (data: any) => {
    return new Promise((resolve, reject) => {
      extractShippingMethod(data.details.shipping_method_id);
      extractDeliveryLocation(data.details.municipality_id);
      dispatch(
        setAuctionBuyerTemplateID(data.details.reverse_auction_template_id)
      );
      dispatch(setNextAuctionTemplateName(data.details.template_name));

      dispatch(
        setAuctionBuyerDeliveryLocationDetails(
          data.details.delivery_location_details
        )
      );

      dispatch(
        setAuctionSelectedCommodities(
          extractSelectedCommodities(data.commodities)
        )
      );

      resolve(true);
    });
  };

  // cja: function to extract the shipping method using the shipping method id param
  const extractShippingMethod = (shipping_method_id: number) => {
    utilities.shippingMethods.forEach((shipping_method: any) => {
      if (shipping_method.id === shipping_method_id) {
        dispatch(setAuctionBuyerShippingMethod(shipping_method));
      }
    });
  };

  // cja: function to extract the delivery location using the delivery location id param
  const extractDeliveryLocation = (municipality_id: number) => {
    getMunicipalitiesAPI(_token, 2)
      .then((data: any) => {
        data.forEach((location: any) => {
          if (location.id === municipality_id) {
            let deliveryLocation = {
              id: location.id,
              name: location.location,
            };
            dispatch(setAuctionBuyerMunicipality(deliveryLocation));
          }
        });
      })
      .catch((err) => console.log(err));
  };

  // cja: function to extract the commodity details using the commodity id param
  const extractCommodityDetails = (commodity_id: number) => {
    let commodityDetails: CommodityTypeMethodModel = {
      id: 0,
      name: "",
      icon: "",
    };
    utilities.commodities.length !== 0 &&
      utilities.commodities.forEach((commodity: any) => {
        if (commodity.id === commodity_id) {
          commodityDetails = commodity;
        }
      });
    return commodityDetails;
  };

  // cja: function to extract the unit details using the unit id param
  const extractUnitDetails = (unit_id: number) => {
    let unitDetails: CommodityTypeMethodModel = {
      id: 0,
      name: "",
      icon: "",
    };
    utilities.commodities.length !== 0 &&
      utilities.units.forEach((unit: any) => {
        if (unit.id === unit_id) {
          unitDetails = unit;
        }
      });
    return unitDetails;
  };

  // cja: function to extract the commodites
  const extractSelectedCommodities = (commodities_from_api: any) => {
    let updatedSelectedCommodities: BuyerSelectedItemsType[] =
      commodities_from_api.map((commodity: any) => ({
        product: extractCommodityDetails(commodity.commodity_id),
        unit: extractUnitDetails(commodity.unit_id),
        volume: commodity.volume,
      }));
    return updatedSelectedCommodities;
  };

  const clearCreateAuctionTemplateData = () => {
    // clear redux (wala pani gamit kay wala pa na save ang shipping details every type sa user)
    let updatedShippingDetails: BuyerShippingDetailsType = {
      shipping_method: {
        id: 0,
        name: "",
      },
      delivery_location: {
        id: 0,
        name: "",
      },
      delivery_location_details: "",
      delivery_date: "",
    };

    dispatch(setAuctionSelectedCommodities([]));
    dispatch(setAuctionBuyerShippingDetails(updatedShippingDetails));
  };

  const createTemplateClickHandler = () => {
    clearCreateAuctionTemplateData();
    navigate("/b/select_commodity");
  };

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="My Quotation Templates"
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <NavTabComponent
              key={Math.random()}
              nav_type="primary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
              left_content={
                <></>
                // <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full">
                //   <input
                //     type="text"
                //     name="account-number"
                //     id="account-number"
                //     className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                //     placeholder="Search template"
                //   />
                //   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5">
                //     <MagnifyingGlassIcon
                //       className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                //       aria-hidden="true"
                //     />
                //   </div>
                // </div>
              }
              right_content={
                <>
                  {auctionTemplateList.length > 0 && (
                    <ButtonComponent
                      text="Create Quotation Template"
                      utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:my-0 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-1/3 w-full"
                      onClick={createTemplateClickHandler}
                    />
                  )}
                </>
              }
            />

            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col text-lg w-full md:pt-2 md:pl-2 md:w-full">
                {activeNav === "Petroleum" && (
                  <>
                    {isAuctionTemplateListLoading ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={auctionTemplateList}
                        currentPage={1} // DC: static for now, lets make it dynamic soon
                        rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                        idStringProperty="reverse_auction_template_id"
                        handleRowClick={handleRowClick}
                        emptyState={{
                          primaryMessage: "No qoutation templates available.",
                          secondaryMessage:
                            "Get started by creating a new template.",
                          buttonText: "Create Quotation Template",
                          buttonOnClick() {
                            createTemplateClickHandler();
                          },
                        }}
                      />
                    )}
                  </>
                )}
                {activeNav === "Non-Petroleum" && (
                  <>
                    <div className="mx-auto md:mt-10 mt-8 text-gray-400">
                      <span className="md:text-sm text-xs">Coming Soon...</span>
                    </div>
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};

export default MyAuctionTemplateListComp;
