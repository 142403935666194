import { useEffect, useState } from "react";
import DashboardTitleComponent from "../../../templates/TitleComponent/DashboardTitleComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import FullRoundCardComponent from "../../../templates/CardComponent/FullRoundCardComponent";
import FeatureData from "./FeatureData";

const FeaturesComponent = () => {
  // declare states here...
  const [fullname, setFullname] = useState("");

  useEffect(() => {
    // decode the token
    const _token = getTokenFromLocalStorage() ?? "";
    const userData: TokenModel = jwt_decode(_token);
    // set to state
    setFullname(userData.name.split(" ")[0]);
  }, []);
  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-full px-4 py-4 sm:px-6 lg:px-8">
          <DashboardTitleComponent
            mainTitle={`Welcome, ${fullname}!`}
            subTitle={``}
          />
          <FullRoundCardComponent data={FeatureData} />
        </div>
      </div>
    </>
  );
};

export default FeaturesComponent;
