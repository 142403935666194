import React, { useEffect, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import {
	ArrowDownCircleIcon,
	CircleStackIcon,
	MapPinIcon,
	UserIcon,
} from "@heroicons/react/24/outline";
import TableComponent from "../../templates/TableComponent/TableComponent";
import Skeleton from "react-loading-skeleton";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
	getCommentDetails,
	getShipments,
	setCommentDetails,
	setShipmentsList,
} from "../../../redux/functions/storageSlice";
import {
	getCloseOrdersAPI,
	verificationAPI,
} from "../../../redux/functions/API";
import { TokenModel } from "../../../model/interface/TokenModel";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import ShipmentListType from "../../../model/types/ShipmentsListType";
import DropdownItemType from "../../../model/types/DropdownItemType";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import DropdownOptionComponent from "../../templates/DropdownOptionComponent/DropdownOptioncomponent";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import ToasterComponent from "../../templates/ToasterComponent/ToasterComponent";

const ShipmentsComp = () => {
	const dispatch = useAppDispatch();
	const myShipment = useAppSelector(getShipments);
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);
	const commentsDetails = useAppSelector(getCommentDetails);

	const [activeNav, setActiveNav] = useState<string>("All");
	const [isAllFetching, setIsAllFetching] = useState<boolean>(true);
	const [allShipmentList, setAllShipmentList] = useState<ShipmentListType[]>(
		myShipment.allShipmentsList
	);
	const [paymentVerificationList, setPaymentVerificationList] = useState<
		ShipmentListType[]
	>(myShipment.allShipmentsList);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
	const [selectedOption, setSelectedOption] = useState<string>("");
	const [isCommentDetailsEmpty, setIsCommentDetailsEmpty] =
		useState<boolean>(false);

	const selectedForVerification = myShipment.allShipmentsList.find(
		(list: any) => list.disbursement_details_id === selectedRowId
	);

	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [toastType, setToastType] = useState<string>("");

	const nav_items = [
		{
			text: "All",
		},
		{
			text: "Payment Verification",
		},
	];

	const fieldChangeHandler = (value: string | any, name: string) => {
		switch (name) {
			case "txtcommentsdetails":
				dispatch(setCommentDetails(value));
				break;
			default:
				break;
		}
	};

	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	const allHeader = [
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "buyer_location",
			value: "Buyer Location",
			icon: MapPinIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "supplier_location",
			value: "Supplier Depo",
			icon: MapPinIcon,
		},
		{
			name: "logistic_rate",
			value: "Amount",
			icon: CircleStackIcon,
		},
		{
			name: "is_disbursement_verified",
			value: "Status",
			icon: ArrowDownCircleIcon,
			customRender: (item: any) => (
				<span
					className={
						item.is_disbursement_verified === "For Verification"
							? "text-dealogikal-50"
							: item.is_disbursement_verified === "Verified"
							? "text-green-500"
							: ""
					}
				>
					{item.is_disbursement_verified}
				</span>
			),
		},
	];

	// fetching close order
	useEffect(() => {
		if (isAllFetching && myShipment.allShipmentsList.length === 0) {
			getCloseOrdersAPI(_token, 6, userData._id).then((response: any) => {
				if (response.statusCode === 200) {
					setIsAllFetching(false);
					const disbursementId = response.data.items.map(
						(item: any, index: number) => {
							const formattedAmount = new Intl.NumberFormat("en-US", {
								style: "currency",
								currency: "PHP", // Change currency as per your requirement
							}).format(parseFloat(item.logistic_rate));
							return {
								...item,
								row_id: item.id ?? index,
								logistic_rate: formattedAmount,
								shipping_method_id:
									item.shipping_method_id === 2 ? "Pick-up" : "Delivery",
								is_disbursement_verified:
									item.is_disbursement_verified === 0
										? "For Verification"
										: "Verified",
							};
						}
					);
					dispatch(setShipmentsList(disbursementId));
				} else {
					console.log("failed to fetch disbursement list");
				}
			});
		} else if (myShipment.allShipmentsList.length !== 0) {
			let updatedListOfAllShipments: ShipmentListType[] = [];
			let updatedListOfPaymentVerification: ShipmentListType[] = [];
			// if (Array.isArray(paymentList.disbursementList)) {
			myShipment.allShipmentsList.forEach((shipment: any) => {
				const newShipment: ShipmentListType = {
					buyer_id: shipment.buyer_id,
					buyer_name: shipment.buyer_name,
					calculate_amount: shipment.calculate_amount,
					delivery_date: shipment.delivery_date,
					deposit_slip_url: shipment.deposit_slip_url,
					disbursement_details_id: shipment.disbursement_details_id,
					disbursement_slip_url: shipment.disbursement_slip_url,
					is_disbursement_verified: shipment.is_disbursement_verified,
					is_payment_verified: shipment.is_payment_verified,
					logistic_name: shipment.logistic_name,
					payment_details_id: shipment.payment_details_id,
					plate_number: shipment.plate_number,
					logistic_rate: shipment.logistic_rate,
					reverse_auction_id: shipment.reverse_auction_id,
					shipping_method_id: shipment.shipping_method_id,
					user_id: shipment.user_id,
					supplier_name: shipment.supplier_name,
					supplier_location: shipment.supplier_location,
					buyer_location: shipment.buyer_location,
				};

				updatedListOfAllShipments = [...updatedListOfAllShipments, newShipment];
				if (newShipment.is_disbursement_verified === "For Verification") {
					updatedListOfPaymentVerification.push(newShipment);
				}
			});
			setAllShipmentList(updatedListOfAllShipments);
			setPaymentVerificationList(updatedListOfPaymentVerification);
			setIsAllFetching(false);
		}
	}, [
		isAllFetching,
		userData._id,
		_token,
		myShipment.allShipmentsList.length,
		dispatch,
		myShipment.allShipmentsList,
	]);

	const dropdown_items: DropdownItemType[] = [
		{
			item_text: "Verify",
			onClick: () => setSelectedOption("Verify"),
		},
		{
			item_text: "Reconcile",
			onClick: () => setSelectedOption("Reconcile"),
		},
	];

	const clearErrors = () => {
		setIsCommentDetailsEmpty(false);
	};

	// handler for updating status
	const verificationHandler = () => {
		let statusId: number;

		// Convert the string status back to a number
		switch (selectedForVerification?.is_disbursement_verified) {
			case "For Verification":
				statusId = 1;
				break;
			default:
				statusId = 0; // Default to 0 if status is unknown
		}

		const data: any = {
			status_id: statusId,
			disbursement_details_id: selectedForVerification?.disbursement_details_id,
			note: commentsDetails,
		};

		verificationAPI(_token, data).then((response: any) => {
			if (response.statusCode === 200) {
				setAllShipmentList((prevList) =>
					prevList.filter(
						(item) =>
							item.disbursement_details_id !==
							selectedForVerification?.disbursement_details_id
					)
				);
				setPaymentVerificationList((prevList) =>
					prevList.filter(
						(item) =>
							item.disbursement_details_id !==
							selectedForVerification?.disbursement_details_id
					)
				);
				clearErrors();
				dispatch(setCommentDetails(""));
				setIsModalOpen(false);
				setToastMessage("Successfully Verified");
				setToastType("success");
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			}
		});
	};

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<ToasterComponent
							isOpen={isToastOpen}
							label={toastMessage}
							onClose={setIsToastOpen}
							type={toastType}
						/>

						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Shipments"
						/>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<NavTabComponent
							key={Math.random()}
							nav_type="secondary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
						/>
						{/**Start here*/}
						<div className="flex flex-wrap justify-center">
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{activeNav === "All" && (
									<>
										{isAllFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={allHeader}
												tableData={allShipmentList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="disbursement_details_id"
												handleRowClick={(disbursement_details_id: string) => {
													setSelectedRowId(parseInt(disbursement_details_id));
													setIsModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Payment Verification" && (
									<>
										{isAllFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={allHeader}
												tableData={paymentVerificationList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="disbursement_details_id"
												handleRowClick={(disbursement_details_id: string) => {
													setSelectedRowId(parseInt(disbursement_details_id));
													setIsModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
							</div>
						</div>
						{/** Modal Component */}
						<ModalComponent
							isOpen={isModalOpen}
							onCloseHandler={() => {
								setIsModalOpen(false);
								setSelectedOption("");
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Payment Verification
									</span>
								</div>
							}
							body={
								<>
									{selectedForVerification !== undefined && (
										<>
											<div className="mx-5 items-center py-16">
												<div className="flex flex-col justify-between gap-8 md:flex-row -translate-y-9">
													<div className="flex flex-col gap-5 w-full">
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Buyer Name
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md w-full">
																<p className="text-base text-gray-700">
																	{selectedForVerification.buyer_name}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Shipment Type
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedForVerification.shipping_method_id}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Delivery Date
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedForVerification.delivery_date}
																</p>
															</div>
														</div>
													</div>

													<div className="md:ml-10 md:w-full">
														<div className="flex flex-col gap-1 mb-5">
															<span className="text-sm text-gray-400">
																Amount
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md w-full">
																<p className="text-base text-gray-700">
																	{selectedForVerification.logistic_rate}
																</p>
															</div>
														</div>
														<div className="items-center">
															<img
																className="w-full h-52 md:h-56"
																src={selectedForVerification.disbursement_slip_url}
																alt="uploaded"
															/>
														</div>
														<div className="mt-4 mr-5">
															<DropdownOptionComponent
																items={dropdown_items}
																main_text="Options"
															/>
														</div>
														<div className="pt-16 ml-6">
															{selectedOption === "Verify" && (
																<ButtonComponent
																	text="Confirm"
																	utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer"
																	 duration-500 rounded-full font-normal md:w-11/12 w-full`}
																	onClick={verificationHandler}
																/>
															)}
														</div>
													</div>
												</div>
												{selectedOption === "Reconcile" && (
													<>
														<textarea
															id="txtcommentsdetails"
															name="txtcommentsdetails"
															value={commentsDetails}
															placeholder="Please input reason"
															rows={3}
															className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																isCommentDetailsEmpty ? "red-600" : "gray-300"
															} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
															onChange={(e) =>
																fieldChangeHandler(
																	e.target.value,
																	"txtcommentsdetails"
																)
															}
														/>
														<div className="mt-5">
															<ButtonComponent
																text="Send"
																utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer"
																	 duration-500 rounded-full font-normal w-full`}
																onClick={verificationHandler}
															/>
														</div>
													</>
												)}
											</div>
										</>
									)}
								</>
							}
						/>
					</>
				}
			/>
		</>
	);
};

export default ShipmentsComp;
