import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { StorageStatesModel } from "../../model/redux/interface/StorageStatesModel";
import { CommodityTypeMethodModel } from "../../model/interface/CommodityTypeMethodModel";
import { UnitsModel } from "../../model/interface/UnitsModel";
import ShippingMethodModel from "../../model/interface/ShippingMethodModel";
import OfferModel from "../../model/interface/OfferModel";
import ProductType from "../../model/types/ProductType";
import SingleOrderDataType from "../../model/types/SingleOrderDataType";
import BuyerSelectedItemsType from "../../model/types/BuyerSelectedItemsType";
import BuyerShippingDetailsType from "../../model/types/BuyerShippingDetailsType";
import { MyQuotationModel } from "../../model/interface/MyQuotationModel";
import DeliveryLocationModel from "../../model/interface/DeliveryLocationModel";
import { VehicleTypeModel } from "../../model/interface/VehicleTypeModel";
import { VehicleWheelModel } from "../../model/interface/VehicleWheelModel";
import { VehicleManufactureModel } from "../../model/interface/VehicleManufactureModel";
import { VehicleCompartmentModel } from "../../model/interface/VehicleCompartmentModel";
import VehicleListType from "../../model/types/VehicleListType";
import RateType from "../../model/types/RateType";
import SocketResponseDataType from "../../model/types/SocketResponseDataType";
import SocketConnectionType from "../../model/types/SocketConnectionType";
import NotificationType from "../../model/types/NotificationType";
import OfferConfirmationModel from "../../model/interface/OfferConfirmationModel";
import ReverseAuctionModel from "../../model/interface/ReverseAuctionModel";
import DisbursementListType from "../../model/types/DisbursementlistType";
import ForVerificationListType from "../../model/types/ForVerificationListType";
import SupplierAndVendorType from "../../model/types/SupplierAndVendorType";
import CloseOrdersListType from "../../model/types/CloseOrdersListType";
import SalesOrderListType from "../../model/types/SalesOrderListType";
import ShipmentListType from "../../model/types/ShipmentsListType";

const initialState: StorageStatesModel = {
  sessionData: {
    path: "",
    message: "",
  },
  dashboardData: {
    breadcrumb: "",
  },
  _token: "",
  createOfferFields: {
    selectedProducts: "",
    offerFields: {
      offerName: "",
      shippingMethodID: "",
      deliveryLocation: { id: "", name: "" },
      municipalDeliveryLocation: { id: "", name: "" },
    },
  },
  offerDetails: {
    offerID: "",
    offerName: "",
    sold: "",
    views: "",
    remaining: "",
  },
  offerOrderList: {
    orders: [],
  },
  utilities: {
    commodities: [],
    commodityTypes: [],
    commodityTypeMethods: [],
    offerList: [],
    offerListConfirmation: [],
    shippingMethods: [],
    deliveryLocations: [],
    islandDeliveryLocations: [],
    municipalityDeliveryLocations: [],
    productList: [],
    quotationList: [],
    units: [],
  },
  buyCommodityDetails: {
    // this state will be remove soon
    selectedProducts: [],
    shippingDetails: {
      shipping_method: {
        id: 0,
        name: "",
      },
      delivery_island: {
        id: 0,
        name: "",
      },
      delivery_location: {
        id: 0,
        name: "",
      },
      delivery_location_details: "",
      delivery_date: "",
    },
  },
  createAuctionTemplateDetails: {
    // new version of the buyCommodityDetails state
    auctionTemplateID: 0,
    selectedProducts: [],
    shippingDetails: {
      shipping_method: {
        id: 0,
        name: "",
      },
      delivery_island: {
        id: 0,
        name: "",
      },
      delivery_location: {
        id: 0,
        name: "",
      },
      delivery_location_details: "",
      delivery_date: "",
    },
  },
  nextAuctionTemplateName: "",
  quotationDetails: {
    // this state will be removed soon
    quotationID: 0,
  },
  productDetails: {
    product_id: 0,
    commodity_id: 0,
    commodity_type_id: 0,
    commodity_type: "",
    commodity_name: "",
    unit_name: "",
    stocks: "",
  },
  productPageAction: "add",
  updateProduct: {
    product_id: 0,
    commodity_id: 0,
    commodity_type_id: 0,
    commodity_type: "",
    commodity_name: "",
    unit_name: "",
    stocks: "",
  },
  vehiclesDetails: {
    selectedVehicles: "",
    selectedVehicleList: [],
    vehicleType: [],
    vehicleWheel: [],
    vehicleManufacture: [],
    vehicleCompartment: [],
    rateList: [],
    selectedDeliveryZone: "",
    selectedSupplier: "",
    confirmTableDetails: [],
  },
  nextVehicleTemplateName: "",
  nextRateTemplateName: "",
  totalVolume: 0,
  socketConnection: {
    isConnected: false,
    currentConnectionId: "",
  },
  socketResponseData: {
    payload: {
      data: null,
      key: "",
      message: "",
    },
    status: null,
  },
  isGlobalModalViewed: false,
  offerBookingId: 0,
  isSnackbarViewed: false,
  notificationList: [],
  reverseAuctionList: [],
  paymentList: {
    forVerificationList: [],
    disbursementList: [],
    supplierAndVendorDetails: [],
    salesOrderList: [],
  },
  mySales: {
    closeOrdersList: [],
    salesOrderList: [],
  },
  shipments: {
    allShipmentsList: [],
  },
  reverseAuctionDetails: {
    reverse_auction_data: {
      auction_time_remaining: 0,
      delivery_date: "",
      delivery_location: "",
      delivery_location_details: "",
      municipality_id: 0,
      reverse_auction_id: 0,
      reverse_auction_status_id: 0,
      shipping_method: "",
      shipping_method_id: 0,
    },
    buyer_data: {
      buyer_details: {
        company_address: "",
        company_logo: "",
        company_name: "",
      },
      buyer_id: 0,
      commodities: {
        0: {
          commodity_icon: "",
          commodity_id: 0,
          commodity_name: "",
          volume: 0,
        },
      },
    },
    supplier_data: {
      cheapestSupplier: {
        id: 0,
        island_id: 0,
        location: "",
        municipality_id: 0,
        offer_name: "",
        offers: {
          commodity_icon: "",
          commodity_id: 0,
          commodity_name: "",
          maximum_quantity: 0,
          minimum_quantity: 0,
          price: 0,
          product_id: 0,
        },
        shipping_method_id: 0,
        supplier_details: {
          company_address: "",
          company_logo: "",
          company_name: "",
        },
        supplier_id: 0,
      },
      supplier_details: {
        0: {
          id: 0,
          island_id: 0,
          location: "",
          municipality_id: 0,
          offer_name: "",
          offers: {
            commodity_icon: "",
            commodity_id: 0,
            commodity_name: "",
            maximum_quantity: 0,
            minimum_quantity: 0,
            price: 0,
            product_id: 0,
          },
          shipping_method_id: 0,
          supplier_details: {
            company_address: "",
            company_logo: "",
            company_name: "",
          },
          supplier_id: 0,
        },
      },
    },
  },
  commentDetails: "",
};

export const storageSlice = createSlice({
  name: "storage",
  initialState,
  // DC: The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // DC: Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes

    setBreadcrumb: (state, action: PayloadAction<string>) => {
      state.dashboardData.breadcrumb = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state._token = action.payload;
    },
    // cja: reducers in creating new offer (start)
    setSelected_Products: (state, action: PayloadAction<string>) => {
      state.createOfferFields.selectedProducts = action.payload;
    },
    setOfferName: (state, action: PayloadAction<string>) => {
      state.createOfferFields.offerFields.offerName = action.payload;
    },
    setShippingMethodID: (state, action: PayloadAction<string>) => {
      state.createOfferFields.offerFields.shippingMethodID = action.payload;
    },
    setDeliveryLocation: (
      state,
      action: PayloadAction<DeliveryLocationModel>
    ) => {
      state.createOfferFields.offerFields.deliveryLocation = action.payload;
    },
    setMunicipalDeliveryLocation: (
      state,
      action: PayloadAction<DeliveryLocationModel>
    ) => {
      state.createOfferFields.offerFields.municipalDeliveryLocation =
        action.payload;
    },
    // cja: reducers in creating new offer (end)
    // cja: reducers in storing offer details (start)
    setOfferDetailsOfferID: (state, action: PayloadAction<string>) => {
      state.offerDetails.offerID = action.payload;
    },
    setOfferDetailsOfferName: (state, action: PayloadAction<string>) => {
      state.offerDetails.offerName = action.payload;
    },
    setOfferDetailsSold: (state, action: PayloadAction<string>) => {
      state.offerDetails.sold = action.payload;
    },
    setOfferDetailsViews: (state, action: PayloadAction<string>) => {
      state.offerDetails.views = action.payload;
    },
    setOfferDetailsRemaining: (state, action: PayloadAction<string>) => {
      state.offerDetails.remaining = action.payload;
    },
    setOfferOrderList: (
      state,
      action: PayloadAction<SingleOrderDataType[]>
    ) => {
      state.offerOrderList.orders = action.payload;
    },
    // cja: reducers in storing offer details (end)
    setUtilityCommodities: (
      state,
      action: PayloadAction<CommodityTypeMethodModel[]>
    ) => {
      state.utilities.commodities = action.payload;
    },
    setUtilityCommodityTypes: (
      state,
      action: PayloadAction<CommodityTypeMethodModel[]>
    ) => {
      state.utilities.commodityTypes = action.payload;
    },
    setUtilityCommodityTypeMethods: (
      state,
      action: PayloadAction<CommodityTypeMethodModel[]>
    ) => {
      state.utilities.commodityTypeMethods = action.payload;
    },
    setUtilityProductList: (state, action: PayloadAction<ProductType[]>) => {
      state.utilities.productList = action.payload;
    },
    setUtilityMyQuotationList: (
      state,
      action: PayloadAction<MyQuotationModel[]>
    ) => {
      state.utilities.quotationList = action.payload;
    },
    setUtilityOfferList: (state, action: PayloadAction<OfferModel[]>) => {
      state.utilities.offerList = action.payload;
    },
    setUtilityOfferListConfirmation: (
      state,
      action: PayloadAction<OfferConfirmationModel[]>
    ) => {
      state.utilities.offerListConfirmation = action.payload;
    },
    setShippingMethods: (
      state,
      action: PayloadAction<ShippingMethodModel[]>
    ) => {
      state.utilities.shippingMethods = action.payload;
    },
    setDeliveryLocationList: (
      state,
      action: PayloadAction<DeliveryLocationModel[]>
    ) => {
      state.utilities.deliveryLocations = action.payload;
    },
    setMunicipalityDeliveryLocationList: (
      state,
      action: PayloadAction<DeliveryLocationModel[]>
    ) => {
      state.utilities.municipalityDeliveryLocations = action.payload;
    },
    setIslandDeliveryLocationList: (
      state,
      action: PayloadAction<DeliveryLocationModel[]>
    ) => {
      state.utilities.islandDeliveryLocations = action.payload;
    },
    setUtilityUnits: (state, action: PayloadAction<UnitsModel[]>) => {
      state.utilities.units = action.payload;
    },
    // cja: reducers for buying commodities (start)
    // to be remove soon
    setSelectedCommodityProducts: (
      state,
      action: PayloadAction<BuyerSelectedItemsType[]>
    ) => {
      state.buyCommodityDetails.selectedProducts = action.payload;
    },
    // to be remove soon
    setBuyerShippingDetails: (
      state,
      action: PayloadAction<BuyerShippingDetailsType>
    ) => {
      state.buyCommodityDetails.shippingDetails = action.payload;
    },
    // to be remove soon
    setQuotationID: (state, action: PayloadAction<number>) => {
      state.quotationDetails.quotationID = action.payload;
    },
    setAuctionBuyerTemplateID: (state, action: PayloadAction<number>) => {
      state.createAuctionTemplateDetails.auctionTemplateID = action.payload;
    },
    setAuctionBuyerMunicipality: (
      state,
      action: PayloadAction<DeliveryLocationModel>
    ) => {
      state.createAuctionTemplateDetails.shippingDetails.delivery_location =
        action.payload;
    },
    setAuctionBuyerIsland: (
      state,
      action: PayloadAction<DeliveryLocationModel>
    ) => {
      state.createAuctionTemplateDetails.shippingDetails.delivery_island =
        action.payload;
    },
    setAuctionBuyerShippingMethod: (
      state,
      action: PayloadAction<ShippingMethodModel>
    ) => {
      state.createAuctionTemplateDetails.shippingDetails.shipping_method =
        action.payload;
    },
    setAuctionBuyerDeliveryLocationDetails: (
      state,
      action: PayloadAction<string>
    ) => {
      state.createAuctionTemplateDetails.shippingDetails.delivery_location_details =
        action.payload;
    },
    setAuctionSelectedCommodities: (
      state,
      action: PayloadAction<BuyerSelectedItemsType[]>
    ) => {
      state.createAuctionTemplateDetails.selectedProducts = action.payload;
    },
    setAuctionBuyerShippingDetails: (
      state,
      action: PayloadAction<BuyerShippingDetailsType>
    ) => {
      state.createAuctionTemplateDetails.shippingDetails = action.payload;
    },
    setNextAuctionTemplateName: (state, action: PayloadAction<string>) => {
      state.nextAuctionTemplateName = action.payload;
    },
    setProductDetails: (state, action: PayloadAction<ProductType>) => {
      state.productDetails = action.payload;
    },
    setProductPageAction: (state, action: PayloadAction<string>) => {
      state.productPageAction = action.payload;
    },
    setUpdateProduct: (state, action: PayloadAction<ProductType>) => {
      state.updateProduct = action.payload;
    },
    setVehicleType: (state, action: PayloadAction<VehicleTypeModel[]>) => {
      state.vehiclesDetails.vehicleType = action.payload;
    },
    setVehicleWheel: (state, action: PayloadAction<VehicleWheelModel[]>) => {
      state.vehiclesDetails.vehicleWheel = action.payload;
    },
    setVehicleManufacture: (
      state,
      action: PayloadAction<VehicleManufactureModel[]>
    ) => {
      state.vehiclesDetails.vehicleManufacture = action.payload;
    },
    setVehicleCompartment: (
      state,
      action: PayloadAction<VehicleCompartmentModel[]>
    ) => {
      state.vehiclesDetails.vehicleCompartment = action.payload;
    },
    setTotalVolume: (state, action: PayloadAction<number>) => {
      state.totalVolume = action.payload;
    },
    setSelectedVehicleList: (
      state,
      action: PayloadAction<VehicleListType[]>
    ) => {
      state.vehiclesDetails.selectedVehicleList = action.payload;
    },
    setNextVehicleTemplateName: (state, action: PayloadAction<string>) => {
      state.nextVehicleTemplateName = action.payload;
    },
    setSessionData: (
      state,
      action: PayloadAction<{ path: string; message: string }>
    ) => {
      state.sessionData = action.payload;
    },
    setSelected_Vehicles: (state, action: PayloadAction<string>) => {
      state.vehiclesDetails.selectedVehicles = action.payload;
    },
    setNextRateTemplateName: (state, action: PayloadAction<string>) => {
      state.nextRateTemplateName = action.payload;
    },
    setRateList: (state, action: PayloadAction<RateType[]>) => {
      state.vehiclesDetails.rateList = action.payload;
    },
    setSelectedDeliveryZone: (state, action: PayloadAction<string>) => {
      state.vehiclesDetails.selectedDeliveryZone = action.payload;
    },
    setSelectedAccreditSupplier: (state, action: PayloadAction<string>) => {
      state.vehiclesDetails.selectedSupplier = action.payload;
    },
    setSocketConnection: (
      state,
      action: PayloadAction<SocketConnectionType>
    ) => {
      state.socketConnection = action.payload;
    },
    setSocketResponseData: (
      state,
      action: PayloadAction<SocketResponseDataType>
    ) => {
      state.socketResponseData = action.payload;
    },
    setIsGlobalModalViewed: (state, action: PayloadAction<boolean>) => {
      state.isGlobalModalViewed = action.payload;
    },
    setOfferBookingId: (state, action: PayloadAction<number>) => {
      state.offerBookingId = action.payload;
    },
    setIsSnackbarViewed: (state, action: PayloadAction<boolean>) => {
      state.isSnackbarViewed = action.payload;
    },
    setNotificationList: (state, action: PayloadAction<NotificationType[]>) => {
      state.notificationList = action.payload;
    },
    setDisbursementList: (
      state,
      action: PayloadAction<DisbursementListType[]>
    ) => {
      state.paymentList.disbursementList = action.payload;
    },
    setCloseOrdersList: (
      state,
      action: PayloadAction<CloseOrdersListType[]>
    ) => {
      state.mySales.closeOrdersList = action.payload;
    },
    setSalesOrderList: (state, action: PayloadAction<SalesOrderListType[]>) => {
      state.mySales.salesOrderList = action.payload;
    },
    setShipmentsList: (state, action: PayloadAction<ShipmentListType[]>) => {
      state.shipments.allShipmentsList = action.payload;
    },
    setForVerificationLists: (
      state,
      action: PayloadAction<ForVerificationListType[]>
    ) => {
      state.paymentList.forVerificationList = action.payload;
    },
    setSupplierAndVendorDetails: (
      state,
      action: PayloadAction<SupplierAndVendorType[]>
    ) => {
      state.paymentList.supplierAndVendorDetails = action.payload;
    },
    clearNotificationList: (state) => {
      state.notificationList = [];
    },
    setCommentDetails: (state, action: PayloadAction<string>) => {
      state.commentDetails = action.payload;
    },
    // (start) janm: add here clear what's need to be cleared in redux when user logged out =============================================>
    clearAllInRedux(state) {
      // use this syntax if all in redux to be cleared
      state.sessionData = {
        path: "",
        message: "",
      };
      state.dashboardData = {
        breadcrumb: "",
      };
      state._token = "";

      state.createOfferFields = {
        selectedProducts: "",
        offerFields: {
          offerName: "",
          shippingMethodID: "",
          deliveryLocation: { id: "", name: "" },
          municipalDeliveryLocation: { id: "", name: "" },
        },
      };
      state.offerDetails = {
        offerID: "",
        offerName: "",
        sold: "",
        views: "",
        remaining: "",
      };
      state.offerOrderList = {
        orders: [],
      };
      state.utilities = {
        commodities: [],
        commodityTypes: [],
        commodityTypeMethods: [],
        offerList: [],
        offerListConfirmation: [],
        shippingMethods: [],
        deliveryLocations: [],
        islandDeliveryLocations: [],
        municipalityDeliveryLocations: [],
        productList: [],
        quotationList: [],
        units: [],
      };
      state.buyCommodityDetails = {
        // this state will be remove soon
        selectedProducts: [],
        shippingDetails: {
          shipping_method: {
            id: 0,
            name: "",
          },
          delivery_island: {
            id: 0,
            name: "",
          },
          delivery_location: {
            id: 0,
            name: "",
          },
          delivery_location_details: "",
          delivery_date: "",
        },
      };
      state.createAuctionTemplateDetails = {
        // new version of the buyCommodityDetails state
        auctionTemplateID: 0,
        selectedProducts: [],
        shippingDetails: {
          shipping_method: {
            id: 0,
            name: "",
          },
          delivery_island: {
            id: 0,
            name: "",
          },
          delivery_location: {
            id: 0,
            name: "",
          },
          delivery_location_details: "",
          delivery_date: "",
        },
      };
      // Use this syntax if it is not necessary to clear it
      state.nextAuctionTemplateName = "";
      state.quotationDetails = {
        // this state will be removed soon
        quotationID: 0,
      };
      state.productDetails = {
        product_id: 0,
        commodity_id: 0,
        commodity_type_id: 0,
        commodity_type: "",
        commodity_name: "",
        unit_name: "",
        stocks: "",
      };
      state.productPageAction = "add";
      state.updateProduct = {
        product_id: 0,
        commodity_id: 0,
        commodity_type_id: 0,
        commodity_type: "",
        commodity_name: "",
        unit_name: "",
        stocks: "",
      };
      state.vehiclesDetails = {
        selectedVehicles: "",
        selectedVehicleList: [],
        vehicleType: [],
        vehicleWheel: [],
        vehicleManufacture: [],
        vehicleCompartment: [],
        rateList: [],
        selectedDeliveryZone: "",
        selectedSupplier: "",
        confirmTableDetails: [],
      };
      state.nextVehicleTemplateName = "";
      state.nextRateTemplateName = "";
      state.totalVolume = 0;
      state.socketConnection = {
        isConnected: false,
        currentConnectionId: "",
      };
      state.socketResponseData = {
        payload: {
          data: null,
          key: "",
          message: "",
        },
        status: null,
      };
      state.isGlobalModalViewed = false;
      state.offerBookingId = 0;
      state.isSnackbarViewed = false;
      state.notificationList = [];
    },
    // (end) janm: add here clear what's need to be cleared in redux when user logged out =============================================>
    setReverseAuctionList: (
      state,
      action: PayloadAction<ReverseAuctionModel[]>
    ) => {
      state.reverseAuctionList = action.payload;
    },
    setReverseAuctionDetails: (state, action: PayloadAction<any>) => {
      state.reverseAuctionDetails = action.payload;
    },
    // cja: reducers for buying commodities (end)
  },
});

export const {
  clearAllInRedux,
  setBreadcrumb,
  setToken,
  setUtilityCommodities,
  setUtilityCommodityTypes,
  setUtilityCommodityTypeMethods,
  setUtilityProductList,
  setUtilityMyQuotationList,
  setUtilityOfferList,
  setUtilityOfferListConfirmation,
  setSelected_Products,
  setOfferName,
  setShippingMethodID,
  setDeliveryLocation,
  setMunicipalDeliveryLocation,
  setUtilityUnits,
  setShippingMethods,
  setDeliveryLocationList,
  setIslandDeliveryLocationList,
  setMunicipalityDeliveryLocationList,
  setOfferDetailsOfferID,
  setOfferDetailsOfferName,
  setOfferDetailsRemaining,
  setOfferDetailsSold,
  setOfferDetailsViews,
  setOfferOrderList,
  setSelectedCommodityProducts,
  setBuyerShippingDetails, // to be remove soon
  setAuctionBuyerTemplateID,
  setAuctionBuyerShippingMethod,
  setAuctionBuyerIsland,
  setAuctionBuyerDeliveryLocationDetails,
  setAuctionBuyerMunicipality,
  setAuctionSelectedCommodities,
  setAuctionBuyerShippingDetails,
  setQuotationID, // to be remove soon
  setNextAuctionTemplateName,
  setProductDetails,
  setProductPageAction,
  setUpdateProduct,
  setVehicleType,
  setVehicleWheel,
  setVehicleManufacture,
  setVehicleCompartment,
  setTotalVolume,
  setSelectedVehicleList,
  setNextVehicleTemplateName,
  setSelected_Vehicles,
  setNextRateTemplateName,
  setSessionData,
  setRateList,
  setSelectedDeliveryZone,
  setSelectedAccreditSupplier,
  setSocketConnection,
  setSocketResponseData,
  setIsGlobalModalViewed,
  setOfferBookingId,
  setIsSnackbarViewed,
  setNotificationList,
  clearNotificationList,
  setReverseAuctionList,
  setReverseAuctionDetails,
  setDisbursementList,
  setSupplierAndVendorDetails,
  setForVerificationLists,
  setCommentDetails,
  setCloseOrdersList,
  setSalesOrderList,
  setShipmentsList,
} = storageSlice.actions;

// DC: The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.storage.value)`
export const getBreadcrumb = (state: RootState) =>
  state.reduxStorage.dashboardData.breadcrumb;
export const getToken = (state: RootState) => state.reduxStorage._token;
export const getCreateOfferFields = (state: RootState) =>
  state.reduxStorage.createOfferFields;
export const getUtilities = (state: RootState) => state.reduxStorage.utilities;
export const getOfferDetails = (state: RootState) =>
  state.reduxStorage.offerDetails;
export const getOfferOrderList = (state: RootState) =>
  state.reduxStorage.offerOrderList;
export const getBuyCommodityDetails = (state: RootState) =>
  state.reduxStorage.buyCommodityDetails;
export const getCreateAuctionTemplateDetails = (state: RootState) =>
  state.reduxStorage.createAuctionTemplateDetails;
export const getQuotationDetails = (state: RootState) =>
  state.reduxStorage.quotationDetails;
export const getNextAuctionTemplateName = (state: RootState) =>
  state.reduxStorage.nextAuctionTemplateName;
export const getProductDetails = (state: RootState) =>
  state.reduxStorage.productDetails;
export const getProductPageAction = (state: RootState) =>
  state.reduxStorage.productPageAction;
export const getupdateProduct = (state: RootState) =>
  state.reduxStorage.updateProduct;
export const getVehiclesDetails = (state: RootState) =>
  state.reduxStorage.vehiclesDetails;
export const getTotalVolume = (state: RootState) =>
  state.reduxStorage.totalVolume;
export const getNextVehicleTemplateName = (state: RootState) =>
  state.reduxStorage.nextVehicleTemplateName;
export const getNextRateTemplateName = (state: RootState) =>
  state.reduxStorage.nextRateTemplateName;
export const getSessionData = (state: RootState) =>
  state.reduxStorage.sessionData;
export const getSocketConnection = (state: RootState) =>
  state.reduxStorage.socketConnection;
export const getSocketResponseData = (state: RootState) =>
  state.reduxStorage.socketResponseData;
export const getIsGlobalModalViewed = (state: RootState) =>
  state.reduxStorage.isGlobalModalViewed;
export const getOfferBookingId = (state: RootState) =>
  state.reduxStorage.offerBookingId;
export const getIsSnackbarViewed = (state: RootState) =>
  state.reduxStorage.isSnackbarViewed;
export const getNotificationList = (state: RootState) =>
  state.reduxStorage.notificationList;
export const getReverseAuctionList = (state: RootState) =>
  state.reduxStorage.reverseAuctionList;
export const getPaymentList = (state: RootState) =>
  state.reduxStorage.paymentList;
export const getReverseAuctionDetails = (state: RootState) =>
  state.reduxStorage.reverseAuctionDetails;
export const getCommentDetails = (state: RootState) =>
  state.reduxStorage.commentDetails;
export const getShipments = (state: RootState) => state.reduxStorage.shipments;
export const getCloseOrders = (state: RootState) => state.reduxStorage.mySales;
export default storageSlice.reducer;
