import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import {
  MagnifyingGlassIcon,
  RectangleStackIcon,
  CubeIcon,
  PlusCircleIcon,
  TagIcon,
  TruckIcon,
  MapPinIcon,
  TrophyIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import OfferType from "../../../../model/types/OfferType";
import ProductType from "../../../../model/types/ProductType";
import SelectedProductsType from "../../../../model/types/SelectedProductsType";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import DropdownComponent from "../../../templates/DropdownComponent/DropdownComponent";
import DropdownItemType from "../../../../model/types/DropdownItemType";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import EmptyStateComp from "../../../templates/EmptyStateComponent/EmptyStateComp";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import {
  formatToReadableDate,
  convertSecondsToDHMS,
  formatNumberWithComma,
  generateKey,
  getFetch,
  getTokenFromLocalStorage,
  manageBreadcrumb,
} from "../../../../redux/functions/function";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getUtilities,
  setOfferDetailsOfferID,
  setOfferDetailsOfferName,
  setOfferDetailsRemaining,
  setOfferDetailsSold,
  setOfferDetailsViews,
  setOfferOrderList,
  setSelected_Products,
  setUtilityCommodityTypes,
  setUtilityOfferList,
  setUtilityOfferListConfirmation,
  setUtilityProductList,
} from "../../../../redux/functions/storageSlice";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import {
  getCommodityTypes,
  getOfferList,
  getOfferListConfirmation,
  getProductList,
} from "../../../../redux/functions/API";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import OfferListConfirmationType from "../../../../model/types/OfferListConfirmationType";

const OfferListComp: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<
    SelectedProductsType[]
  >([]);
  const [selectedProductID, setSelectedProductID] = useState<number[]>([]);
  const [isDisplaySelectionError, setIsDisplaySelectionError] =
    useState<boolean>(false);
  const createNewProductHandler = () => {
    navigate("/s/choose_commodity");
  };
  // cja: local state for storing product list
  const [listOfProducts, setListOfProducts] = useState<ProductType[]>([]);
  const [listOfOffers, setListOfOffers] = useState<OfferType[]>([]);

  const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);

  const [isOfferListLoading, setIsOfferListLoading] = useState(true);
  const [isProductListLoading, setIsProductListLoading] = useState(true);
  const [isCommodityTypeListLoading, setIsCommodityTypeListLoading] =
    useState(true);

  // cja: flag for the active nav item
  const [offerPageActiveNav, setOfferPageActiveNav] =
    useState<string>("Published");
  const [offerStatusToDisplay, setOfferStatusToDisplay] = useState<number>(1);

  const [isOfferListConfirmationLoading, setIsOfferListConfirmationLoading] =
    useState(true);
  const [listOfConfirmationOffers, setListOfConfirmationOffers] = useState<
    OfferListConfirmationType[]
  >([]);

  // this useEffect will serves as the handler of switching the offer tab
  useEffect(() => {
    setIsOfferListLoading(true);
    if (offerPageActiveNav === "Published") {
      setOfferStatusToDisplay(1);
    } else if (offerPageActiveNav === "Unpublished") {
      setOfferStatusToDisplay(0);
    } else if (offerPageActiveNav === "Confirmation") {
      setOfferStatusToDisplay(2);
    }
  }, [offerPageActiveNav]);

  // cja: useEffect hook to check the offer list from redux storage,
  // if empty, then call from api
  useEffect(() => {
    if (isOfferListLoading) {
      // get and decode the token from local storage
      const _token = getTokenFromLocalStorage() ?? "";
      const userData: TokenModel = jwt_decode(_token);
      const supplier_id: string = userData._id.toString();

      getOfferList(_token, offerStatusToDisplay, supplier_id).then(
        (data: any) => {
          dispatch(setUtilityOfferList(data));
          setIsOfferListLoading(false);
        }
      );
    } else {
      let updatedListOfOffers: OfferType[] = [];
      utilities.offerList.length !== 0 &&
        utilities.offerList.forEach((offer: any) => {
          updatedListOfOffers = [
            ...updatedListOfOffers,
            {
              offer_id: offer.offer_id,
              offer_name: offer.name,
              shipping_method: offer.shipping_method,
              delivery_location: offer.delivery_location,
              remaining: convertSecondsToDHMS(Number(offer.remaining)),
              effectivity_date: formatToReadableDate(offer.effectivity_date),
            },
          ];
        });
      setListOfOffers(updatedListOfOffers);
      setIsOfferListLoading(false);
    }
  }, [utilities.offerList, dispatch, isOfferListLoading, offerStatusToDisplay]);

  // cja: this useEffect hook will be triggered once there are changes occured in the
  // utilities.commodityTypes and utilities.productList redux storage.
  useEffect(() => {
    if (utilities.commodityTypes.length !== 0) {
      if (utilities.productList.length === 0 && isProductListLoading) {
        // cja: if utilities.productList is empty, then we will fetch update product list data from api
        // then store the resulting data to redux utilities.productList.
        utilities.commodityTypes.forEach((commodity_type: any) => {
          if (commodity_type.name.toString() === "Petroleum") {
            let commodity_type_id = parseInt(commodity_type.id);
            // get and decode the token from local storage
            const _token = getTokenFromLocalStorage() ?? "";
            const userData: TokenModel = jwt_decode(_token);
            const supplier_id: string = userData._id.toString();

            getProductList(_token, commodity_type_id, supplier_id).then(
              (data: any) => {
                dispatch(setUtilityProductList(data));
                setIsProductListLoading(false);
              }
            );
          }
        });
      } else if (utilities.productList.length !== 0) {
        // cja: if utilities.productList is not empty,
        // then, loop it and store it to product_list local state
        let updatedListOfProducts: ProductType[] = [];
        utilities.productList.forEach((product: any) => {
          const newProduct: ProductType = {
            product_id: product.product_id,
            commodity_name: product.commodity_name,
            stocks: formatNumberWithComma(product.stocks) + " ltrs",
            commodity_type: "Petroluem",
          };
          updatedListOfProducts = [...updatedListOfProducts, newProduct];
        });
        setListOfProducts(updatedListOfProducts);
        setIsProductListLoading(false);
      }
    } else if (
      utilities.commodityTypes.length === 0 &&
      isCommodityTypeListLoading
    ) {
      getCommodityTypes().then((data: any) => {
        dispatch(setUtilityCommodityTypes(data));
        setIsCommodityTypeListLoading(false);
      });
    }
  }, [
    dispatch,
    utilities.commodityTypes,
    utilities.productList,
    isProductListLoading,
    isCommodityTypeListLoading,
  ]);

  const dropdown_items: DropdownItemType[] = [
    {
      item_text: "Select Product from My Products",
      icon: (
        <RectangleStackIcon
          className="mr-3 h-5 w-5 text-dealogikal-200 group-hover:text-dealogikal-200"
          aria-hidden="true"
        />
      ),
      onClick: () => setIsModalOpen(true),
    },
    {
      item_text: "Create New Product Info",
      icon: (
        <CubeIcon
          className="mr-3 h-5 w-5 text-dealogikal-200 group-hover:text-dealogikal-200"
          aria-hidden="true"
        />
      ),
      onClick: createNewProductHandler,
    },
  ];

  // cja: product list inside modal checkbox handler
  const selectionHandler = (product_id: number) => {
    // get the entire product details
    let toSelectProduct: any = [];
    listOfProducts.forEach((prod: ProductType) => {
      if (prod.product_id === product_id) {
        toSelectProduct = [...toSelectProduct, prod];
      }
    });

    // check if product_id exist in the selectedProduct
    checkProductExist(product_id).then((res) => {
      if (!res && selectedProducts.length < 3) {
        // if not selected, then add product name to selected array
        let newSelectedProducts: SelectedProductsType[] = [];
        newSelectedProducts = [
          ...selectedProducts,
          {
            product: toSelectProduct[0],
            fields: "",
          },
        ];
        setSelectedProducts(newSelectedProducts);
      } else if (res && selectedProducts.length <= 3) {
        let newSelectedProducts: SelectedProductsType[] = [];
        selectedProducts.forEach((selected_product: SelectedProductsType) => {
          if (selected_product.product.product_id !== product_id) {
            newSelectedProducts = [...newSelectedProducts, selected_product];
          }
        });
        setSelectedProducts(newSelectedProducts);
      } else {
        selectionErrorHandler();
      }
    });
  };

  // cja: check if to_select product already exist
  const checkProductExist = (product_id: number) => {
    return new Promise((resolve, reject) => {
      resolve(selectedProductID.includes(product_id));
    });
  };

  // cja: useEffect hook that extracts the product ids
  // and store to an array if changes detected
  useEffect(() => {
    let productIDArray: number[] = [];
    selectedProducts.forEach((selected_product: SelectedProductsType) => {
      productIDArray = [...productIDArray, selected_product.product.product_id];
    });
    setSelectedProductID(productIDArray);
  }, [selectedProducts]);

  // cja: function that handles the display of the error message in
  // in selecting products beyond 3
  const selectionErrorHandler = () => {
    setIsDisplaySelectionError(true);
    setTimeout(() => {
      setIsDisplaySelectionError(false);
    }, 3000);
  };

  // cja: flag for the active nav item
  const [activeSelectProductNav, setActiveSelectProductNav] =
    useState<string>("Item");
  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const selectProductNavItems = [
    {
      text: `Item(${selectedProducts.length})`,
    },
    {
      text: "Unlisted(0)",
    },
  ];
  // This Section was for Table Header Column
  // DC: setup table header columns
  const tableHeader = [
    {
      name: "offer_name",
      value: "Offer Name",
      icon: TagIcon,
      isTruncated: false,
    },
    {
      name: "shipping_method",
      value: "Shipping Method",
      icon: TruckIcon,
      isTruncated: false,
    },
    {
      name: "delivery_location",
      value: "Delivery Location",
      icon: MapPinIcon,
      isTruncated: true,
    },
  ];

  const confirmationtableHeader = [
    {
      name: "offer_name",
      value: "Order Name",
      icon: TrophyIcon,
      isTruncated: false,
    },
    {
      name: "requested_commodities",
      value: "Requested Commodities",
      icon: CubeIcon,
      isTruncated: true,
    },
    {
      name: "requested_volume",
      value: "Requested Volume",
      icon: ClockIcon,
      isTruncated: false,
    },
    {
      name: "delivery_date",
      value: "Delivery Date",
      icon: MapPinIcon,
      isTruncated: true,
    },
    {
      name: "delivery_location",
      value: "Delivery Location",
      icon: MapPinIcon,
      isTruncated: true,
    },
    {
      name: "order_status",
      value: "Order Status",
      icon: MapPinIcon,
      isTruncated: true,
    },
  ];

  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const navItems = [
    {
      text: "Published",
    },
    {
      text: "Unpublished",
    },
    {
      text: "Confirmation",
    },
  ];

  // cja: confirm selection handler function
  const confirmSelectionHandler = () => {
    // declare the base property which will be the basis in sorting the array in alphabet order
    sortSelectedProducts(selectedProducts, "alphabet");
    dispatch(setSelected_Products(JSON.stringify(selectedProducts)));
    navigate("/s/add_product_offer");
  };

  const sortSelectedProducts = (
    arr: SelectedProductsType[],
    base_type: string
  ) => {
    arr.sort(function (a: any, b: any) {
      var result = 0;
      if (base_type === "alphabet") {
        var textA = a.product.commodity_name.toString().toLowerCase();
        var textB = b.product.commodity_name.toString().toLowerCase();
        result = textA < textB ? -1 : textA > textB ? 1 : 0;
      } else if (base_type === "numeric") {
        // to be enchance
      }
      return result;
    });
  };

  const rowClickHandler = (id: string) => {
    if (id !== "") {
      const _token = getTokenFromLocalStorage() ?? "";
      const userData: TokenModel = jwt_decode(_token);
      const supplier_id: string = userData._id.toString();
      getFetch(
        `${process.env.REACT_APP_API_URL}/offer/details/get?offer_id=${id}`,
        _token
      ).then((response) => {
        let offer_id = response.data.items[0].offer_id;
        let offer_name = response.data.items[0].offer_name;
        let sold = response.data.items[0].sold;
        let remaining = response.data.items[0].remaining;
        let views = response.data.items[0].views;

        if (response.statusCode === 200) {
          getFetch(
            `${process.env.REACT_APP_API_URL}/offer/details/list/get?offer_id=${id}&supplier_id=${supplier_id}`,
            _token
          ).then((res) => {
            if (res.statusCode === 200) {
              dispatch(setOfferOrderList(res.data.items));
              dispatch(setOfferDetailsOfferID(offer_id));
              dispatch(setOfferDetailsOfferName(offer_name));
              dispatch(setOfferDetailsSold(sold));
              dispatch(setOfferDetailsRemaining(remaining));
              dispatch(setOfferDetailsViews(views));
              manageBreadcrumb(
                { name: "Offer 1", href: "/offer_details" },
                () => navigate("/offer_details")
              );
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (isOfferListConfirmationLoading) {
      const _token = getTokenFromLocalStorage() ?? "";
      const userData: TokenModel = jwt_decode(_token);
      const supplier_id: string = userData._id.toString();
      getOfferListConfirmation(_token, 1, supplier_id).then((data: any) => {
        dispatch(setUtilityOfferListConfirmation(data));
        setIsOfferListConfirmationLoading(false);
      });
    } else {
      let updatedListOfConfirmOffers: OfferListConfirmationType[] = [];
      utilities.offerListConfirmation.length !== 0 &&
        utilities.offerListConfirmation.forEach((confirmoffer: any) => {
          updatedListOfConfirmOffers = [
            ...updatedListOfConfirmOffers,
            {
              offer_name: confirmoffer.offer_name,
              requested_commodities: confirmoffer.requested_commodities,
              requested_volume: confirmoffer.requested_volume,
              delivery_date: confirmoffer.delivery_date,
              delivery_location: confirmoffer.delivery_location,
              order_status: confirmoffer.order_status,
            },
          ];
        });
      setListOfConfirmationOffers(updatedListOfConfirmOffers);
      setIsOfferListConfirmationLoading(false);
    }
  }, [
    utilities.offerListConfirmation,
    dispatch,
    isOfferListConfirmationLoading,
  ]);
  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="My Offers"
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <NavTabComponent
              nav_type="primary"
              active_tab={offerPageActiveNav}
              key={Math.random()}
              nav_items={navItems}
              setActiveNavHandler={setOfferPageActiveNav}
              left_content={
                <></>
                // <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full">
                //   <input
                //     type="text"
                //     name="account-number"
                //     id="account-number"
                //     className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                //     placeholder="Search an offer"
                //   />
                //   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5">
                //     <MagnifyingGlassIcon
                //       className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                //       aria-hidden="true"
                //     />
                //   </div>
                // </div>
              }
              right_content={
                listOfOffers.length > 0 && (
                  <DropdownComponent
                    items={dropdown_items}
                    main_text="Create Offer"
                  />
                )
              }
            />
            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pl-2 md:w-full">
                {offerPageActiveNav === "Published" && (
                  <>
                    {isOfferListLoading ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={listOfOffers}
                        currentPage={1} // DC: static for now, lets make it dynamic soon
                        rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                        hasRowClick={true}
                        idStringProperty="offer_id"
                        handleRowClick={rowClickHandler}
                        emptyState={{
                          primaryMessage: "No offers available.",
                          secondaryMessage:
                            "Get started by adding a new offer.",
                          buttonText: "Add an Offer",
                          buttonOnClick() {
                            setIsModalOpen(true);
                          },
                        }}
                      />
                    )}
                  </>
                )}
                {offerPageActiveNav === "Unpublished" && (
                  <>
                    {isOfferListLoading ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={listOfOffers}
                        currentPage={1} // DC: static for now, lets make it dynamic soon
                        rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                        hasRowClick={true}
                        idStringProperty="offer_id"
                        handleRowClick={() => alert("ready to click row")}
                        emptyState={{
                          primaryMessage: "No offers available.",
                          secondaryMessage:
                            "Get started by adding a new offer.",
                          buttonText: "Add an Offer",
                          buttonOnClick() {
                            setIsModalOpen(true);
                          },
                        }}
                      />
                    )}
                  </>
                )}
                {offerPageActiveNav === "Confirmation" && (
                  <>
                    {isOfferListConfirmationLoading ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={confirmationtableHeader}
                        tableData={listOfConfirmationOffers}
                        currentPage={1} // DC: static for now, lets make it dynamic soon
                        rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                        hasRowClick={true}
                        idStringProperty="offer_id"
                        handleRowClick={() => alert("ready to click row")}
                        emptyState={{
                          primaryMessage: "No offers available.",
                          secondaryMessage:
                            "Get started by adding a new offer.",
                          buttonText: "Add an Offer",
                          buttonOnClick() {
                            setIsModalOpen(true);
                          },
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />

      <ModalComponent
        isOpen={isModalOpen}
        onCloseHandler={() => setIsModalOpen(false)}
        header={
          <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
            <RectangleStackIcon
              className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
              aria-hidden="true"
            />
            <span className="text-white inline md:text-base text-xs">
              Select Product from My Products
            </span>
          </div>
        }
        body={
          <>
            <div className="relative rounded-md md:py-0 md:px-0 px-2 md:mt-7 mt-5 md:mx-7 mx-3">
              <input
                type="text"
                name="account-number"
                id="account-number"
                className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                placeholder="Search product"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
                <MagnifyingGlassIcon
                  className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="md:mx-0 mx-3.5">
              <NavTabComponent
                key={Math.random()}
                nav_type="secondary"
                active_tab={activeSelectProductNav}
                nav_items={selectProductNavItems}
                setActiveNavHandler={setActiveSelectProductNav}
              />
            </div>
            {isDisplaySelectionError && (
              <div className="md:px-6 px-5 md:mt-5">
                <span className="text-sm text-red-600">
                  You can only select up to 3 products
                </span>
              </div>
            )}
            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
                {activeSelectProductNav.includes("Item") && (
                  <>
                    {listOfProducts.length !== 0 && (
                      <>
                        <div className="md:px-6 px-5 justify-center">
                          <div className="table w-full pt-6">
                            <div className="table-row-group">
                              {listOfProducts.map((product: ProductType) => {
                                return (
                                  <div
                                    key={generateKey(
                                      product.product_id.toString() +
                                        Math.random()
                                    )}
                                  >
                                    <div className="table-row">
                                      <div className="table-cell text-center md:w-1/12">
                                        <input
                                          id={`cb` + product.product_id}
                                          name={`cb` + product.product_id}
                                          type="checkbox"
                                          checked={selectedProductID.includes(
                                            product.product_id
                                          )}
                                          className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
                                          value={product.product_id}
                                          onChange={(event) =>
                                            selectionHandler(
                                              parseInt(event.target.value)
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="table-cell md:w-10/12">
                                        <span className="font-normal md:text-base text-xs ml-4">
                                          {product.commodity_name}
                                        </span>
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="text-center md:mt-4 md:mx-0 mx-5">
                          <ButtonComponent
                            text="Confirm"
                            utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 ${
                              selectedProducts.length === 0
                                ? "disabled:opacity-50 cursor-arrow"
                                : "hover:bg-dealogikal-200 cursor-pointer"
                            } duration-500 rounded-full font-normal md:w-11/12 w-full`}
                            onClick={confirmSelectionHandler}
                            disabled={
                              selectedProducts.length === 0 ? true : false
                            }
                          />
                        </div>
                      </>
                    )}
                    {listOfProducts.length === 0 && (
                      <div className="md:my-8">
                        <EmptyStateComp
                          icon={
                            <PlusCircleIcon
                              className="h-10 w-10 mx-auto text-gray-300"
                              aria-hidden="true"
                            />
                          }
                          primary_message="No products"
                          secondary_message="Get started by creating a new product"
                          button_text="Add New Product"
                          button_onclick_handler={() =>
                            navigate("/s/choose_commodity")
                          }
                        />
                      </div>
                    )}
                  </>
                )}
                {activeSelectProductNav.includes("Unlisted") && (
                  <>
                    <div className="mx-auto text-gray-400">
                      <span className="md:text-sm text-xs">Coming Soon...</span>
                    </div>
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};
export default OfferListComp;
