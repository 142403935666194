import DashboardCardComponent2Light from "../../../templates/CardComponent/DashboardCardComponent2Light";
import ShipmentStatusData from "./ShipmentStatusData";

const ShipmentStatusComponent = () => {
  return (
    <div className="py-8 md:py-16">
      <div className="mx-auto max-w-full px-3 sm:px-5 lg:px-8">
        <div className="max-w-2xl lg:max-w-none ">
          <h2 className="text-xl md:text-2xl font-bold tracking-tight text-black text-left">
            Shipment Status
          </h2>
          <DashboardCardComponent2Light data={ShipmentStatusData} />
        </div>
      </div>
    </div>
  );
};

export default ShipmentStatusComponent;
