import {
  CalendarDaysIcon,
  DocumentChartBarIcon,
  TruckIcon,
  DocumentIcon
} from "@heroicons/react/24/outline";

const exploreDealogikal = [
  {
    name: "Shipment",
    icon: CalendarDaysIcon,
    description: "",
    href: "/l/shipment",
  },
  {
    name: "Rates",
    icon: DocumentChartBarIcon,
    description: "",
    href: "/l/rates",
  },
  {
    name: "Vehicle Manager",
    icon: TruckIcon,
    description: "",
    href: "/l/vehicle_manager",
  },
  {
    name: "Accreditation",
    icon: DocumentIcon,
    description: "",
    href: "/l/accreditation",
  },
];

export default exploreDealogikal;
