// DC: add all our page components here ...
//import OrderType from "../pages/buyer/buy_commodity/order_type/OrderType";
//import SelectCommodity from "../pages/buyer/buy_commodity/select_commodity/SelectCommodity";
import SingleItem from "../pages/buyer/buy_commodity/single_item/SingleItem";
import ChooseItem from "../pages/buyer/buy_commodity/choose_item/ChooseItem";
import ShippingDetails from "../pages/buyer/buy_commodity/shipping_details/ShippingDetails";
import BuyerDashboard from "../pages/buyer/dashboard/Dashboard";
import SellerDashboard from "../pages/seller/dashboard/Dashboard";
import Quotation from "../pages/buyer/buy_commodity/quotation/Quotation";
import ProductList from "../pages/seller/my_products/product_list/ProductList";
import ChooseCommodity from "../pages/seller/my_products/add_product/choose_commodity_type/ChooseCommodity";
import ChooseProductType from "../pages/seller/my_products/add_product/choose_product_type/ChooseProductType";
import AddSingleProduct from "../pages/seller/my_products/add_product/add_single_product/AddSingleProduct";
import AddProductOrOffer from "../pages/seller/my_offers/add_product_or_offer/AddProductOrOffer";
import OfferList from "../pages/seller/my_offers/offer_list/OfferList";
import OfferDetails from "../pages/seller/my_offers/offer_details/OfferDetails";
import AddVariation from "../pages/seller/my_offers/add_product_or_offer/AddVariation";
import SellerQuotationComp from "../components/seller/quotation/SellerQuotationComp";
import MyQuotation from "../pages/buyer/my_quotation/MyQuotation";
import MyAuctionTemplateList from "../pages/buyer/auction_templates/my_templates/MyAuctionTemplateList";
import SelectCommodity from "../pages/buyer/auction_templates/add_template/select_commodity/SelectCommodity";
import OrderType from "../pages/buyer/auction_templates/add_template/order_type/OrderType";
import ReverseAuctionTemplate from "../pages/buyer/auction_templates/add_template/reverse_auction/ReverseAuctionTemplate";
import BuyerReverseAuction from "../pages/buyer/my_reverse_auction/reverse_auction/BuyerReverseAuction";
import MyReverseAuctionList from "../pages/buyer/my_reverse_auction/reverse_auction_list/MyReverseAuctionList";
// import SupplierReverseAuction from "../pages/seller/my_reverse_auction/reverse_auction/SupplierReverseAuction";
import Explore from "../pages/explore/Explore";
import LogisticDashboard from "../pages/logistic/dashboard/Dashboard";
// import LogisticReverseAuction from "../pages/logistic/my_reverse_auction/reverse_auction/LogisticReverseAuction";
import AddVehicle from "../pages/logistic/vehicle_manager/add_vehicle/AddVehicle";
import VehicleList from "../pages/logistic/vehicle_manager/vehicle_list/VehicleList";
import RateList from "../pages/logistic/rates/rate_list/RatesList";
import SetDetails from "../pages/logistic/rates/set_details/SetDetails";
import SetRate from "../pages/logistic/rates/create_rate/set_rate/SetRate";
import SetDeliveryZone from "../pages/logistic/rates/set_delivery_zone/SetDeliveryZone";
import LogisticRate from "../pages/logistic/rates/logistic_rate/LogisticRate";
import LogisticAccreditation from "../pages/logistic/accreditation/LogisticAccreditation";
import Accreditation from "../pages/seller/my_accreditation/Accreditation";
import BuyerVehicleList from "../pages/buyer/my_vehicle/BuyerVehicleList";
import BuyerAddVehicleList from "../pages/buyer/my_vehicle/BuyerAddVehicleList";
import LogisticPreOrder from "../pages/logistic/preorder/LogisticPreOrder";
import BuyerOrderDetailsComp from "../components/buyer/my_order/BuyerOrderDetailsComp";
import AdminDashboard from "../pages/admin/dashboard/Dashboard";
import Payment from "../pages/admin/payment/Payment";
import QuotationListComp from "../components/admin/quotation/quotation_list/QuotationListComp";
import SalesOrderManagement from "../pages/seller/my_orders/SalesOrderManagement";
import Shipments from "../pages/logistic/shipments/Shipments";

// DC: declare our routes here...
const Routes = [
  {
    path: "/b/dashboard",
    element: <BuyerDashboard />,
    allowedRoles: [1],
  },
  {
    path: "/b/my_auction_templates",
    element: <MyAuctionTemplateList />,
    allowedRoles: [1],
  },
  {
    path: "/b/select_commodity",
    element: <SelectCommodity />,
    allowedRoles: [1],
  },
  {
    path: "/b/order_type",
    element: <OrderType />,
    allowedRoles: [1],
  },
  {
    path: "/b/reverse_auction_template",
    element: <ReverseAuctionTemplate />,
    allowedRoles: [1],
  },
  {
    path: "/b/reverse_auction/:reverse_auction_id",
    element: <BuyerReverseAuction />,
    allowedRoles: [1, 7],
  },
  {
    path: "/b/reverse_auction_list",
    element: <MyReverseAuctionList />,
    allowedRoles: [1],
  },
  {
    path: "/b/vehicle_manager",
    element: <BuyerVehicleList />,
    allowedRoles: [1],
  },
  {
    path: "/b/vehicle_manager/:step_status",
    element: <BuyerVehicleList />,
    allowedRoles: [1],
  },

  {
    path: "/b/add_vehicle",
    element: <BuyerAddVehicleList />,
    allowedRoles: [1],
  },
  //   "/l/add_vehicle/:vehicle_types"
  {
    path: "/b/add_vehicle/:vehicle_types",
    element: <BuyerAddVehicleList />,
    allowedRoles: [1],
  },
  {
    path: "/b/my_orders/:reverse_auction_id",
    element:<BuyerOrderDetailsComp/>,
    allowedRoles: [1],
  },
  //Supplier
  {
    path: "/s/reverse_auction/:reverse_auction_id",
    element: <BuyerReverseAuction />, // TODO: Make this common component
    allowedRoles: [2, 7],
  },
  {
    path: "/single_item",
    element: <SingleItem />,
    allowedRoles: [6],
  },
  {
    path: "/choose_item",
    element: <ChooseItem />,
    allowedRoles: [6],
  },
  {
    path: "/shipping_details",
    element: <ShippingDetails />,
    allowedRoles: [6],
  },
  {
    path: "/quotation/:quotation_id",
    element: <Quotation />,
    allowedRoles: [6],
  },
  {
    path: "/s/dashboard",
    element: <SellerDashboard />,
    allowedRoles: [2],
  },
  {
    path: "/my_products",
    element: <ProductList />,
    allowedRoles: [2],
  },
  {
    path: "/s/choose_commodity",
    element: <ChooseCommodity />,
    allowedRoles: [2],
  },
  {
    path: "/s/choose_product_type",
    element: <ChooseProductType />,
    allowedRoles: [2],
  },
  {
    path: "/s/add_single_product",
    element: <AddSingleProduct />,
    allowedRoles: [2],
  },
  {
    path: "/s/add_product_offer",
    element: <AddProductOrOffer />,
    allowedRoles: [2],
  },
  {
    path: "/s/add_product_offer_variation",
    element: <AddVariation />,
    allowedRoles: [2],
  },
  {
    path: "/my_offers",
    element: <OfferList />,
    allowedRoles: [2],
  },
  {
    path: "/offer_details",
    element: <OfferDetails />,
    allowedRoles: [2],
  },
  {
    path: "/s/quotation",
    element: <SellerQuotationComp />,
    allowedRoles: [2],
  },
  {
    path: "/my_quotations",
    element: <MyQuotation />,
    allowedRoles: [2],
  },
  {
    path: "/s/my_accreditation/:status_id",
    element: <Accreditation />,
    allowedRoles: [2],
  },
  {
    path:"/s/my_sales",
    element:<SalesOrderManagement/>,
    allowedRoles:[2]
  },
  // logistic routes
  {
    path: "/l/dashboard",
    element: <LogisticDashboard />,
    allowedRoles: [6],
  },
  {
    path: "/explore",
    element: <Explore />,
    allowedRoles: [6],
  },
  {
    path: "/l/vehicle_manager",
    element: <VehicleList />,
    allowedRoles: [6],
  },
  {
    path: "/l/add_vehicle/:vehicle_types",
    element: <AddVehicle />,
    allowedRoles: [6],
  },
  {
    path: "/l/set_rate",
    element: <SetRate />,
    allowedRoles: [6],
  },
  {
    path: "/l/rates",
    element: <RateList />,
    allowedRoles: [6],
  },
  {
    path: "/l/set_details",
    element: <SetDetails />,
    allowedRoles: [6],
  },
  {
    path: "/l/set_delivery_zone",
    element: <SetDeliveryZone />,
    allowedRoles: [6],
  },
  {
    path: "/l/logistic_rate/:rate_details_id",
    element: <LogisticRate />,
    allowedRoles: [6],
  },
  {
    path: "/l/reverse_auction/:reverse_auction_id",
    element: <BuyerReverseAuction />, // TODO: Make this common component
    allowedRoles: [6, 7],
  },
  {
    path: "/l/accreditation/:status_id",
    element: <LogisticAccreditation />,
    allowedRoles: [6],
  },
  {
    path: "/l/logistic_rate/:rate_details_id",
    element: <LogisticRate />,
    allowedRoles: [6],
  },
  {
    path: "/l/pre_order/:status_id",
    element: <LogisticPreOrder />,
    allowedRoles: [6],
  },
  {
    path: "/l/vehicle_manager/:step_status",
    element: <VehicleList />,
    allowedRoles: [6],
  },
  {
    path: "/l/shipments",
    element: <Shipments />,
    allowedRoles: [6],
  },
  // admin routes
  {
    path: "/a/dashboard",
    element: <AdminDashboard />,
    allowedRoles: [7],
  },
  {
    path: "/a/quotation/:status_id",
    element: <QuotationListComp />,
    allowedRoles: [7],
  },
  {
    path: "/a/quotation",
    element: <QuotationListComp />,
    allowedRoles: [7],
  },
  {
    path: "/a/payment",
    element: <Payment />,
    allowedRoles: [7],
  },
];

export default Routes;
