import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import {
  ArrowPathIcon,
  CubeIcon,
  MapPinIcon,
  Square3Stack3DIcon,
  TagIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import TableComponent from "../../../templates/TableComponent/TableComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { getReverseAuctionListAPI } from "../../../../redux/functions/API";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {getReverseAuctionList, setReverseAuctionList} from "../../../../redux/functions/storageSlice"

const MyReverseAuctionListComp = () => {
  const navigate = useNavigate();
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const [reverseAuctionListFetching, setReverseAuctionListFetching] =
    useState<boolean>(true);
  //const [reverseAuctionList, setReverseAuctionList] = useState<ReverseAuctionModel[]>([]);  #### change to dispatch redux
  const dispatch = useAppDispatch();
  const reverseAuctionList = useAppSelector(getReverseAuctionList)
  // cja: flag for the active nav item
  const [activeNav, setActiveNav] = useState<string>("In Progress");

  // cja: fetch reverse auction of the current buyer account
  useEffect(() => {
    if (reverseAuctionListFetching) {
      getReverseAuctionListAPI(_token, userData._id, getStatusID(activeNav))
        .then((response: any) => {
          if (response.statusCode === 200) {
            dispatch(setReverseAuctionList(response.data.items))
            //setReverseAuctionList(response.data.items);  #### change to dispatch redux
            setReverseAuctionListFetching(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    dispatch,
    _token,
    //reverseAuctionList.length, #### change to dispatch redux
    reverseAuctionListFetching,
    userData._id,
    activeNav,
  ]);

  const getStatusID = (active_tab: string) => {
    /*
      1 - In progress
      2 - Waiting
      3 - Expired
      4 - Completed
    */
    switch (active_tab) {
      case "In Progress":
        return 1;
      case "Waiting":
        return 2;
      case "Expired":
        return 3;
      case "Completed":
        return 4;
      default:
        return 0;
    }
  };

  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const nav_items = [
    {
      text: `In Progress`,
    },
    {
      text: `Waiting`,
    },
    {
      text: `Expired`,
    },
  ];

  const setActiveNavTab = (active_item: string) => {
    setReverseAuctionListFetching(true);
    setActiveNav(active_item);
  };

  const tableHeader = [
    {
      name: "reference_number",
      value: "Order No.",
      icon: TagIcon,
    },
    {
      name: "commodities",
      value: "Commodities",
      icon: CubeIcon,
    },
    {
      name: "volume",
      value: "Volume",
      icon: Square3Stack3DIcon,
    },
    {
      name: "delivery_location",
      value: "Location",
      icon: MapPinIcon,
    },
    {
      name: "shipping_method",
      value: "Shipping Method",
      icon: TruckIcon,
    },
    {
      name: "status",
      value: "Status",
      icon: ArrowPathIcon,
    },
  ];

  // created a function for handling the table row click
  const handleRowClick = (reverse_auction_id: number | string) => {
    navigate(`/b/reverse_auction/${reverse_auction_id}`);
  };

  const createTemplateClickHandler = () => {
    navigate("/b/select_commodity");
  };

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="My Active Quotation"
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <NavTabComponent
              key={Math.random()}
              nav_type="secondary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
              left_content={
                <></>
                // <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full -z-10">
                //   <input
                //     type="text"
                //     name="account-number"
                //     id="account-number"
                //     className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                //     placeholder="Search template"
                //   />
                //   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5 ">
                //     <MagnifyingGlassIcon
                //       className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                //       aria-hidden="true"
                //     />
                //   </div>
                // </div>
              }
            />

            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                <>
                  {reverseAuctionListFetching ? (
                    <div className="-my-2">
                      <Skeleton height={40} count={1} />
                      <Skeleton height={40} count={5} />
                    </div>
                  ) : (
                    <TableComponent
                      tableHeader={tableHeader}
                      tableData={reverseAuctionList}//tableData={reverseAuctionList}
                      currentPage={1} // DC: static for now, lets make it dynamic soon
                      rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                      idStringProperty="reverse_auction_id"
                      handleRowClick={handleRowClick}
                      emptyState={{
                        primaryMessage: "No active quotation available.",
                        secondaryMessage: "",
                        buttonText: "Create Auction",
                        buttonOnClick() {
                          createTemplateClickHandler();
                        },
                      }}
                    />
                  )}
                </>
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};

export default MyReverseAuctionListComp;
