import { useEffect, useState } from "react";
import { TokenModel } from "../../../../model/interface/TokenModel";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import { getReverseAuctionDetailsAPI } from "../../../../redux/functions/API";
import { useParams } from "react-router-dom";
import ReverseAuctionComponent from "../../../templates/ReverseAuctionComponent/ReverseAuctionComponent";
import { TruckIcon, UserIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import { getReverseAuctionDetails, setReverseAuctionDetails } from "../../../../redux/functions/storageSlice";

const BuyerReverseAuctionComp = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  const [cardData, setCardData] = useState<any>([]);
  //const [reverseAuctionDetails, setReverseAuctionDetails] = useState<any>({});
  const dispatch = useAppDispatch()
  const reverseAuctionDetails = useAppSelector(getReverseAuctionDetails)
  const [isRefreshing, setisRefreshing] = useState<boolean>(true);

  // get the url params
  const { reverse_auction_id } = useParams();

  // cja: this hook is the main functionality in displaying the data in the buyer terminal reverse auction page.
  // It's main dependency is "isRefreshing" local state, if the value of this state will be true,
  // this hook will be executed to refetch the updated reverse auction data.
  useEffect(() => {
    if (isRefreshing) {
      // Make the API call
      getReverseAuctionDetailsAPI(_token, reverse_auction_id ?? "", userData.role)
        .then((data: any) => {
          // Extracting relevant data from the response
          const { reverse_auction_data, buyer_data, supplier_data, logistic_data } = data;
          // Update state with extracted data
          dispatch(setReverseAuctionDetails({
            reverse_auction_data: reverse_auction_data,
            buyer_data: buyer_data || {},
            supplier_data: supplier_data || {},
            logistic_data: logistic_data || {}
          }));
  
          // Creating buyer, supplier and logistic details for display
          let buyerDetailsOfferCard;
          if (buyer_data && buyer_data.buyer_details) {
            buyerDetailsOfferCard = {
              name: buyer_data.buyer_details?.company_name || "No buyer name",
              icon: UserIcon,
              role: "Buyer",
              imageUrl: buyer_data.buyer_details?.company_logo || "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
              count: 1
            };
          } else {
            buyerDetailsOfferCard = {
              name: "No buyer available",
              icon: UserIcon,
              role: "Buyer",
              imageUrl: "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
              count: 0
            };
          }

          let supplierDetailsOfferCard;
          if (supplier_data && supplier_data.supplier_details) {
            supplierDetailsOfferCard = {
              name: supplier_data.cheapestSupplier?.supplier_details.company_name || "No supplier available",
              icon: TruckIcon,
              role: "Supplier",
              imageUrl: supplier_data.cheapestSupplier?.supplier_details.company_logo || "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
              count: supplier_data.supplier_details.length
            };
          } else {
            supplierDetailsOfferCard = {
              name: "No supplier available",
              icon: TruckIcon,
              role: "Supplier",
              imageUrl: "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
              count: 0
            };
          }
          
          let logisticDetailsOfferCard;
          // When supplier's shipping method is pickup or buyer wants to pickup the item
          logisticDetailsOfferCard = {
            name: "No logistic available",
            icon: TruckIcon,
            role: "Logistic",
            imageUrl: "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
            count: 0
          };
          if (supplier_data.cheapestSupplier?.shipping_method_id === 1 && reverse_auction_data?.shipping_method_id === 1) {
            logisticDetailsOfferCard = {
              name: "Supplier's logistic",
              icon: TruckIcon,
              role: "Logistic",
              imageUrl: supplier_data.cheapestSupplier?.company_logo || "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
              count: 1
            };
          } else if(supplier_data.cheapestSupplier?.shipping_method_id === 2 && reverse_auction_data?.shipping_method_id === 2) {
      
            logisticDetailsOfferCard = {
              name: logistic_data.cheapestLogistic?.logistic_details.company_name || "Buyer's logistic",
              icon: TruckIcon,
              role: "Logistic",
              imageUrl: logistic_data.cheapestLogistic?.logistic_details.company_logo || "https://res.cloudinary.com/doehyebmw/image/upload/v1694657055/dealogikal_2.0/resources/logo/dealogikal-app-dark_p4svpl.png",
              count: 1
            };
          } else {
            if (supplier_data && supplier_data.supplier_details) {
              if(logistic_data && logistic_data.logistic_details) {
                logisticDetailsOfferCard = {
                  name: logistic_data.cheapestLogistic.logistic_details?.company_name,
                  icon: TruckIcon,
                  role: "Logistic",
                  imageUrl: logistic_data.cheapestLogistic.logistic_details?.company_logo,
                  count: logistic_data.logistic_details.length
                };
              }
            }
          }
  
          // Update card data state
          const updatedCardData = [buyerDetailsOfferCard, supplierDetailsOfferCard, logisticDetailsOfferCard];
          setCardData(updatedCardData);
  
          // Turn off refreshing state
          setisRefreshing(false);
        })
        .catch((error: any) => {
          // Handle errors here
          console.error("Error fetching data:", error);
          // Turn off refreshing state even in case of error
          setisRefreshing(false);
        });
    }
  }, [dispatch,_token, userData.role, isRefreshing, reverse_auction_id]);

  return (
    <>
      <ReverseAuctionComponent
        user_data={userData}
        card_data={cardData}
        reverse_auction_id={reverse_auction_id ?? ""}
        reverse_auction_details={reverseAuctionDetails}
        refreshHandler={() => setisRefreshing(true)}
        isRefreshing={isRefreshing}
      />
      ;
    </>
  );
};
export default BuyerReverseAuctionComp;
