import React, { useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import ButtonComponent from "../../../../templates/ButtonComponent/ButtonComponent";
import TableComponent from "../../../../templates/TableComponent/TableComponent";
import { ArrowUpTrayIcon, DocumentIcon } from "@heroicons/react/24/outline";
import ModalComponent from "../../../../templates/ModalComponent/ModalComponent";
import * as XLSX from "xlsx";
import TableHeaderType from "../../../../../model/types/TableHeaderType";
import { useNavigate } from "react-router-dom";
import { getTokenFromLocalStorage } from "../../../../../redux/functions/function";
import { useAppSelector } from "../../../../../redux/app/hooks";
import CircularLoading from "../../../../../lottie/circular_loading_theme_1.json";
import {
  getNextRateTemplateName,
  getVehiclesDetails,
} from "../../../../../redux/functions/storageSlice";
import SelectedVehicleType from "../../../../../model/types/SelectedVehicleType";
import { addRateAPI } from "../../../../../redux/functions/API";
import ToasterComponent from "../../../../templates/ToasterComponent/ToasterComponent";
import Lottie from "lottie-react";

const SetRateComp: React.FC = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const navigate = useNavigate();
  const createRateFields = useAppSelector(getVehiclesDetails);
  const nextRateTemplateName = useAppSelector(getNextRateTemplateName);

  const [importCSVModalIsOpen, setImportCSVModalIsOpen] =
    useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [uploadError, setUploadError] = useState<string>("");
  const [excelFile, setExcelFile] = useState<string | ArrayBuffer | null>(null);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [isAddingRate, setIsAddingRate] = useState<boolean>(false);
  const [selectedVehicleID, setSelectedVehicleID] = useState<number[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [rateTableHeader, setRateTableHeader] = useState<TableHeaderType[]>([]);
  const [selectedDeliveryZone, setSelectedDeliveryZone] = useState<number>(0);
  const [newSubTitle, setNewSubTitle] = useState<string>(nextRateTemplateName);

  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("");
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const fileInputRef = useRef<any>(null);

  // initialize accepted file types
  const accepted_file_types = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  // cja: file input on change handler function
  const fileOnChangeHandler = (e: any) => {
    // extract the file
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // validate the filetype
      if (accepted_file_types.includes(selectedFile.type)) {
        setUploadError("");
        // get filename
        setFileName(selectedFile.name);

        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target!.result);
        };
      } else {
        setUploadError("Please select only excel or csv file types.");
        setFileName("");
        setExcelFile(null);
      }
    } else {
      // if selecting in file explorer window is cancelled via cancel button
      // console.log("Please select your file");
    }
  };

  // janm: list on vehicle list
  useEffect(() => {
    if (createRateFields.selectedVehicles === "") {
      navigate("/l/rates");
    } else {
      let newVehicleIDArray: number[] = [];
      JSON.parse(createRateFields.selectedVehicles).forEach(
        (selected_vehicle: SelectedVehicleType) => {
          newVehicleIDArray = [
            ...newVehicleIDArray,
            selected_vehicle.vehicle.vehicle_id,
          ];
        }
      );
      setSelectedVehicleID(newVehicleIDArray);
    }
  }, [createRateFields.selectedVehicles, navigate]);

  // janm: get selected island id from redux vehicleDetails.selectedDeliveryZone
  useEffect(() => {
    if (createRateFields.selectedDeliveryZone !== "") {
      setSelectedDeliveryZone(parseInt(createRateFields.selectedDeliveryZone));
    }
  }, [createRateFields.selectedDeliveryZone]);

  // janm: get Rate Title from redux
  useEffect(() => {
    if (nextRateTemplateName !== "") {
      setNewSubTitle(nextRateTemplateName);
    }
  }, [nextRateTemplateName]);

  // cja: handler function to upload excel file and populate to table
  const uploadClickHandler = () => {
    setIsUploading(true);
    if (excelFile !== null) {
      // read excel file
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      // get the first worksheet name by accessing the index 0 of sheetnames array
      const worksheetName = workbook.SheetNames[0];
      // access the worksheet data via it's worksheet name
      const worksheet = workbook.Sheets[worksheetName];

      const data = XLSX.utils.sheet_to_json(worksheet);
      // to be implemented soon for pagination
      // setExcelData(data.slice(0, 10));
      data.forEach((row: any) => {
        setExcelData((prev) => [
          ...prev,
          {
            location: row["Location"],
            direction: row["Direction"],
            km: row["KM"],
            freight: row["FREIGHT"],
            pump: row["PUMP"],
            tax: row["12 % VAT"],
            total: row["TOTAL"],
            new_rate: row["NEW RATE"],
          },
        ]);
      });
    } else {
      setIsUploading(false);
      setUploadError("Please select a file first.");
    }
  };

  // cja: useEffect hook responsible for extracting the table header
  useEffect(() => {
    if (excelData.length !== 0) {
      Object.keys(excelData[0]).forEach((key: string) => {
        // format the header (remove the underscores & capitalize)
        let headerValue = key.replace("_", " ");
        let formatHeader =
          headerValue[0].toUpperCase() + headerValue.substring(1);

        let singleTableHeader: TableHeaderType = {
          name: key,
          value: formatHeader,
        };
        setRateTableHeader((prev) => [...prev, singleTableHeader]);
      });
      setIsUploading(false);
      setImportCSVModalIsOpen(false);
    }
  }, [excelData]);

  const removeFileClickHandler = () => {
    setToDefault();
    // erase excel data
    setRateTableHeader([]);
    setExcelData([]);
  };

  const cancelUploadClickHandler = () => {
    setToDefault();
    setImportCSVModalIsOpen(false);
  };

  const setToDefault = () => {
    setUploadError("");
    setFileName("");
    setExcelFile(null);

    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const publishClickHandler = async () => {
    if (excelData.length > 0) {
      const data: any = {
        rate_name: newSubTitle,
        island_id: selectedDeliveryZone, // delivery_zone
        vehicle_id: selectedVehicleID,
        rates: excelData.map((entry) => {
          const lowerCaseEntry = { ...entry };
          for (const key in lowerCaseEntry) {
            lowerCaseEntry[key.toLowerCase()] = lowerCaseEntry[key];
            if (key.toLowerCase() !== key) {
              delete lowerCaseEntry[key];
            }
          }
          setIsAddingRate(true);
          return lowerCaseEntry;
        }),
      };
      try {
        setIsButtonDisabled(true);
        setIsLoadingAPI(true);
        await addRateAPI(_token, data)
          .then(() => {
            // Handle the response data as needed
            setToastMessage("New Rate has been added");
            setToastType("success");
            setIsToastOpen(true);
            setTimeout(() => {
              setIsToastOpen(false);
            }, 3000);
            setTimeout(() => {
              setIsLoadingAPI(false);
              navigate("/l/rates");
            }, 4000);
          })
          .catch((error: any) => {
            if (error.response && error.response.statusCode === 500) {
              setToastMessage("This rate is already exists");
              setToastType("error");
              setIsToastOpen(true);
              setTimeout(() => {
                setIsToastOpen(false);
              }, 3000);
            }
          })
          .finally(() => {
            setIsUploading(false);
          });
      } catch (error) {
        setIsUploading(false);
      }
    } else {
      setToastMessage("No data to save. Please upload a file first.");
      setToastType("warning");
      setIsToastOpen(true);
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000);
    }
  };

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          <ToasterComponent
            isOpen={isToastOpen}
            label={toastMessage}
            onClose={setIsToastOpen}
            type={toastType}
          />
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <BreadcrumbContainerComponent
            key={Math.random()}
            subtitle={"Set Rates"}
          />
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <div className="flex flex-wrap">
            <div className="inline-flex w-full md:w-full justify-end">
              {excelData.length > 0 && (
                <>
                  <ButtonComponent
                    text="Import CSV/Excel file"
                    utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 md:text-sm text-xs shadow-sm md:mx-1 md:py-4 py-3.5 md:my-0 mb-5 self-end sm:hover:bg-slate-50 duration-500 rounded-full font-normal md:w-2/12 w-full"
                    onClick={() => setImportCSVModalIsOpen(true)}
                  />
                  <ButtonComponent
                    text={`${isButtonDisabled ? "Publishing ..." : "Publish"}`}
                    icon={
                      isLoadingAPI && (
                        <Lottie
                          className="md:w-5 w-5 h-auto"
                          animationData={CircularLoading}
                          loop={true}
                        />
                      )
                    }
                    disabled={isButtonDisabled}
                    utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:mx-1 md:py-4 py-3.5 md:my-0 mb-5 self-end sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-2/12 w-full
                            ${
                              isAddingRate
                                ? "cursor-not-allowed opacity-50"
                                : ""
                            }  `}
                    onClick={publishClickHandler}
                  />
                </>
              )}
            </div>
          </div>

          {/* Loading indicator when uploading */}
          {isUploading && (
            <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-700 opacity-50 z-50 flex items-center justify-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          )}

          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-full md:w-full mt-10">
              <TableComponent
                tableHeader={rateTableHeader}
                tableData={excelData}
                currentPage={1}
                rowsPerPage={10}
                idStringProperty="reverse_auction_template_id"
                handleRowClick={() => alert("row click event is ready")}
                emptyState={{
                  primaryMessage: "No rates available.",
                  secondaryMessage: "Get started by creating/importing rates.",
                  buttonText: "Import CSV/Excel file",
                  buttonOnClick() {
                    setImportCSVModalIsOpen(true);
                  },
                }}
              />
            </div>
          </div>

          {/* modals */}
          <ModalComponent
            isOpen={importCSVModalIsOpen}
            onCloseHandler={() => setImportCSVModalIsOpen(false)}
            header={
              <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                <ArrowUpTrayIcon
                  className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                  aria-hidden="true"
                />
                <span className="text-white inline md:text-base text-xs">
                  Import CSV/Excel file
                </span>
              </div>
            }
            body={
              <>
                <div className="flex flex-wrap justify-center md:my-10">
                  <div className="flex flex-col w-full px-10 py-12 md:w-3/5 items-center">
                    {fileName !== "" ? (
                      <div className="flex flex-col w-full md:w-full items-center">
                        <div className=" bg-gray-50 rounded-lg shadow-md my-3 py-4 pl-7 pr-5 md:w-full border-l-2 border-dealogikal-50">
                          <span className="text-dealogikal-200 md:text-lg text-xs mt-5">
                            {fileName}
                          </span>
                          <span
                            className="float-right text-gray-300 hover:text-gray-400 cursor-pointer text-xl"
                            onClick={removeFileClickHandler}
                          >
                            x
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="md:w-36 h-auto self-center rounded-full bg-dealogikal-30 p-8">
                          <DocumentIcon
                            className="md:h-auto md:w-full h-5 w-5 text-dealogikal-50 rounded-2xl bg-dealogikal-30"
                            aria-hidden="true"
                          />
                        </div>
                        <span className="text-gray-400 md:text-base text-xs mt-5">
                          -- No file selected --
                        </span>
                      </>
                    )}

                    <div
                      className={`mt-8 flex text-sm leading-6 text-gray-600`}
                    >
                      <label
                        htmlFor="file-upload"
                        className={`relative cursor-pointer rounded-md text-base bg-white font-semibold text-dealogikal-200 focus-within:ring-offset-2 hover:text-dealogikal-300`}
                      >
                        <span
                          className={`${
                            fileName !== ""
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }`}
                        >
                          Upload a file
                        </span>
                        <input
                          ref={fileInputRef}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className={`sr-only`}
                          disabled={fileName !== ""}
                          onChange={fileOnChangeHandler}
                        />
                      </label>
                    </div>
                    {uploadError !== "" ? (
                      <p className="text-base text-red-600 mt-5">
                        {uploadError}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="inline-flex w-full md:w-full justify-center mt-14 mb-6">
                    <ButtonComponent
                      text={`Cancel`}
                      utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-1 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4"
                      onClick={cancelUploadClickHandler}
                    />
                    <ButtonComponent
                      text={`${isUploading ? "Uploading..." : "Upload"}`}
                      utils={`bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-1 duration-500 rounded-full font-normal w-1/4 ${
                        isUploading || excelData.length > 0
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                      disabled={isUploading || excelData.length > 0}
                      onClick={uploadClickHandler}
                    />
                  </div>
                </div>
              </>
            }
          />
        </>
      }
    />
  );
};

export default SetRateComp;
