import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import SideBarComponentMobile from "../../buyer/navigation/sidebar/SideBarComponentMobile";
import SideBarComponentDesktop from "../../buyer/navigation/sidebar/SideBarComponentDesktop";
import TopNavigationComponent from "../../buyer/navigation/topnav/TopNavigationComponent";
import {
  identifyMenu,
  prevTransactions,
  userNavigation,
} from "./MainConsoleLayoutComponentData";
import PageContentComponent from "../PageContentComponent/PageContentComponent";
import { MainConsoleLayoutModel } from "../../../model/interface/MainConsoleLayoutModel";
import { TokenModel } from "../../../model/interface/TokenModel";
import {
  generateKey,
  getTokenFromLocalStorage,
} from "../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import NotificationType from "../../../model/types/NotificationType";
import { getNotificationsAPI } from "../../../redux/functions/API";
import SnackBarComponent from "../SnackBarComponent/SnackBarComponent";
import { useWebSocket } from "../../../services/ws/WebSocketService";
import ModalComponent from "../ModalComponent/ModalComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import {
  CalendarDaysIcon,
  ClipboardDocumentCheckIcon,
  InboxIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
  getIsGlobalModalViewed,
  getIsSnackbarViewed,
  getNotificationList,
  setIsGlobalModalViewed,
  setIsSnackbarViewed,
  setNotificationList,
  setOfferBookingId,
  setSocketResponseData,
} from "../../../redux/functions/storageSlice";
import SnackBarType from "../../../model/types/SnackBarType";

const MainConsoleLayoutComponent = (props: MainConsoleLayoutModel) => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const navigate = useNavigate();

  const { socketMessage } = useWebSocket();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // cja: this is a dummy user role, for dynamic platfomr console
  const [userRole] = useState<number>(userData.role);

  // General BookOffer WebSocket implimentation (start) ====================================================================>
  const [notificationListLoading, setNotificationListLoading] =
    useState<boolean>(true);

  const location = useLocation();
  const dispatch = useAppDispatch();

  //redux states
  const notificationList = useAppSelector(getNotificationList);
  const isGlobalModalViewed = useAppSelector(getIsGlobalModalViewed);
  const isSnackbarViewed = useAppSelector(getIsSnackbarViewed);

  // loocal states
  const [globalModalIsOpen, setGlobalModalIsOpen] = useState<boolean>(false);
  const [globalModalHeaderContent, setGlobalModalHeaderContent] =
    useState<ReactNode>(<></>);
  const [globalModalBodyContent, setGlobalModalBodyContent] =
    useState<ReactNode>(<></>);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [snackbarData, setSnackbarData] = useState<{ key: string; data: any }>({
    key: "",
    data: "",
  });
  const [snackbars, setSnackbars] = useState<SnackBarType[]>([]);

  // cja: snackbar close button click handler
  const snackbarCloseHandler = useCallback(() => {
    dispatch(setIsSnackbarViewed(true));
  }, [dispatch]);

  // cja: useEffect to manage storing of notifications
  useEffect(() => {
    if (notifications.length > notificationList.length) {
      /* 
				cja: [ Important Note! ] If changes detected in [notifications] local state, this useEffect will handle the process
				of checking the length of the local and redux notification states.

				If, notifications local state array length is greater than the notificationList redux state, therefore, there is a new notification
				that was received from the socket messages and stored in the local state that MUST BE also stored to the redux state.
			*/
      dispatch(setNotificationList(notifications));
    }
  }, [notifications, notificationList.length, dispatch]);

  // cja: useEffect to handle the new snackbar data
  useEffect(() => {
    if (snackbarData.key !== "" && snackbarData.data !== "") {
      if (snackbarData.key === "PAYMENT_READY") {
        // get expected data
        const { notification, reference_number } = snackbarData.data;

        const newSnackbar: SnackBarType = {
          snackbar_id: notification[0].notification_id,
          icon: InboxIcon,
          title: (
            <p className="text-sm font-medium text-gray-900">Book Confirmed</p>
          ),
          content: (
            <p className="mt-1 text-sm text-gray-500">
              Your Order#{" "}
              <span className="text-dealogikal-100 cursor-pointer hover:underline decoration-dealogikal-200">
                {reference_number}
              </span>{" "}
              has been confirmed!
            </p>
          ),
          href: `#`,
          isVisible: true,
          snackbarCloseHandler: snackbarCloseHandler,
        };

        if (!isSnackbarViewed) {
          setSnackbars((prev) => [newSnackbar, ...prev]);
        }
      } else if (snackbarData.key === "NEW_NOTIFICATION") {
        if (snackbarData.data.title === "Vehicle Accreditation Approved") {
          // get expected data
          const { notification_id, title, message } = snackbarData.data;

          const newSnackbar: SnackBarType = {
            snackbar_id: notification_id,
            icon: InboxIcon,
            title: (
              <p className="text-sm font-medium text-dealogikal-200">{title}</p>
            ),
            content: <p className="mt-1 text-sm text-gray-500">{message}</p>,
            href: `#`,
            isVisible: true,
            snackbarCloseHandler: snackbarCloseHandler,
          };

          if (!isSnackbarViewed) {
            setSnackbars((prev) => [newSnackbar, ...prev]);
          }
        } else if (snackbarData.data.title === "Payment Verification") {
          // get expected data
          const { notification_id, title, message } = snackbarData.data;

          const newSnackbar: SnackBarType = {
            snackbar_id: notification_id,
            icon: InboxIcon,
            title: (
              <p className="text-sm font-medium text-dealogikal-200">{title}</p>
            ),
            content: <p className="mt-1 text-sm text-gray-500">{message}</p>,
            href: `#`,
            isVisible: true,
            snackbarCloseHandler: snackbarCloseHandler,
          };

          if (!isSnackbarViewed) {
            setSnackbars((prev) => [newSnackbar, ...prev]);
          }
        }
      } else if (snackbarData.key === "VEHICLE_CONFIRMED") {
        // get expected data
        const { data, message } = snackbarData.data;

        const newSnackbar: SnackBarType = {
          snackbar_id: data.notification[0].notification_id,
          icon: InboxIcon,
          title: (
            <p className="text-sm font-medium text-dealogikal-200">
              {data.notification[0].title}
            </p>
          ),
          content: <p className="mt-1 text-sm text-gray-500">{message}</p>,
          href: `#`,
          isVisible: true,
          snackbarCloseHandler: snackbarCloseHandler,
        };

        if (!isSnackbarViewed) {
          setSnackbars((prev) => [newSnackbar, ...prev]);
        }
      }
    }
  }, [snackbarData, isSnackbarViewed, snackbarCloseHandler]);

  const closeGlobalModalHandler = useCallback(() => {
    setGlobalModalIsOpen(false);
    setGlobalModalBodyContent(null);
    dispatch(setIsGlobalModalViewed(true));
  }, [dispatch]);

  const tableHeader = useMemo(
    () => [
      {
        name: "items",
        value: "Items",
      },
      {
        name: "quantity",
        value: "Quantity",
      },
      {
        name: "your_price",
        value: "Your Price",
      },
    ],
    []
  );

  const renderGlobalModalContent = useCallback(
    (path: string, key: string, data: any) => {
      let modalContent: ReactNode = null;

      if (key === "BEEN_BOOKED") {
        // if the buyer books supplier's offer
        if (path.includes("/s/reverse_auction/")) {
          // if inside reverse auction page
          modalContent = (
            <>
              <div className="flex flex-wrap justify-center mt-2 mx-20">
                {/* single card (start) */}
                <div className="flex flex-col py-5 w-full md:w-full items-center">
                  <ClipboardDocumentCheckIcon
                    className="mr-1.5 h-20 w-20 flex-shrink-0 text-green-600 animate-bounce"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex flex-col w-full py-1 px-5 md:w-full">
                  <span className="text-lg text-gray-500 text-center">
                    {`Your offer has been booked by the buyer.`}
                  </span>
                  <span className="text-lg text-gray-500 text-center">
                    {`You have 5 minutes to confirm this booking.`}
                  </span>
                </div>
                <div className="inline-flex w-full justify-center pt-10 pb-12 md:w-full">
                  <ButtonComponent
                    text={`OK`}
                    utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-1/4 focus:outline-none"
                    onClick={closeGlobalModalHandler}
                  />
                </div>
                {/* single card (end) */}
              </div>
            </>
          );
        } else {
          // if outside reverse auction page
          // expected data
          const { booking_details, items } = data;
          modalContent = (
            <>
              <div className="relative md:mt-7 mt-5 flex flex-col mb-2  mx-auto justify-center items-center">
                <img
                  className="h-28 w-35"
                  src="https://res.cloudinary.com/doehyebmw/image/upload/v1710379942/dealogikal_2.0/resources/images/modal_image/22060-removebg-preview_1_wrago3.svg"
                  alt="modal"
                />
                <p className="mt-2">You have been choosen</p>
                <span className="text-dealogikal-200">
                  By{" "}
                  <span className=" font-bold">
                    {booking_details.company_name}
                  </span>{" "}
                  from reverse Auction
                </span>
              </div>

              <div className="border w-auto h-auto mx-4 rounded-lg mb-2 flex flex-wrap">
                <div className="flex-col w-full p-3 mt-2 py-5">
                  <div className="flex gap-5 items-center">
                    <div className="flex items-center gap-2">
                      <MapPinIcon className="h-5 w-5 text-gray-400" />
                      <span className="font-bold">Location</span>
                    </div>
                    <p className="text-gray-400">{booking_details.location}</p>
                  </div>
                  <div className="flex gap-12 items-center">
                    <div className="flex mt-2 gap-2">
                      <CalendarDaysIcon className="h-5 w-5 text-gray-400" />
                      <span className="font-bold">NBD</span>
                    </div>
                    <p className="text-gray-400">
                      {booking_details.delivery_date}
                    </p>
                  </div>
                  <hr className="w-full mt-2 border-dashed" />
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
                          <>
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="text-center">
                                <tr>
                                  {tableHeader.map((t) => (
                                    <th
                                      key={generateKey(t.name)}
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {/* DC: label here... */}
                                      <span className="align-middle">
                                        {t.value}
                                      </span>
                                      {/* DC: sorting icons here... */}
                                      {/* {t.name === sortBy && sort !== "" && sortBy !== "" ?  */}
                                      {/* : defaultSortIconComp(t.name)} */}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 text-center">
                                {items.map((row: any) => (
                                  <tr key={generateKey(row.name)}>
                                    <td
                                      key={generateKey(row.name)}
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                    >
                                      <div className="">{row.name}</div>
                                    </td>
                                    <td
                                      key={generateKey(row.volume)}
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                    >
                                      <div className="">{row.volume}</div>
                                    </td>
                                    <td
                                      key={generateKey(row.price)}
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                    >
                                      <div className="">{row.price}</div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-7 flex items-center gap-5">
                <input type="checkbox" className="border-dealogikal-200" />
                <p className="text-base text-gray-400">
                  Don't want to show this message again?
                </p>
              </div>
              <hr className="w-auto my-5 mx-5" />
              <div className="mb-5 flex justify-between mx-2">
                <ButtonComponent
                  text={`Cancel`}
                  utils="text-dealogikal-100 ring-1 ring-inset  ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-lg font-normal w-full focus:outline-none"
                  onClick={() => alert("ready to cancel")}
                />
                <ButtonComponent
                  text={`Confirm`}
                  utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-lg font-normal w-full focus:outline-none"
                  // onClick={() => alert("ready to confirm")} TODO: revert back to confirm
                  onClick={() => {
                    window.location.href =
                      "/s/reverse_auction/" +
                      booking_details.reverse_auction_id;
                  }}
                />
              </div>
            </>
          );
        }
      } else if (key === "VEHICLE_BOOKED") {
        if (path.includes("/l/reverse_auction/")) {
          // if inside reverse auction page
          modalContent = (
            <>
              <div className="flex flex-wrap justify-center mt-2 mx-20">
                {/* single card (start) */}
                <div className="flex flex-col py-5 w-full md:w-full items-center">
                  <ClipboardDocumentCheckIcon
                    className="mr-1.5 h-20 w-20 flex-shrink-0 text-green-600 animate-bounce"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex flex-col w-full py-1 px-5 md:w-full">
                  <span className="text-lg text-gray-500 text-center">
                    {`The supplier confirms the order.`}
                  </span>
                  <span className="text-lg text-gray-500 text-center">
                    {`Kindly confirm offer delivery booking.`}
                  </span>
                </div>
                <div className="inline-flex w-full justify-center pt-10 pb-12 md:w-full">
                  <ButtonComponent
                    text={`OK`}
                    utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-1/4 focus:outline-none"
                    onClick={closeGlobalModalHandler}
                  />
                </div>
                {/* single card (end) */}
              </div>
            </>
          );
        } else {
          const { booking_details, items } = data;
          modalContent = (
            <>
              <div className="relative md:mt-7 mt-5 flex flex-col mb-2  mx-auto justify-center items-center">
                <img
                  className="h-28 w-35"
                  src="https://res.cloudinary.com/doehyebmw/image/upload/v1710379942/dealogikal_2.0/resources/images/modal_image/22060-removebg-preview_1_wrago3.svg"
                  alt="modal"
                />
                <p className="mt-2">You have been choosen</p>
                <span className="text-dealogikal-200">
                  By{" "}
                  <span className=" font-bold">
                    {booking_details.company_name}
                  </span>{" "}
                  from reverse Auction
                </span>
              </div>

              <div className="border w-auto h-auto mx-4 rounded-lg mb-2 flex flex-wrap">
                <div className="flex-col w-full p-3 mt-2 py-5">
                  <div className="flex gap-5 items-center">
                    <div className="flex items-center gap-2">
                      <MapPinIcon className="h-5 w-5 text-gray-400" />
                      <span className="font-bold">Location</span>
                    </div>
                    <p className="text-gray-400">{booking_details.location}</p>
                  </div>
                  <div className="flex gap-12 items-center">
                    <div className="flex mt-2 gap-2">
                      <CalendarDaysIcon className="h-5 w-5 text-gray-400" />
                      <span className="font-bold">NBD</span>
                    </div>
                    <p className="text-gray-400">
                      {booking_details.delivery_date}
                    </p>
                  </div>
                  <hr className="w-full mt-2 border-dashed" />
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
                          <>
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="text-center">
                                <tr>
                                  {tableHeader.map((t) => (
                                    <th
                                      key={generateKey(t.name)}
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                      {/* DC: label here... */}
                                      <span className="align-middle">
                                        {t.value}
                                      </span>
                                      {/* DC: sorting icons here... */}
                                      {/* {t.name === sortBy && sort !== "" && sortBy !== "" ?  */}
                                      {/* : defaultSortIconComp(t.name)} */}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 text-center">
                                {items.map((row: any) => (
                                  <tr key={generateKey(row.name)}>
                                    <td
                                      key={generateKey(row.name)}
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                    >
                                      <div className="">{row.name}</div>
                                    </td>
                                    <td
                                      key={generateKey(row.volume)}
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                    >
                                      <div className="">{row.volume}</div>
                                    </td>
                                    <td
                                      key={generateKey(row.price)}
                                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                                    >
                                      <div className="">{row.price}</div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-7 flex items-center gap-5">
                <input type="checkbox" className="border-dealogikal-200" />
                <p className="text-base text-gray-400">
                  Don't want to show this message again?
                </p>
              </div>
              <hr className="w-auto my-5 mx-5" />
              <div className="mb-5 flex justify-between mx-2">
                <ButtonComponent
                  text={`Cancel`}
                  utils="text-dealogikal-100 ring-1 ring-inset  ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-lg font-normal w-full focus:outline-none"
                  onClick={() => alert("ready to cancel")}
                />
                <ButtonComponent
                  text={`Confirm`}
                  utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-lg font-normal w-full focus:outline-none"
                  // onClick={() => alert("ready to confirm")} TODO: revert back to confirm
                  onClick={() => {
                    window.location.href =
                      "/l/reverse_auction/" +
                      booking_details.reverse_auction_id;
                  }}
                />
              </div>
            </>
          );
        }
      }
      return modalContent;
    },
    [closeGlobalModalHandler, tableHeader]
  );

  // cja: this hook will handle GLOBAL websocket messages
  useEffect(() => {
    if (socketMessage !== null) {
      const strData: string = socketMessage.toString();
      if (strData === "Success") {
        // do something here
      } else {
        const socketData = JSON.parse(strData);
        dispatch(setSocketResponseData(socketData));

        const { payload } = socketData || {};

        if (payload === undefined) {
          console.log("socketData", socketData);
        } else {
          if (payload.key === "BEEN_BOOKED" && !isGlobalModalViewed) {
            // [for SUPPLIER]
            setGlobalModalHeaderContent(
              <span className="text-white inline md:text-base text-xs">
                Offer Booking
              </span>
            );
            setGlobalModalBodyContent(
              renderGlobalModalContent(
                location.pathname,
                "BEEN_BOOKED",
                payload.data
              )
            );
            setGlobalModalIsOpen(true);

            // save socket response data to redux
            const { offer_booking_id } = payload.data;
            dispatch(setOfferBookingId(parseInt(offer_booking_id)));
          } else if (payload.key === "VEHICLE_BOOKED" && !isGlobalModalViewed) {
            // [for LOGISTIC]
            setGlobalModalHeaderContent(
              <span className="text-white inline md:text-base text-xs">
                Offer Delivery Booking
              </span>
            );
            setGlobalModalBodyContent(
              renderGlobalModalContent(
                location.pathname,
                "VEHICLE_BOOKED",
                payload.data
              )
            );

            setGlobalModalIsOpen(true);

            // save socket response data to redux
            const { offer_booking_id } = payload.data;
            dispatch(setOfferBookingId(parseInt(offer_booking_id)));
          } else if (payload.key === "PAYMENT_READY") {
            // get new notification data for buyer
            const { notification } = payload.data;
            setNotifications((prev) => [notification[0], ...prev]);

            // save to snackbarData for showing the snackbar
            setSnackbarData({
              key: payload.key,
              data: payload.data,
            });
          } else if (payload.key === "VEHICLE_CONFIRMED") {
            // get new notification data for supplier
            const { notification } = payload.data;
            setNotifications((prev) => [notification[0], ...prev]);

            // show snackbar
            // save to snackbarData for showing the snackbar
            setSnackbarData({
              key: payload.key,
              data: payload,
            });
          } else if (payload.key === "NEW_NOTIFICATION") {
            // Get new notification data from supplier accreditation
            setNotifications((prev) => [payload.data, ...prev]);

            // if supplier approves a vehicle accreditation (for buyer and logistic snackbar)
            if (payload.data.title === "Vehicle Accreditation Approved") {
              // save to snackbarData for showing the snackbar
              setSnackbarData({
                key: payload.key,
                data: payload.data,
              });
            } else if (payload.data.title === "Payment Verification") {
              // if Payments are verified by admin
              setSnackbarData({
                key: payload.key,
                data: payload.data,
              });
            }
          }
        }
      }
    }
  }, [
    socketMessage,
    isGlobalModalViewed,
    location.pathname,
    renderGlobalModalContent,
    dispatch,
  ]);
  // General BookOffer WebSocket implimentation (end) ====================================================================>

  // fetching notification api (start) ================================================================================================>>

  useEffect(() => {
    if (notificationList.length === 0 && notificationListLoading) {
      const user_id: string = userData._id.toString();
      getNotificationsAPI(_token, user_id).then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          let newNotifications: NotificationType[] = [];

          data.items.forEach((item: any) => {
            newNotifications = [...newNotifications, item];
          });
          setNotifications(newNotifications);
          dispatch(setNotificationList(newNotifications));
        }
      });
      setNotificationListLoading(false);
    } else {
      setNotifications(notificationList);
    }
  }, [
    _token,
    userData._id,
    notificationListLoading,
    notificationList.length,
    dispatch,
    notificationList,
  ]);

  // fetching notification api (end)================================================================================================>>

  // cja: separate side menu click handler ---> store the selected menu.name into local storage for active menu rendering purposes
  const menuClickHandler = (menu_id: string, href: string) => {
    navigate(href);
    const menu = identifyMenu(userRole).filter(
      (m: any) => m.menu_id.toString() === menu_id
    );
    localStorage.setItem("activeMenu", menu.length > 0 ? menu[0].menu_id : "");
  };

  // modal confirmation for supplier (end)
  return (
    <>
      <PageContentComponent
        content={{
          content: (
            <>
              <div>
                {/* snackbar component  */}
                <SnackBarComponent
                  snackbarList={isSnackbarViewed ? [] : snackbars}
                />
                {/* embed the sidebar for mobile */}
                <SideBarComponentMobile
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                  mainMenu={identifyMenu(userRole)}
                  subMenu={prevTransactions}
                  menuClickHandler={menuClickHandler}
                />

                {/* embed sidebar for desktop */}
                <SideBarComponentDesktop
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                  mainMenu={identifyMenu(userRole)}
                  subMenu={prevTransactions}
                  menuClickHandler={menuClickHandler}
                />

                <div className="lg:pl-72">
                  {/* embed top navigation for all devices */}
                  <TopNavigationComponent
                    notificationsList={notifications}
                    userNavigation={userNavigation}
                    setSidebarOpen={setSidebarOpen}
                  />

                  <main className="py-4">
                    <div>{props.head_banner}</div>
                    <div className="px-4 sm:px-6 lg:px-8">
                      {/* import the content here... */}
                      {props.content}
                    </div>
                  </main>
                </div>
              </div>
            </>
          ),
        }}
      />
      <ModalComponent
        isOpen={globalModalIsOpen}
        onCloseHandler={closeGlobalModalHandler}
        header={
          <div className="bg-dealogikal-100 md:py-5 md:px-4 pt-3 pb-3 px-4 text-center">
            {globalModalHeaderContent}
          </div>
        }
        body={globalModalBodyContent}
      />
    </>
  );
};

export default MainConsoleLayoutComponent;
