import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getNextVehicleTemplateName,
  getUtilities,
  getVehiclesDetails,
  setSelectedAccreditSupplier,
  setUtilityCommodities,
  setVehicleCompartment,
  setTotalVolume,
} from "../../../../redux/functions/storageSlice";
import ComboboxComponent from "../../../templates/ComboboxComponent/ComboboxComponent";
import { VehicleTypeModel } from "../../../../model/interface/VehicleTypeModel";
import { ComboBoxItemType } from "../../../../model/types/ComboBoxItemType";
import {
  getCommodities,
  getVehicleCompartmentAPI,
  getVehicleManufactureAPI,
  getVehicleTypeAPI,
  getVehicleWheelAPI,
  addVehicle,
  getVehicleCargoAPI,
  getAccreditSupplierAPI,
} from "../../../../redux/functions/API";
import { VehicleWheelModel } from "../../../../model/interface/VehicleWheelModel";
import { VehicleManufactureModel } from "../../../../model/interface/VehicleManufactureModel";
import { VehicleCompartmentModel } from "../../../../model/interface/VehicleCompartmentModel";
import {
  CubeIcon,
  MagnifyingGlassIcon,
  RectangleGroupIcon,
  Square3Stack3DIcon,
  TruckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import Lottie from "lottie-react";
import CircularLoading from "../../../../lottie/circular_loading_theme_1.json";
import ChipsComponent from "../../../templates/ChipsComponent/ChipsComponent";
import { generateKey } from "../../../../redux/functions/function";
import ToasterComponent from "../../../templates/ToasterComponent/ToasterComponent";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { TokenModel } from "../../../../model/interface/TokenModel";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import MetaComponent from "../../../templates/MetaComponent/MetaComponent";
import { CommodityType } from "../../../../model/interface/CommodityType";
import SelectedAccreditSupplierType from "../../../../model/types/SelectedAccreditSupplierType";
import SupplierAccreditListType from "../../../../model/types/SupplierAccreditListType";

const AddVehicleComp: React.FC = () => {
  // declare navigation
  const navigate = useNavigate();
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  const dispatch = useAppDispatch();
  const createVechilesDetails = useAppSelector(getVehiclesDetails);
  const utilities = useAppSelector(getUtilities);
  const selectedCompartment = createVechilesDetails.vehicleCompartment;
  const selectedAccreditSupplier = createVechilesDetails.selectedSupplier;

  // janm: item details functionality code (start)
  const [listOfCommodities, setListOfCommodities] = useState(
    utilities.commodities
  );

  const [isTitleInEditState, setIsTitleInEditState] = useState<boolean>(false);
  const nextVehicleTemplateName = useAppSelector(getNextVehicleTemplateName);
  const [newSubTitle, setNewSubTitle] = useState<string>(
    nextVehicleTemplateName
  );
  const [selectedCommodities, setSelectedCommodities]: any = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });

  const [selectedVehicleWheel, setSelectedVehicleWheel]: any = useState(null);
  const [selectedVehicleManufacture, setSelectedVehicleManufacture]: any =
    useState(null);
  const [selectedVehicleCompartment, setselectedVehicleCompartment] = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });
  const [plateNumber, setPlateNumber] = useState<string>("");
  const [volume, setVolume] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [listOfVehicleWheel, setListOfVehicleWheel] =
    useState<ComboBoxItemType>([]);
  const [listOfVehicleManufacture, setListOfVehicleManufacture] =
    useState<ComboBoxItemType>([]);
  const [listOfVehicleCompartment, setListOfVehicleCompartment] =
    useState<ComboBoxItemType>([]);
  const [vehicleCargoIsError, setVehicleCargoIsError] =
    useState<boolean>(false);

  const [wheelIsError, setIsWheelIsError] = useState<boolean>(false);
  const [manufactureIsError, setManufactureIsError] = useState<boolean>(false);
  const [compartmentIsError, setcompartmentIsError] = useState<boolean>(false);
  const [plateNumberIsError, setPlateNumberIsError] = useState<boolean>(false);
  const [volumeIsError, setVolumeIsError] = useState<boolean>(false);
  const templateNameIconClickHandler = () => {
    setIsTitleInEditState(!isTitleInEditState);
  };

  const subtitleOnBlurHandler = (new_title: string) => {
    if (new_title.trim() !== "") {
      setNewSubTitle(new_title);
    } else {
      setNewSubTitle(nextVehicleTemplateName);
    }
  };

  const [isVehicleTypeFetching, setIsVehicleTypeFetching] =
    useState<boolean>(true);
  const [isVehicleCargoFetching, setIsVehicleCargoFetching] =
    useState<boolean>(true);
  const [isVehicleWheelFetching, setIsVehicleWheelFetching] =
    useState<boolean>(true);
  const [isVehicleManufactureFetching, setIsVehicleManufactureFetching] =
    useState<boolean>(true);
  const [isVehicleCompartmentFetching, setIsVehicleCompartmentFetching] =
    useState<boolean>(true);
  const [isNoCompartmentAdded, setIsNoCompartmentAdded] =
    useState<boolean>(false);
  const [isAddingTruck, setIsAddingTruck] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("");
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState<
    SelectedAccreditSupplierType[]
  >([]);
  const [isSupplierFetching, setIsSupplierFetching] = useState<boolean>(true);
  const [supplierID, setSupplierID] = useState<string>("");
  const [supplierList, setSupplierList] = useState<SupplierAccreditListType[]>(
    []
  );

  const [selectedCommodityTypes, setSelectedCommodityTypes] = useState<
    CommodityType[]
  >([]);

  const [selectedSupplierID, setselectedSupplierID] = useState<number[]>([]);
  const [isDisplaySelectAccreditError, setIsDisplaySelectAccreditError] =
    useState<boolean>(false);
  // janm: flag for the active selected vehicle modal
  const [activeSelectedAccreditSupplier, setactiveSelectedAccreditSupplier] =
    useState<string>("Supplier");
  // janm: this array of objects will be passed in the
  // NavTab components for populating the nav items
  const SelectAccreditSupplierItems = [
    {
      text: "Suppliers",
    },
    {
      text: "Unlisted(0)",
    },
  ];

  // Check if next template name is not empty
  useEffect(() => {
    if (newSubTitle === "") {
      navigate("/l/vehicle_manager");
    }
  }, [navigate, newSubTitle]);

  // jamn: Vehicle Type list
  useEffect(() => {
    if (isVehicleTypeFetching) {
      getVehicleTypeAPI(_token)
        .then((response: any) => {
          if (response.statusCode === 200) {
            let newListOfVehicleType: ComboBoxItemType = [];
            response.data.items.forEach((vehicle_type: VehicleTypeModel) => {
              let singleVehicleType = {
                id: vehicle_type.vehicle_type_id,
                name: vehicle_type.vehicle_type_name,
              };
              newListOfVehicleType = [
                ...newListOfVehicleType,
                singleVehicleType,
              ];
            });
            // Set the list of vehicle Types
            //setListOfVehicleType(newListOfVehicleType);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsVehicleTypeFetching(false);
        });
    }
  }, [isVehicleTypeFetching, _token]);

  const [vehicleCargos, setVehicleCargos] = useState<ComboBoxItemType>([]);

  function getLastTwoIdsFromBreadcrumb() {
    const breadcrumb = sessionStorage.getItem("breadcrumb");
    if (!breadcrumb) {
      return [null, null];
    }

    const parsedBreadcrumb = JSON.parse(breadcrumb);
    const length = parsedBreadcrumb.length;

    if (length < 2) {
      return [null, null]; // Or handle the case where there are fewer than 2 items
    }

    const lastTwo = parsedBreadcrumb.slice(length - 2);
    return lastTwo.map((item: { id: any }) => item.id);
  }

  const [commodityId, vehicleTypeId] = getLastTwoIdsFromBreadcrumb();

  useEffect(() => {
    if (isVehicleCargoFetching) {
      if (commodityId && vehicleTypeId) {
        getVehicleCargoAPI(_token, commodityId, vehicleTypeId).then(
          (response: any) => {
            if (response.statusCode === 200) {
              let newlistVehicleCargo: ComboBoxItemType = [];
              response.data.items.forEach((vehicle_cargo: CommodityType) => {
                let vehicleCargo = {
                  id: vehicle_cargo.commodity_id,
                  name: vehicle_cargo.commodity_name,
                };
                newlistVehicleCargo = [...newlistVehicleCargo, vehicleCargo];
              });
              setVehicleCargos(newlistVehicleCargo);
            }
          }
        );
      }
      setIsVehicleCargoFetching(false);
    }
  }, [
    isVehicleCargoFetching,
    _token,
    setVehicleCargos,
    vehicleTypeId,
    commodityId,
  ]);

  // useEffect to clear session storage on page unload
  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.removeItem("breadcrumb");
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  // janm: Vehicle Wheel list
  useEffect(() => {
    if (isVehicleWheelFetching) {
      getVehicleWheelAPI(_token)
        .then((response: any) => {
          if (response.statusCode === 200) {
            let newListOfVehicleWheel: ComboBoxItemType = [];
            response.data.items.forEach((vehicle_wheel: VehicleWheelModel) => {
              let singleVehicleWheel = {
                id: vehicle_wheel.vehicle_wheel_id,
                name: vehicle_wheel.wheel_description,
              };
              newListOfVehicleWheel = [
                ...newListOfVehicleWheel,
                singleVehicleWheel,
              ];
            });
            // Set the list of vehicle wheel
            setListOfVehicleWheel(newListOfVehicleWheel);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsWheelIsError(true);
        })
        .finally(() => {
          setIsVehicleWheelFetching(false);
        });
    }
  }, [isVehicleWheelFetching, _token]);

  // janm: Vehicle Manufacture
  useEffect(() => {
    if (isVehicleManufactureFetching) {
      getVehicleManufactureAPI(_token)
        .then((response: any) => {
          if (response.statusCode === 200) {
            let newListOfVehicleManufacture: ComboBoxItemType = [];
            response.data.items.forEach(
              (vehicle_manufacture: VehicleManufactureModel) => {
                let singleVehicleManufacture = {
                  id: vehicle_manufacture.vehicle_manufacture_id,
                  name: vehicle_manufacture.manufacture_name,
                };
                newListOfVehicleManufacture = [
                  ...newListOfVehicleManufacture,
                  singleVehicleManufacture,
                ];
              }
            );
            // Set the list of Vehicle Wheel
            setListOfVehicleManufacture(newListOfVehicleManufacture);
          }
        })
        .catch((error) => {
          console.log(error);
          setManufactureIsError(true);
        })
        .finally(() => {
          setIsVehicleManufactureFetching(false);
        });
    }
  }, [isVehicleManufactureFetching, _token]);

  // janm: function that handles the display of the error message in
  // in selecting products beyond 3
  const selectionErrorHandler = () => {
    setIsDisplaySelectAccreditError(true);
    setTimeout(() => {
      setIsDisplaySelectAccreditError(false);
    }, 3000);
  };

  // Janm: vehicle compartments
  useEffect(() => {
    if (isVehicleCompartmentFetching) {
      getVehicleCompartmentAPI(_token)
        .then((response: any) => {
          if (response.statusCode === 200) {
            let newListOfVehicleCompartment: ComboBoxItemType = [];
            response.data.items.forEach(
              (vehicle_compartment: VehicleCompartmentModel) => {
                let singleVehicleCompartment = {
                  id: vehicle_compartment.compartment_id,
                  name: vehicle_compartment.description,
                };
                newListOfVehicleCompartment = [
                  ...newListOfVehicleCompartment,
                  singleVehicleCompartment,
                ];
              }
            );
            // Set the list of Vehicle Compartments
            setListOfVehicleCompartment(newListOfVehicleCompartment);
          }
        })
        .catch((error) => {
          console.log(error);
          setcompartmentIsError(true);
        })
        .finally(() => {
          setIsVehicleCompartmentFetching(false);
        });
    }
  }, [isVehicleCompartmentFetching, _token]);

  // Janm: commodity
  useEffect(() => {
    // if commodity types is empty
    if (listOfCommodities.length === 0)
      getCommodities().then((data: any) => {
        dispatch(setUtilityCommodities(data));
        setListOfCommodities(data);
      });
  }, [dispatch, listOfCommodities]);

  // Janm: add compartments button click handler function
  const addCompartmentClickHandler = () => {
    if (selectedVehicleCompartment.id !== "") {
      const updatedSelectedCompartment = [
        ...selectedCompartment,
        {
          compartment_id: selectedVehicleCompartment.id,
          description: selectedVehicleCompartment.name,
          uniqueId: Date.now(),
        },
      ];

      // Calculate total volume with the potentially new compartment
      const tempTotalVolume = computeVolumeByCompartment(
        updatedSelectedCompartment
      );

      // If the new total does not exceed 40,000, proceed with the update
      if (tempTotalVolume <= 40000) {
        dispatch(setVehicleCompartment(updatedSelectedCompartment));
        setVolume(tempTotalVolume);
        dispatch(setTotalVolume(tempTotalVolume));
        setIsNoCompartmentAdded(false);
      } else {
        // If exceeding 40,000, alert the user or handle as needed
        alert(
          "Adding this compartment would exceed the total volume limit of 40,000."
        );
      }
    }
  };

  // Calculate/compute the volume
  // TODO: Not sure that compartment is equal to volume
  const computeVolumeByCompartment = (compartmentObject: any) => {
    let totalVolume = 0;
    compartmentObject.forEach((compartment: any) => {
      totalVolume += parseInt(compartment.description);
    });
    return totalVolume;
  };

  // Janm: remove compartment chips handler
  const removeCompartmentChiphandler = (uniqueId: number) => {
    const updatedSelectedCompartment = selectedCompartment.filter(
      (compartment) => compartment.uniqueId !== uniqueId
    );

    const totalVolume = computeVolumeByCompartment(updatedSelectedCompartment);

    dispatch(setTotalVolume(totalVolume));
    dispatch(setVehicleCompartment(updatedSelectedCompartment));
    setVolume(totalVolume);
  };

  // when each dropbox change
  useEffect(() => {
    if (selectedCommodities !== null) {
      setVehicleCargoIsError(false);
    }
    if (selectedVehicleWheel !== null) {
      setIsWheelIsError(false);
    }
    if (selectedVehicleManufacture !== null) {
      setManufactureIsError(false);
    }
    if (selectedVehicleCompartment.id) {
      setcompartmentIsError(false);
    }
    if (volume) {
      setVolumeIsError(false);
    }
    if (plateNumber) {
      setPlateNumberIsError(false);
    }
  }, [
    selectedCommodities,
    selectedVehicleWheel,
    selectedVehicleManufacture,
    selectedVehicleCompartment.id,
    volume,
    plateNumber,
  ]);

  // janm: check if to_select vehicle already exists
  const checkSupplierExist = (supplier_id: number) => {
    return new Promise((resolve, reject) => {
      resolve(selectedSupplierID.includes(supplier_id));
    });
  };

  const sortSelectedSupplier = (
    arr: SelectedAccreditSupplierType[],
    base_type: string
  ) => {
    arr.sort(function (a: any, b: any) {
      var result = 0;
      if (base_type === "alphabet") {
        var textA = a.supplier.company_name.toString().toLowerCase();
        var textB = b.supplier.company_name.toString().toLowerCase();
        result = textA < textB ? -1 : textA > textB ? 1 : 0;
      } else if (base_type === "numeric") {
        // to be enhance
      }
      return result;
    });
  };

  // janm: useEffect hook that extracts the product ids
  // and store at an array if changes detected
  useEffect(() => {
    let newsupplierIDArray: number[] = [];
    selectedSupplier.forEach(
      (selected_supplier: SelectedAccreditSupplierType) => {
        newsupplierIDArray = [
          ...newsupplierIDArray,
          selected_supplier.supplier.user_id,
        ];
      }
    );

    setselectedSupplierID(newsupplierIDArray);
  }, [selectedSupplier]);

  // remove handler
  const removeSupplierChipHandler = (supplier_id: number) => {
    const newSelectedSupplier: SelectedAccreditSupplierType[] =
      selectedSupplier.filter((supplier: SelectedAccreditSupplierType) => {
        return supplier.supplier.user_id.toString() !== supplier_id.toString();
      });
    setSelectedSupplier(newSelectedSupplier);
    dispatch(setSelectedAccreditSupplier(JSON.stringify(newSelectedSupplier)));
  };

  // janm: suppliers list inside modal checkbox handler
  const selectionHandler = (user_id: number) => {
    // get the entire list
    let toSelectSupplier: any = [];
    supplierList.forEach((supp: SupplierAccreditListType) => {
      if (supp.user_id === user_id) {
        toSelectSupplier = [...toSelectSupplier, supp];
      }
    });

    // check if supplier_id exist in the selectedSupplier
    checkSupplierExist(user_id).then((res) => {
      if (!res && selectedSupplier.length < 3) {
        // if not selected, then add product name to selected array
        let newSelectedSupplier: SelectedAccreditSupplierType[] = [];
        newSelectedSupplier = [
          ...selectedSupplier,

          {
            supplier: toSelectSupplier[0],
            fields: "",
          },
        ];
        setSelectedSupplier(newSelectedSupplier);
      } else if (res && selectedSupplier.length <= 3) {
        let newSelectedSupplier: SelectedAccreditSupplierType[] = [];
        selectedSupplier.forEach(
          (selected_supplier: SelectedAccreditSupplierType) => {
            if (selected_supplier.supplier.user_id !== user_id) {
              newSelectedSupplier = [...newSelectedSupplier, selected_supplier];
            }
          }
        );
        setSelectedSupplier(newSelectedSupplier);
      } else {
        selectionErrorHandler();
      }
    });
  };

  // janm: confirm selection handler function
  const confirmSelectionHandler = () => {
    const sortSupplierSelected = [...selectedSupplier];
    sortSelectedSupplier(sortSupplierSelected, "alphabet");
    dispatch(setSelectedAccreditSupplier(JSON.stringify(sortSupplierSelected)));
    const supplier_id: any = sortSupplierSelected.map(
      (arr) => arr.supplier.user_id
    );
    setSupplierID(supplier_id);
    setIsModalOpen(false);
  };

  // api for suppliers list
  useEffect(() => {
    const fetchData = async () => {
      if (isSupplierFetching && selectedCommodityTypes.length > 0) {
        try {
          let commodities = selectedCommodityTypes.map(
            (commodity) => commodity.commodity_id
          );

          const response: any = await getAccreditSupplierAPI(_token, {
            commodities,
          });
          // Handle response here
          setSupplierList(response.data.items);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setIsSupplierFetching(false);
      }
    };

    if (selectedCommodityTypes.length > 0) {
      fetchData();
    }
  }, [_token, isSupplierFetching, selectedCommodityTypes]);

  const handlerAddTruck = async () => {
    // error handling if user not selected item or input
    if (isLoadingAPI) {
      return;
    }
    if (!selectedCommodities.id) {
      setVehicleCargoIsError(true);
    }
    if (!selectedVehicleWheel) {
      setIsWheelIsError(true);
    }
    if (!selectedVehicleManufacture) {
      setManufactureIsError(true);
    }
    if (!selectedVehicleCompartment.id) {
      setcompartmentIsError(true);
    }
    if (!plateNumber) {
      setPlateNumberIsError(true);
    }
    if (!volume) {
      setVolumeIsError(true);
    }
    // success if user selected each item or input
    if (
      newSubTitle &&
      commodityId &&
      vehicleTypeId &&
      selectedCommodities.id &&
      selectedVehicleWheel &&
      selectedVehicleManufacture &&
      selectedVehicleCompartment.id &&
      plateNumber &&
      volume &&
      supplierID
    ) {
      const data: any = {
        commodities: selectedCommodityTypes.map(
          (commodity) => commodity.commodity_id
        ),
        commodity_type_id: commodityId,
        vehicle_name: newSubTitle,
        vehicle_type_id: vehicleTypeId,
        vehicle_wheel_id: selectedVehicleWheel?.id,
        compartments: selectedCompartment.map(
          (compartment) => compartment.compartment_id
        ),
        plate_number: plateNumber,
        volume: volume,
        manufacture_id: selectedVehicleManufacture?.id,
        user_id: userData._id,
        supplier_id: supplierID,
      };
      setIsAddingTruck(true);
      try {
        setIsButtonDisabled(true);
        setIsLoadingAPI(true);
        await addVehicle(_token, data);
        setToastMessage("New Vehicle has been saved.");
        setToastType("success");
        setIsToastOpen(true);
        setTimeout(() => {
          setIsToastOpen(false);
          setIsLoadingAPI(false);
          setIsButtonDisabled(true);
          navigate("/l/vehicle_manager");
        }, 4000);

        // Log success message
        dispatch(setVehicleCompartment([]));
        dispatch(setSelectedAccreditSupplier(""))
      } catch (error: any) {
        if (error.response && error.response.statusCode === 409) {
          setToastMessage("Vehicle name or plate number already exists");
          setToastType("error");
          setIsToastOpen(true);
          setIsLoadingAPI(false);
          setIsButtonDisabled(false);
          setIsAddingTruck(false);
        } else {
          console.error("Error adding vehicle:", error);
          setToastMessage(
            `Sorry, a vehicle with the title "${newSubTitle}" or the plate number you've enter  already exists. Please choose a different title or plate number`
          ); //setToastMessage("An error occurred while adding the vehicle");
          setToastType("error");
          setIsToastOpen(true);
          setIsLoadingAPI(false);
          setIsButtonDisabled(false);
          setIsAddingTruck(false);
        }
      }
    }
  };

  // Metadata
  const [metaData, setMetaData] = useState([]);

  useEffect(() => {
    // Fetch the steps from session storage
    const strSteps = sessionStorage.getItem("breadcrumb");

    if (strSteps) {
      const steps = JSON.parse(strSteps);
      const stepsLength = steps.length;

      // Update metadata based on session data
      const updatedMetaData = steps
        .map((step: any, index: number) => {
          if (index === stepsLength - 2) {
            // First step
            return {
              text: step.name,
              icon: <CubeIcon className="-ml-0.5 h-5 w-5" />,
            };
          } else if (index === stepsLength - 1) {
            // Second to last step
            return {
              text: step.name,
              icon: <TruckIcon className="-ml-0.5 h-5 w-5" />,
            };
          } else if (index === stepsLength - 0) {
            // Last step
            return {
              text: step.name,
              icon: <TruckIcon className="-ml-0.5 h-5 w-5" />,
            };
          } else {
            return null; // Handle other steps if needed
          }
        })
        .filter(Boolean); // Remove any null entries

      setMetaData(updatedMetaData);
    }
  }, []);

  const addCommodityClickHandler = () => {
    // Check if there is a selected commodity and if it's not already in the list
    if (
      selectedCommodities &&
      !selectedCommodityTypes.some(
        (c) => c.commodity_id === selectedCommodities.id
      )
    ) {
      setSelectedCommodityTypes([
        ...selectedCommodityTypes,
        {
          commodity_id: selectedCommodities.id,
          commodity_name: selectedCommodities.name,
        },
      ]);
    }
  };

  // Handler to remove a commodity from the selected list
  const removeCommodityHandler = (id: number) => {
    setSelectedCommodityTypes(
      selectedCommodityTypes.filter((c) => c.commodity_id !== id)
    );
  };

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          <ToasterComponent
            isOpen={isToastOpen}
            label={toastMessage}
            onClose={setIsToastOpen}
            type={toastType}
          />
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <BreadcrumbContainerComponent
            key={Math.random()}
            subtitle={newSubTitle}
            isTitleEditable={true} // indicated when the page title is editable
            isTitleInEditState={isTitleInEditState} // indication if show input textbox or not; by default set to false to hide input textbox
            titleIconClickHandler={templateNameIconClickHandler}
            subtitleOnBlurHandler={subtitleOnBlurHandler}
          />
          <ButtonComponent
            text={`${isButtonDisabled ? "Adding Truck" : "Add Truck"}`}
            icon={
              isLoadingAPI && (
                <Lottie
                  className="md:w-5 w-5 h-auto"
                  animationData={CircularLoading}
                  loop={true}
                />
              )
            }
            disabled={isButtonDisabled}
            utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 -mt-5 py-3.5 mb-5 float-right sm:hover:bg-dealogikal-200
                    duration-500 rounded-full font-normal md:w-56 w-full ${
                      isAddingTruck ? "cursor-not-allowed opacity-50" : ""
                    } `}
            onClick={handlerAddTruck}
          />
          {/**------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <div className="flex flex-wrap md:-mt-5 mt-0 mb-3 ml-1 justify-between">
            <div className="flex flex-col justify-start text-black bg-gray-100 px-8 py-3 pt-4 rounded-3xl">
              <MetaComponent
                key={generateKey(Math.random().toString())}
                meta_data={metaData}
                placement="start"
              />
            </div>
          </div>

          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <div className="flex ml-4 mt-2 md:mt-10">
            <div className="grid">
              <div
                key={Math.random()}
                className="col-span-1 divide-y divide-gray-200 text-gray-400"
              >
                Details
              </div>
            </div>
          </div>
          <div className="mt-1 mb-2">
            <hr className="w-full ml-2 "></hr>
          </div>
          {/* input vehicle details */}
          <div className="flex flex-col w-full md:pt-4 md:pl-2 md:w-full ml-2">
            <div className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2  ">
              {/* vehicle type */}
              <div className="col-span-1 rounded-lg border bg-white shadow-sm px-4 py-6">
                <label
                  htmlFor="txtvehicletype"
                  className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
                >
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Select Commodities</span>
                  </div>
                </label>
                <div className="mt-2 mb-2">
                  <ComboboxComponent
                    hasError={vehicleCargoIsError}
                    items={vehicleCargos || []}
                    placeholder="Please select Commodities"
                    selectedItem={selectedCommodities} //change
                    setSelectedItem={setSelectedCommodities}
                  />
                </div>
                {vehicleCargoIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Vehicle Type is required
                    </p>
                  </div>
                )}
                <label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Selected Commodities</span>
                  </div>
                </label>

                {/** add chipcomponent here */}
                {selectedCommodityTypes.map((commodity) => (
                  <ChipsComponent
                    key={commodity.commodity_id}
                    text={commodity.commodity_name}
                    removeHandler={() =>
                      removeCommodityHandler(commodity.commodity_id)
                    }
                  />
                ))}
                <ButtonComponent
                  text="Select Commodities"
                  icon={
                    false && (
                      <Lottie
                        className="md:w-5 w-1 h-auto"
                        animationData={CircularLoading}
                        loop={true}
                      />
                    )
                  }
                  disabled={
                    selectedCommodities.id === 0 ||
                    selectedCommodities.name === ""
                  }
                  utils={`bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-4 ${
                    selectedCommodities.id === 0 ||
                    selectedCommodities.name === ""
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  onClick={addCommodityClickHandler}
                />

                <label
                  htmlFor="txtPlateNumber"
                  className="block text-sm font-medium leading-6 text-gray-900 "
                >
                  <div className="flex gap-2">
                    <Square3Stack3DIcon className="-ml-0.5 h-5 w-5" />
                    <span>Plate Number</span>
                  </div>
                </label>
                <div className="mt-2">
                  <input
                    value={plateNumber}
                    type="text"
                    name="txtPlateNumber"
                    id="txtPlateNumber"
                    placeholder="Please input your Plate Number"
                    className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                      plateNumberIsError ? "red-600" : "gray-300"
                    } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPlateNumber(e.target.value)
                    }
                  />
                </div>
                {plateNumberIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Plate Number is required
                    </p>
                  </div>
                )}

                {/**-------Vehicle Wheel ------------------------------------ */}
                <label
                  htmlFor="txtvehiclewheel"
                  className="block text-sm font-medium leading-6 text-gray-900 md:mt-6 mt-6"
                >
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Vehicle Wheel</span>
                  </div>
                </label>
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={wheelIsError}
                    items={listOfVehicleWheel}
                    placeholder="Please select Vehicle Wheel"
                    selectedItem={selectedVehicleWheel}
                    setSelectedItem={setSelectedVehicleWheel}
                  />
                </div>
                {wheelIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Vehicle Wheel is required
                    </p>
                  </div>
                )}

                {/**--------- Manufacturing */}
                <label
                  htmlFor="txtvehiclewheel"
                  className="block text-sm font-medium leading-6 text-gray-900 md:mt-6 mt-6"
                >
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Manufacture</span>
                  </div>
                </label>
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={manufactureIsError}
                    items={listOfVehicleManufacture}
                    placeholder="Please select Manufacture"
                    selectedItem={selectedVehicleManufacture}
                    setSelectedItem={setSelectedVehicleManufacture}
                  />
                </div>
                {manufactureIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Vehicle Manufacture is required
                    </p>
                  </div>
                )}
              </div>
              {/* janm: another card */}
              <div className="col-span-1 rounded-lg border bg-white shadow-sm px-4 py-6">
                <label
                  htmlFor="txtvehiclewheel"
                  className="block text-sm font-medium leading-6 text-gray-900 md:mt-6 mt-6"
                >
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Compartments</span>
                  </div>
                </label>
                <div className="mt-2 ">
                  <ComboboxComponent
                    hasError={compartmentIsError}
                    items={listOfVehicleCompartment}
                    placeholder="Please select Compartments"
                    selectedItem={selectedVehicleCompartment}
                    setSelectedItem={setselectedVehicleCompartment}
                  />
                </div>
                {compartmentIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Vehicle Manufacture is required
                    </p>
                  </div>
                )}
                <label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Selected Compartment</span>
                  </div>
                </label>
                <div
                  className={`border-0 h-auto ${
                    selectedCompartment.length === 0 ? "py-2.5" : ""
                  } ${
                    isNoCompartmentAdded
                      ? "ring-1 ring-inset ring-red-600 rounded-2xl py-4 mt-2"
                      : ""
                  }`}
                >
                  {selectedCompartment.length !== 0 &&
                    selectedCompartment.map(
                      (selected_compartment: VehicleCompartmentModel) => {
                        return (
                          <ChipsComponent
                            key={generateKey(
                              Math.random.toString() +
                                selected_compartment.compartment_id
                            )}
                            text={`${selected_compartment.description}`}
                            removeHandler={() =>
                              removeCompartmentChiphandler(
                                selected_compartment.uniqueId
                              )
                            }
                          />
                        );
                      }
                    )}
                  {selectedCompartment.length === 0 &&
                    selectedVehicleCompartment !== null && (
                      <p className="ml-6 text-xs text-gray-400">
                        -- No added compartment --
                      </p>
                    )}
                  <ButtonComponent
                    text="Add Compartment"
                    icon={
                      false && (
                        <Lottie
                          className="md:w-5 w-1 h-auto"
                          animationData={CircularLoading}
                          loop={true}
                        />
                      )
                    }
                    disabled={false}
                    utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-4"
                    onClick={addCompartmentClickHandler}
                  />
                </div>
                <label
                  htmlFor="txtvolume"
                  className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                >
                  <div className="flex gap-2">
                    <Square3Stack3DIcon className="-ml-0.5 h-5 w-5" />
                    <span>Total of Volume</span>
                  </div>
                </label>
                <div className="mt-2">
                  <input
                    value={volume}
                    type="text"
                    name="txtvolume"
                    id="txtvolume"
                    disabled
                    placeholder="Set range of volume"
                    className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                      volumeIsError ? "red-600" : "gray-300"
                    } placeholder:text-gray-300 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                    onChange={addCompartmentClickHandler}
                  />
                </div>
                {volumeIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Volume field is required
                    </p>
                  </div>
                )}
                <label
                  htmlFor="txtaccredit"
                  className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                >
                  <div className="flex gap-2">
                    <UserIcon className="-ml-0.5 h-5 w-5" />
                    <span>Select Supplier for Accreditation</span>
                  </div>
                </label>
                <ButtonComponent
                  text="Select Accredit Supplier"
                  icon={
                    false && (
                      <Lottie
                        className="md:w-5 w-1 h-auto"
                        animationData={CircularLoading}
                        loop={true}
                      />
                    )
                  }
                  disabled={false}
                  utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-4"
                  onClick={() => setIsModalOpen(true)}
                />
                {selectedAccreditSupplier !== "" &&
                  JSON.parse(selectedAccreditSupplier).map(
                    (selected_supplier: SelectedAccreditSupplierType) => {
                      return (
                        <ChipsComponent
                          key={generateKey(
                            Math.random.toString() +
                              selected_supplier.supplier.user_id
                          )}
                          text={`${selected_supplier.supplier.company_name}`}
                          removeHandler={() => {
                            removeSupplierChipHandler(
                              selected_supplier.supplier.user_id
                            );
                          }}
                        />
                      );
                    }
                  )}
                {/**------------------------------selectedAccreditSupplier  */}
                {selectedAccreditSupplier === "" &&
                  selectedAccreditSupplier !== null && (
                    <p className="ml-6 text-lg text-gray-400 text-center mt-16">
                      -- No supplier selected --
                    </p>
                  )}
                <ModalComponent
                  isOpen={isModalOpen}
                  onCloseHandler={() => setIsModalOpen(false)}
                  header={
                    <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                      <RectangleGroupIcon
                        className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                        aria-hidden="true"
                      />
                      <span className="text-white inline md;text-base text-xs">
                        Select Accredit Supplier
                      </span>
                    </div>
                  }
                  body={
                    <>
                      <div className="relative rounded-md md-py-0 md:px-0 px-2 md:mt-7 mt-5 md:mx-7 mx-3">
                        <input
                          type="text"
                          name="account-number"
                          id="account-number"
                          className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                          placeholder="Search product"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
                          <MagnifyingGlassIcon
                            className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <div className="md:mx-0 mx-3.5">
                        <NavTabComponent
                          key={Math.random()}
                          nav_type="secondary"
                          active_tab={activeSelectedAccreditSupplier}
                          nav_items={SelectAccreditSupplierItems}
                          setActiveNavHandler={
                            setactiveSelectedAccreditSupplier
                          }
                        />
                      </div>
                      {isDisplaySelectAccreditError && (
                        <div className="md:px-6 px-5 md:mt-5">
                          <span className="text-sm text-red-600">
                            You can only select up to 3 vehicles
                          </span>
                        </div>
                      )}
                      <div className="flex flex-wrap justify-start">
                        <div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
                          {activeSelectedAccreditSupplier.includes(
                            "Supplier"
                          ) && (
                            <>
                              {supplierList.length !== 0 && (
                                <>
                                  <div className="md:px-6 px-5 justify-center ">
                                    <div className="table w-full pt-6">
                                      <div className="table-row-group">
                                        {supplierList.map(
                                          (
                                            supplier: SupplierAccreditListType
                                          ) => {
                                            return (
                                              <div
                                                key={generateKey(
                                                  supplier.user_id.toString() +
                                                    Math.random()
                                                )}
                                              >
                                                <div className="table-row mt-2">
                                                  <div className="table-cell text-center md:w-1/12">
                                                    <input
                                                      id={
                                                        `cb` + supplier.user_id
                                                      }
                                                      name={
                                                        `cb` + supplier.user_id
                                                      }
                                                      type="checkbox"
                                                      checked={selectedSupplierID.includes(
                                                        supplier.user_id
                                                      )}
                                                      className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
                                                      value={supplier.user_id}
                                                      onChange={(event) =>
                                                        selectionHandler(
                                                          parseInt(
                                                            event.target.value
                                                          )
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="table-cell md:w-11/12">
                                                    <span className="font-normal md:text-base text-xs ml-4 flex gap-5">
                                                      <span className="font-extrabold text">
                                                        {`${supplier.company_name}`}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <br />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center md:mt-4 md:mx-5 mx-5">
                                    <ButtonComponent
                                      text="Confirm"
                                      utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 rounded-md py-2.5 ${
                                        selectedSupplier.length === 0
                                          ? "disabled:opacity-50 cursor-arrow"
                                          : "hover:bg-dealogikal-200 cursor-pointer"
                                      } duration-500 rounded-full font-normal md:w-11/12 w-full`}
                                      onClick={confirmSelectionHandler}
                                      disabled={
                                        selectedSupplier.length === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </>
                              )}
                              {selectedCommodityTypes.length === 0 && (
                                <>
                                  <div className="md:px-6 px-5 justify-center ">
                                    <div className="table w-full pt-6">
                                      <div className="table-row-group">
                                        <p className="text-gray-400 text-center mb-5 text-sm">
                                          {" "}
                                          -- Please select commodity first --{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center md:mt-4 md:mx-5 mx-5">
                                    <ButtonComponent
                                      text="Confirm"
                                      utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 rounded-md py-2.5 ${
                                        selectedSupplier.length === 0
                                          ? "disabled:opacity-50 cursor-arrow"
                                          : "hover:bg-dealogikal-200 cursor-pointer"
                                      } duration-500 rounded-full font-normal md:w-11/12 w-full`}
                                      onClick={confirmSelectionHandler}
                                      disabled={
                                        selectedSupplier.length === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default AddVehicleComp;
