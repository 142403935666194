import { useNavigate } from "react-router-dom";
import { manageBreadcrumb } from "../../../redux/functions/function";
import { DefaultDataModel } from "../../../model/interface/DefaultDataModel";

const FullRoundCardComponent = (props: DefaultDataModel) => {
  // declare navigation
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-wrap md:-mt-6 justify-center md:justify-normal">
        {props.data.map((item, i: number) => (
          <div
            key={Math.random().toString() + i.toString()}
            className="flex flex-col h-36 w-36 mx-2 md:h-64 md:w-64 md:mx-10 mt-0 md:mt-8 rounded-full overflow-hidden bg-gray-100 cursor-pointer group sm:hover:bg-dealogikal-100 duration-500"
            onClick={() => manageBreadcrumb(item, () => navigate(item.href))}
          >
            <div className="flex flex-col md:h-3/5 md:w-2/3 mx-auto pt-8 md:pt-5 py-auto">
              <item.icon className="group-hover:text-white h-10 md:h-auto w-auto m-1 md:m-6" />
            </div>
            <div className="flex flex-col md:h-2/5 w-full md:w-full">
              <h2 className="text-center text-gray-900 group-hover:text-white md:text-xl text-xs md:mt-2 font-bold">
                {item.name}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FullRoundCardComponent;
