import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableComponent from "../../../../templates/TableComponent/TableComponent";
import { ClipboardIcon, MapPinIcon } from "@heroicons/react/24/outline";
import LogRateListType from "../../../../../model/types/LogRateListType";
import { getRateDetailsAPI } from "../../../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../../../redux/functions/function";

const CVDetailsComponent = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const [rateDetails, setRateDetails] = useState<LogRateListType[]>([]);
  const navigate = useNavigate();

  const { rate_details_id } = useParams<string>();

  useEffect(() => {
    getRateDetailsAPI(_token, rate_details_id ?? "").then((response: any) => {
      setRateDetails(response.data.items);
    });
  }, [_token, rate_details_id]);

  const tableHeader1 = [
    {
      name: "rate_details_id",
      value: "Rate Detail No.",
      icon: ClipboardIcon,
    },
    {
      name: "location",
      value: "Location",
      icon: MapPinIcon,
    },
    {
      name: "direction",
      value: "Direction",
      icon: ClipboardIcon,
    },
    {
      name: "freight",
      value: "KM",
      icon: ClipboardIcon,
    },
    {
      name: "pump",
      value: "Pump",
      icon: ClipboardIcon,
    },
    {
      name: "rate",
      value: "Rate",
      icon: ClipboardIcon,
    },
    {
      name: "tax",
      value: "Tax",
      icon: ClipboardIcon,
    },
    {
      name: "total",
      value: "Total",
      icon: ClipboardIcon,
    },
  ];

  return (
    <div className="mt-2">
      <TableComponent
        tableHeader={tableHeader1}
        tableData={rateDetails}
        currentPage={1}
        rowsPerPage={10}
        hasRowClick={true}
        idStringProperty="rate_id" // TODO: change to reverse_auction_id
        emptyState={{
          primaryMessage: "No Data",
          secondaryMessage: "Get started",
          buttonText: "Add Data",
          buttonOnClick() {
            navigate("/l/rates");
          },
        }}
      />
    </div>
  );
};

export default CVDetailsComponent;
