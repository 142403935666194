import { useEffect } from 'react'
import SalesOrderManagementComp from '../../../components/seller/my_orders/SalesOrderManagementComp'
import { useLocation, useNavigate } from 'react-router-dom';
import { useValidateAuth } from '../../../hooks/useValidateAuth';
import { useCheckWebSocketConnection } from '../../../hooks/useCheckWebSocketConnection';

const SalesOrderManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const validateAuth = useValidateAuth(location.pathname);

   /*
    cja: [Hook Purpose]
    - if not refresh, every navigate of the pages does not resend the message by checking the redux state
    - if refresh, reestablish the connection
  */
    useCheckWebSocketConnection(!validateAuth);

    /*
      cja: hook that will handle in validating
      the expiration of authentication token
    */
    useEffect(() => {
      if (validateAuth) {
        navigate("/login");
      }
    }, [navigate, validateAuth]);

  return <SalesOrderManagementComp/>
}

export default SalesOrderManagement