import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import React, { useCallback, useEffect, useState } from "react";
import TableComponent from "../../templates/TableComponent/TableComponent";
import { ClipboardIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import {
  getAccreditationAPI,
  postAccreditationAPI,
} from "../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import AccreditationDetailsType from "../../../model/types/AccreditationDetailsType";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import { useWebSocket } from "../../../services/ws/WebSocketService";
import ToasterComponent from "../../templates/ToasterComponent/ToasterComponent";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const AccreditationComp: React.FC = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState<string>("All");
  const setActiveNavTab = (active_item: string) => {
    setActiveNav(active_item);
  };

  const [accreditDetails, setAccreditDetails] = useState<
    AccreditationDetailsType[]
  >([]);

  const [accreditDetailsFetching, setAccreditDetailsFetching] =
    useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId]: any = useState(null);
  const [isApprovedButtonDisabled, setIsApprovedButtonDisabled] =
    useState<boolean>(true);
  // toast message (start) ===================================>
  const [message, setToastMessage] = useState("");
  const [toastIcon, setToastIcon] = useState("success");
  const [showToast, setShowToast] = useState(false);
  // toast message (start) ===================================>

  //state for websocket (start) =================================================>
  const { socket, sendWebSocketMessage } = useWebSocket();
  //state for websocket (end) =================================================>

  const nav_items = [
    {
      text: "All",
    },
    {
      text: "Approved",
    },
    {
      text: "Pending",
    },
  ];

  const tableHeader = [
    {
      name: "accreditee_name",
      value: "Logistic Name",
      icon: ClipboardIcon,
    },
    {
      name: "plate_number",
      value: "Plate Number",
      icon: ClipboardIcon,
    },
    {
      name: "status",
      value: "Status",
      icon: ClipboardIcon,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const user_id = userData._id.toLocaleString();
      let status = "";
      switch (activeNav) {
        case "All":
          status = "all";
          break;
        case "Approved":
          status = "approved";
          break;
        case "Pending":
          status = "pending";
          break;
      }

      navigate(`/s/my_accreditation/${status}`);

      if (activeNav === "All") {
        getAccreditationAPI(_token, user_id).then((response: any) => {
          setAccreditDetails(response.data.items);
          setAccreditDetailsFetching(false);
        });
      } else if (activeNav === "Approved") {
        getAccreditationAPI(_token, user_id, status).then((response: any) => {
          setAccreditDetails(response.data.items);
          setAccreditDetailsFetching(false);
        });
      } else if (activeNav === "Pending") {
        getAccreditationAPI(_token, user_id, status).then((response: any) => {
          setAccreditDetails(response.data.items);
          setAccreditDetailsFetching(false);
        });
      }

      if (accreditDetails.length > 0 && selectedRowId !== null) {
        setIsApprovedButtonDisabled(false);
      } else {
        setIsApprovedButtonDisabled(true);
      }
    };
    fetchData();
  }, [
    activeNav,
    _token,
    userData._id,
    navigate,
    accreditDetails.length,
    selectedRowId,
    setAccreditDetailsFetching,
  ]);

  const postAccreditHandler = useCallback(() => {
    if (selectedRowId) {
      const selectedDetail = accreditDetails.find(
        (detail) => detail.id === selectedRowId
      );
      if (selectedDetail) {
        const updateDetail = { ...selectedDetail, status: "approved" };
        const data = {
          user_id: userData._id?.toString(),
          vehicle_id: updateDetail.vehicle_id,
          status: updateDetail.status, // Use the status from the selected detail
        };
        postAccreditationAPI(_token, data).then((response: any) => {
          setIsModalOpen(false);
          if (response.statusCode === 200) {
            const updatedAccreditDetails = accreditDetails.map((detail) =>
              detail.id === selectedRowId ? { ...detail, status: "approved" } : detail
            );
            setAccreditDetails(updatedAccreditDetails);
            if (socket && socket.readyState === WebSocket.OPEN) {
              const socketPayload = {
                title: "Vehicle Accreditation Approved",
                message: `${userData.company} approved your vehicle accreditation`,
                user_id: updateDetail.accreditee_id,
                notification_type_id: 1,
                source_id: 1,
              };
              sendWebSocketMessage("sendNotification", socketPayload);
            }
            setToastMessage("Successfully approved!");
            setShowToast(true);
            setToastIcon("success");
            setTimeout(() => {
              setShowToast(false); // Close the toast after 2000ms
            }, 2000);
          }
        });
      }
    }
  }, [
    _token,
    accreditDetails,
    selectedRowId,
    userData._id,
    socket,
    sendWebSocketMessage,
    userData.company,
  ]);

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            <ToasterComponent
              isOpen={showToast}
              label={message}
              onClose={setShowToast}
              type={toastIcon}
            />
            {/*-----------------------------------------------------------------------------------------------------------*/}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="Accreditation"
            />
            {/*-----------------------------------------------------------------------------------------------------------*/}
            <NavTabComponent
              key={Math.random()}
              nav_type="primary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
              left_content={<></>}
            />
            <div className="flex flex-wrap justify-center">
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                {activeNav === "All" && (
                  <>
                    {accreditDetailsFetching ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={accreditDetails}
                        currentPage={1}
                        rowsPerPage={10}
                        idStringProperty="id"
                        handleRowClick={(id: string) => {
                          setSelectedRowId(parseInt(id));
                          setIsModalOpen(true);
                        }}
                        emptyState={{
                          primaryMessage: "No Data",
                          secondaryMessage: "Get Started",
                          buttonText: "Add Data",
                          buttonOnClick() {},
                        }}
                      />
                    )}
                  </>
                )}
                {activeNav === "Approved" && (
                  <>
                    {accreditDetailsFetching ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={accreditDetails}
                        currentPage={1}
                        rowsPerPage={10}
                        idStringProperty="id"
                        handleRowClick={(id: string) => {
                          setSelectedRowId(parseInt(id));
                          setIsModalOpen(true);
                        }}
                        emptyState={{
                          primaryMessage: "No Data",
                          secondaryMessage: "Get Started",
                          buttonText: "Add Data",
                          buttonOnClick() {},
                        }}
                      />
                    )}
                  </>
                )}
                {activeNav === "Pending" && (
                  <>
                    {accreditDetailsFetching ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={accreditDetails}
                        currentPage={1}
                        rowsPerPage={10}
                        idStringProperty="id"
                        handleRowClick={(id: string) => {
                          setSelectedRowId(parseInt(id));
                          setIsModalOpen(true);
                        }}
                        emptyState={{
                          primaryMessage: "No Data",
                          secondaryMessage: "Get Started",
                          buttonText: "Add Data",
                          buttonOnClick() {
                            navigate("/");
                          },
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>

            <ModalComponent
              isOpen={isModalOpen}
              onCloseHandler={() => setIsModalOpen(false)}
              header={
                <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                  <RectangleStackIcon
                    className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                    aria-hidden="true"
                  />
                  <span className="text-white inline md:text-base text-xs">
                    Accreditation Approval
                  </span>
                </div>
              }
              body={
                <>
                  {/* {isDisplaySelectionError && (
                  {isDisplaySelectionError && (
                    <div className="md:px-6 px-5 md:mt-5">
                      <span className="text-sm text-red-600">
                        You can only select up to 3 products
                      </span>
                    </div>
                  )} */}
                  <div className="flex flex-wrap justify-center">
                    <div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
                      <>
                        {accreditDetails.length !== 0 && (
                          <>
                            <div className="md:px-6 px-5 justify-center">
                              <div className="table w-full pt-6">
                                <div className="table-row-group">
                                  {accreditDetails
                                    .filter((list) => list.id === selectedRowId)
                                    .map((list: AccreditationDetailsType) => {
                                      return (
                                        <div
                                          className="text-center"
                                          key={
                                            list.accreditor_id.toString() +
                                            Math.random()
                                          }
                                        >
                                          <div className="table-row">
                                            <div className="table-cell text-center pl-6 md:pl-20">
                                              <span className="font-normal md:text-base text-sm">
                                                {selectedRowId === list.id &&
                                                list.status === "approved"
                                                  ? "This Logistic has already been approved"
                                                  : "Are you sure? Do you want to approve this vehicle?"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row mt-24 md:flex-row gap-4 md:justify-center md:w-4/4 md:mt-4 md:mx-0 mx-5 px-4">
                              <ButtonComponent
                                text="Approved"
                                disabled={isApprovedButtonDisabled}
                                utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 
                                md:mt-7 sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full`}
                                onClick={postAccreditHandler}
                              />
                              <ButtonComponent
                                text="Declined" // Adjust the text for the second button as needed
                                utils={`bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogical-100 text-sm shadow-sm py-3.5 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-7`}
                                onClick={() => setIsModalOpen(false)}
                              />
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default AccreditationComp;
