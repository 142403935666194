import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import {
  formatNumberWithComma,
  getTokenFromLocalStorage,
} from "../../../../redux/functions/function";
import {
  Square3Stack3DIcon,
  TrophyIcon,
  MagnifyingGlassIcon,
  UserIcon,
  ShoppingBagIcon,
  EyeIcon,
  RectangleStackIcon,
  TruckIcon,
  MapPinIcon,
  ExclamationCircleIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import ToggleComponent from "../../../templates/ToggleComponent/ToggleComponent";
import SlideOverComponent from "../../../templates/SlideOverComponent/SlideOverComponent";
import {
  getCreateOfferFields,
  getOfferDetails,
  getOfferOrderList,
  getUtilities,
  setDeliveryLocation,
  setDeliveryLocationList,
  setOfferName,
  setShippingMethodID,
  setShippingMethods,
  setUtilityOfferList,
  setUtilityProductList,
} from "../../../../redux/functions/storageSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import ComboboxComponent from "../../../templates/ComboboxComponent/ComboboxComponent";
import DeliveryLocationModel from "../../../../model/interface/DeliveryLocationModel";
import {
  getMunicipalitiesAPI,
  getOfferDetailsLogisticList,
  getOfferDetailsVariations,
  getOfferList,
  getPhilippineIslandsAPI,
  getProductList,
  getShippingMethods,
  unpublishOffer,
  updateOfferDetails,
  updateOfferVariations,
} from "../../../../redux/functions/API";
import ShippingMethodModel from "../../../../model/interface/ShippingMethodModel";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import OfferVariationTabContentComp from "./OfferVariationTabContentComp";
import ProductType from "../../../../model/types/ProductType";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import ToasterComponent from "../../../templates/ToasterComponent/ToasterComponent";
import SelectedProductsType from "../../../../model/types/SelectedProductsType";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import SupplierLogisticListType from "../../../../model/types/SupplierLogisticListType";

const OfferDetailsComp: React.FC = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const navigate = useNavigate();
  const offerDetails = useAppSelector(getOfferDetails);
  const offerList = useAppSelector(getUtilities).offerList;
  const orderList = useAppSelector(getOfferOrderList).orders;

  // cja: flag for the active nav item
  const [activeNav, setActiveNav] = useState<string>("Offers");
  const [slideOver, setSlideOver] = useState<boolean>(false);
  const [listOfOrders, setListOfOrders] = useState<any[]>([]);

  const [toggleEnabled, setToggleEnabled] = useState<boolean>(true);
  const [isUnpublishWarningModelOpen, setIsUnpublishWarningModelOpen] =
    useState<boolean>(false);

  // cja: if offerlist in redux is empty, it will automatically redirect to offer list page
  useEffect(() => {
    offerList.length === 0 && navigate("/my_offers");
  }, [navigate, offerList]);

  // cja: get offer order list data from redux and store to local state if not empty
  useEffect(() => {
    if (orderList.length !== 0) {
      setListOfOrders(orderList);
    }
  }, [orderList]);

  // DC: setup table header columns
  const tableHeader = [
    // {
    //   name: "reference_number",
    //   value: "Reference Number",
    //   icon: TagIcon,
    // },
    {
      name: "customer_name",
      value: "Customer Name",
      icon: UserIcon,
    },
    {
      name: "requested_commodity",
      value: "Requested Commodity",
      icon: TrophyIcon,
    },
    {
      name: "requested_date",
      value: "Requested Date",
      icon: Square3Stack3DIcon,
    },
    {
      name: "delivery_location",
      value: "Delivery Location",
      icon: Square3Stack3DIcon,
    },
  ];

  const logisticTableHeader = [
    {
      name: "logistic_name",
      value: "Logistic Name",
      icon: ClipboardDocumentIcon,
      isTruncated: false,
    },
    {
      name: "vehicle_name",
      value: "Vehicle",
      icon: TruckIcon,
      isTruncated: false,
    },
    {
      name: "plate_number",
      value: "Plate Number",
      icon: TruckIcon,
      isTruncated: false,
    },
    {
      name: "vehicle_type",
      value: "Vehicle Type",
      icon: TruckIcon,
      isTruncated: false,
    },
    {
      name: "volume",
      value: "Volume",
      icon: Square3Stack3DIcon,
      isTruncated: false,
    },
    {
      name: "status",
      value: "Status",
      icon: TruckIcon,
      isTruncated: false,
    },
  ];

  // cja: hook to fetch list of logistics on the current offer
  const [fetchingLogistic, setFetchingLogistic] = useState<boolean>(true);
  const [possibleLogistics, setPossibleLogistics] = useState<
    SupplierLogisticListType[]
  >([]);
  useEffect(() => {
    if (fetchingLogistic) {
      getOfferDetailsLogisticList(_token, Number(offerDetails.offerID))
        .then((res: any) => {
          setFetchingLogistic(false);
          if (res.statusCode === 200) {
            const { items } = res.data;
            setPossibleLogistics(items);
          }
        })
        .catch((reason: any) => console.log(reason));
    }
  }, [fetchingLogistic, _token, offerDetails.offerID]);

  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const nav_items = [
    {
      text: "Offers",
    },
    {
      text: "Offer Details",
    },
    {
      text: "Offer Variations",
    },
    {
      text: "Logistic",
    },
  ];

  const viewReverseAuctionDetails = (id: string) => {
    navigate(`/s/reverse_auction/${id}`);
  };

  // cja: Offer Details Tab blocks (start)
  // flags
  const [offerDetailsEmptyErr, setOfferDetailsEmptyErr] = useState({
    offerName: false,
    shippingMethod: false,
    deliveryLocation: false,
  });
  const [offerDetailsHasChanges, setOfferDetailsHasChanges] =
    useState<boolean>(false);
  const [isRepublishing, setIsRepublishing] = useState<boolean>(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("");

  // local states
  const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);
  const [productList, setProductList] = useState<ProductType[]>(
    utilities.productList
  );
  const createOfferFields = useAppSelector(getCreateOfferFields);
  const [listOfShippingMethods, setListOfShippingMethods] = useState<
    ShippingMethodModel[]
  >([]);
  const [listOfDeliveryLocations, setListOfDeliveryLocations] = useState<
    DeliveryLocationModel[]
  >(utilities.deliveryLocations);

  // initial values of the offer details
  const [initOfferName, setInitOfferName] = useState<string>("");
  const [initShippingMethod, setInitShippingMethod] = useState<string>("");
  const [initDeliveryLocation, setInitDeliveryLocation] =
    useState<DeliveryLocationModel>({ id: 0, name: "" });

  // flags
  const [offerVariationsHasChanges, setOfferVariationsHasChanges] =
    useState<boolean>(false);
  // cja: state data guide-->[product_id,[variation_id, [min_volume, max_volume, price][]]]
  const [mainVariationFieldArray, setMainVariationFieldArray] = useState<
    [number, [number, [string, string, string]][]][]
  >([]);

  // cja: this hook will fetch the list of products of the current supplier account
  const [isProductListLoading, setIsProductListLoading] =
    useState<boolean>(true);
  useEffect(() => {
    // if commodity types is empty
    if (productList.length === 0 && isProductListLoading) {
      // get and decode the token from local storage
      const userData: TokenModel = jwt_decode(_token);
      const supplier_id: string = userData._id.toString();
      getProductList(_token, 2, supplier_id).then((data: any) => {
        setIsProductListLoading(false);

        // add stocksWithUnits field to an array
        const finalData = data.map((i: ProductType) => ({
          ...i,
          stocksWithUnits: `${formatNumberWithComma(i.stocks)} ${i.unit_name}`,
        }));
        setProductList(finalData);
        dispatch(setUtilityProductList(finalData));
      });
    } else {
      // removed the loading
      setIsProductListLoading(false);
    }
  }, [dispatch, productList, isProductListLoading, _token]);

  // get offer details and it's variation
  // this useEffect will handle in fetching the offer details
  const [isOfferDetailsLoading, setIsOfferDetailsLoading] =
    useState<boolean>(true);
  useEffect(() => {
    if (isOfferDetailsLoading) {
      const userData: TokenModel = jwt_decode(_token);
      const supplier_id: string = userData._id.toString();
      getOfferDetailsVariations(_token, offerDetails.offerID, supplier_id)
        .then((response: any) => {
          setIsOfferDetailsLoading(false);
          if (response.statusCode === 200) {
            // extract offer details
            dispatch(
              setOfferName(response.data.items.offer_details[0].offer_name)
            );
            setInitOfferName(response.data.items.offer_details[0].offer_name);
            dispatch(
              setShippingMethodID(
                response.data.items.offer_details[0].shipping_method_id
              )
            );
            setInitShippingMethod(
              response.data.items.offer_details[0].shipping_method_id
            );
            if (response.data.items.offer_details[0].shipping_method_id === 1) {
              dispatch(
                setDeliveryLocation({
                  id: response.data.items.offer_details[0].municipalities,
                  name: response.data.items.offer_details[0].location_name,
                })
              );
              setInitDeliveryLocation({
                id: response.data.items.offer_details[0].municipalities,
                name: response.data.items.offer_details[0].location_name,
              });
            } else if (
              response.data.items.offer_details[0].shipping_method_id === 2
            ) {
              dispatch(
                setDeliveryLocation({
                  id: response.data.items.offer_details[0].island_id,
                  name: response.data.items.offer_details[0].location_name,
                })
              );
              setInitDeliveryLocation({
                id: response.data.items.offer_details[0].island_id,
                name: response.data.items.offer_details[0].location_name,
              });
            }

            // extract offer variations
            let newReturnedMainVariationFieldArr: [
              number,
              [number, [string, string, string]][]
            ][] = [];

            // remove product duplications
            response.data.items.offer_variations.forEach((product: any) => {
              let newProductVariationArr: any[] = [];
              let productVariation: any[] = product[1]; // any for now
              let addedVariationIDArr: number[] = [];

              productVariation.forEach((variation: any) => {
                if (addedVariationIDArr.length === 0) {
                  newProductVariationArr = [
                    ...newProductVariationArr,
                    variation,
                  ];
                  addedVariationIDArr = [
                    ...addedVariationIDArr,
                    Number(variation[0]),
                  ];
                } else {
                  if (!addedVariationIDArr.includes(variation[0])) {
                    newProductVariationArr = [
                      ...newProductVariationArr,
                      variation,
                    ];
                    addedVariationIDArr = [
                      ...addedVariationIDArr,
                      variation[0],
                    ];
                  }
                }
              });

              newReturnedMainVariationFieldArr = [
                ...newReturnedMainVariationFieldArr,
                [product[0], newProductVariationArr],
              ];
            });

            // re-arrange the variation field array according to product id
            let rearrangedProductsArr: any[] = [];

            // compile all product id out of newRetrunedMainVariationFieldArr variable
            let productIdsArray: number[] = [];
            newReturnedMainVariationFieldArr.forEach((product: any) => {
              if (
                productIdsArray.length === 0 ||
                !productIdsArray.includes(Number(product[0]))
              ) {
                productIdsArray = [...productIdsArray, Number(product[0])];
              }
            });

            // restructure the array
            productIdsArray.forEach((product_id: number) => {
              let newProductVariaionArr: [number, [string, string, string]][] =
                [];
              let addedVariationIDs: number[] = [];
              newReturnedMainVariationFieldArr.forEach((product: any) => {
                if (Number(product[0]) === product_id) {
                  product[1].forEach((productVariation: any) => {
                    if (
                      addedVariationIDs.length === 0 ||
                      !addedVariationIDs.includes(Number(productVariation[0]))
                    ) {
                      newProductVariaionArr = [
                        ...newProductVariaionArr,
                        product[1][0],
                      ];
                    }
                  });
                }
              });

              // add the product with its variations
              rearrangedProductsArr = [
                ...rearrangedProductsArr,
                [product_id, newProductVariaionArr],
              ];
            });

            setMainVariationFieldArray(rearrangedProductsArr);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [isOfferDetailsLoading, offerDetails.offerID, _token, dispatch]);

  // cja: this hook will check if there are changes of the offer details.
  // If there are changes, the republish button will display.
  useEffect(() => {
    if (!isOfferDetailsLoading) {
      if (
        initOfferName !== createOfferFields.offerFields.offerName ||
        initShippingMethod !== createOfferFields.offerFields.shippingMethodID ||
        initDeliveryLocation.id !==
          createOfferFields.offerFields.deliveryLocation.id
      ) {
        setOfferDetailsHasChanges(true);
      } else {
        setOfferDetailsHasChanges(false);
      }
    }
  }, [
    initOfferName,
    initShippingMethod,
    initDeliveryLocation,
    createOfferFields.offerFields.offerName,
    createOfferFields.offerFields.shippingMethodID,
    createOfferFields.offerFields.deliveryLocation.id,
    isOfferDetailsLoading,
  ]);

  // cja: this hook will check if the shipping method redux state is empty or not.
  // If it is empty, it will call an API to fetch shipping method list from database.
  // Else, will store to local state
  useEffect(() => {
    if (utilities.shippingMethods.length === 0) {
      getShippingMethods(_token).then((data: any) => {
        dispatch(setShippingMethods(data));
      });
    } else {
      let updatedShippingMethods: ShippingMethodModel[] = [];
      utilities.shippingMethods.length !== 0 &&
        utilities.shippingMethods.forEach((shipping_method: any) => {
          updatedShippingMethods = [...updatedShippingMethods, shipping_method];
        });
      setListOfShippingMethods(updatedShippingMethods);
    }
  }, [utilities.shippingMethods, dispatch, _token]);

  // cja: this hook will check if the shipping method is delivery or pick-up
  useEffect(() => {
    // 1 - delivery; 2 - pick-up
    // if delivery, then switch the delivery location into municipalities
    // if pick-up, then switch the delivery location into philippine islands
    if (createOfferFields.offerFields.shippingMethodID !== "") {
      let shippingMethod = createOfferFields.offerFields.shippingMethodID;
      if (shippingMethod.toString() === "1") {
        // 2 (cebu island)--> this is the philippine island id (static for now)
        getMunicipalitiesAPI(_token, 2)
          .then((data: any) => {
            dispatch(setDeliveryLocationList(data));
          })
          .catch((err: any) => console.log(err));
      } else if (shippingMethod.toString() === "2") {
        getPhilippineIslandsAPI(_token)
          .then((data: any) => {
            dispatch(setDeliveryLocationList(data));
          })
          .catch((err: any) => console.log(err));
      }
    }
  }, [_token, dispatch, createOfferFields.offerFields.shippingMethodID]);

  // cja: this hook will handle in fetching the municipalities from utilities and
  // store to local state to be populated in the municipality dropdown selection field
  useEffect(() => {
    if (utilities.deliveryLocations.length !== 0) {
      let newDeliveryLocations: DeliveryLocationModel[] = [];
      utilities.deliveryLocations.forEach((location: any) => {
        let singleLocation: DeliveryLocationModel = { id: 0, name: "" };
        if (createOfferFields.offerFields.shippingMethodID.toString() === "1") {
          singleLocation = {
            id: location.id,
            name: location.location,
          };
        } else if (
          createOfferFields.offerFields.shippingMethodID.toString() === "2"
        ) {
          singleLocation = {
            id: location.id,
            name: location.island,
          };
        }
        newDeliveryLocations = [...newDeliveryLocations, singleLocation];
      });
      setListOfDeliveryLocations(newDeliveryLocations);
    }
  }, [
    utilities.deliveryLocations,
    createOfferFields.offerFields.shippingMethodID,
  ]);

  // cja: onChange handler of the create offer fields
  const fieldChangeHandler = (value: string | any, name: string) => {
    switch (name) {
      case "txtoffername":
        dispatch(setOfferName(value));
        break;
      case "txtshippingmethod":
        dispatch(setShippingMethodID(value));
        break;
      case "txtlocation":
        dispatch(setDeliveryLocation(value));
        break;
      default:
        break;
    }
  };
  // cja: Offer Details Tab blocks (end)

  // cja: Offer Variations Tab blocks (start)

  // cja: publish handler validation flags (start)
  // cja: flag if publish button has been click and there are still errors
  const [isPublishedWithErrors, setIsPublishedWithErrors] =
    useState<boolean>(false);
  // validation flag 1 --> product_id[]
  const [publishEmptyValidation, setPublishEmptyValidation] = useState<
    number[]
  >([]);
  // validation flag 2 --> product_id[]
  const [publishComparisonValidation, setPublishComparisonValidation] =
    useState<number[]>([]);
  // validation flag 3 --> product_id[]
  const [publishStockValidation, setPublishStockValidation] = useState<
    number[]
  >([]);
  // cja: publish handler validation flags (end)
  const [selectedProducts, setSelectedProducts] = useState<
    SelectedProductsType[]
  >([]);

  /* cja: edit offer variation click handler function ---> this will enable this offer variations fields
   allowing the users (sellers) to edit the offer variations */
  const editClickHandler = () => {
    setOfferVariationsHasChanges(true);
  };

  // cja: cancel offer variation editing handler function
  const cancelEditClickHandler = () => {
    setIsOfferDetailsLoading(true);
    setOfferVariationsHasChanges(false);
  };

  // cja: Offer Variations Tab blocks (end)
  /* 
    cja: this useEffect hook will extract the product details of the selected items out of the mainVariationFieldArray and productList utility.
    this will be executed if the mainVariationFieldArray has already data

    data structure: [number, [number, [string, string, string]][]]
    data guide    : [product_id, [variation_id, [min, max, price]][]]
  */
  useEffect(() => {
    if (
      mainVariationFieldArray.length !== 0 &&
      utilities.productList.length !== 0
    ) {
      let newSelectedProducts: SelectedProductsType[] = [];
      mainVariationFieldArray.forEach(
        (
          mainVariationField: [number, [number, [string, string, string]][]]
        ) => {
          let fieldProductID = mainVariationField[0];
          let productDetails = null;
          utilities.productList.forEach((product: ProductType) => {
            if (product.product_id.toString() === fieldProductID.toString()) {
              productDetails = product;
            }
          });

          if (productDetails !== null) {
            newSelectedProducts = [
              ...newSelectedProducts,
              { fields: "", product: productDetails },
            ];
          }
        }
      );

      setSelectedProducts(newSelectedProducts);
    }
  }, [mainVariationFieldArray, utilities.productList]);

  // cja: publish main validation -> this useEffect hook will be triggered once there are changes
  // detected in the main_variation_field_arr local state. Then, loop over the array to implement the 3 validaitons
  useEffect(() => {
    if (
      mainVariationFieldArray.length !== 0 &&
      utilities.productList.length !== 0
    ) {
      // reset the flags associated with publish validations
      // validation 1
      setPublishEmptyValidation([]);
      setPublishComparisonValidation([]);
      setPublishStockValidation([]);
      setIsPublishedWithErrors(false);

      // initialize new publish empty fields
      let newPublishEmptyValidation: number[] = [];
      // initialize the new array of comparison invalid fields
      let newPublishComparisonValidation: number[] = [];
      // initialize the new array of stock validation fields
      let newPublishStockValidation: number[] = [];
      // loop over the main variation field array
      mainVariationFieldArray.forEach(
        (
          main_variation_field: [number, [number, [string, string, string]][]]
        ) => {
          const product_id: number = main_variation_field[0];
          let stock: number = 0;
          selectedProducts.length !== 0 &&
            selectedProducts.forEach((product: SelectedProductsType) => {
              if (product.product.product_id === product_id) {
                stock = Number(
                  product.product.stocks
                    .toString()
                    .replaceAll(" ltrs", "")
                    .replaceAll(",", "")
                );
              }
            });

          // loop over the variation field on every product
          main_variation_field[1].forEach(
            (variation_field: [number, [string, string, string]]) => {
              // extract fields
              const min: string = variation_field[1][0];
              const max: string = variation_field[1][1];
              const price: string = variation_field[1][2];

              // empty validation
              if (min === "" || max === "" || price === "") {
                newPublishEmptyValidation = [
                  ...newPublishEmptyValidation,
                  product_id,
                ];
              } else if (Number(min) >= Number(max)) {
                newPublishComparisonValidation = [
                  ...newPublishComparisonValidation,
                  product_id,
                ];
              } else if (Number(min) >= stock || Number(max) > stock) {
                newPublishStockValidation = [
                  ...newPublishStockValidation,
                  product_id,
                ];
              }
            }
          );
        }
      );

      setPublishEmptyValidation(newPublishEmptyValidation);
      setPublishComparisonValidation(newPublishComparisonValidation);
      setPublishStockValidation(newPublishStockValidation);
    }
  }, [mainVariationFieldArray, selectedProducts, utilities.productList.length]);

  const updateOfferDetailsFunction = (supplier_id: string) => {
    return new Promise((resolve, reject) => {
      setOfferDetailsEmptyErr({
        offerName: false,
        shippingMethod: false,
        deliveryLocation: false,
      });

      let data = {
        offer_id: offerDetails.offerID,
        offer_name: createOfferFields.offerFields.offerName,
        shipping_method_id: createOfferFields.offerFields.shippingMethodID,
        delivery_location_id:
          createOfferFields.offerFields.deliveryLocation.id.toString(),
        supplier_id: supplier_id,
      };

      updateOfferDetails(_token, data)
        .then((response: any) => {
          if (response.statusCode === 200) {
            resolve(true);
          }
        })
        .catch((err) => console.log(err));
    });
  };

  const updateOfferVariationsFunction = () => {
    return new Promise((resolve, reject) => {
      let data = {
        offer_id: offerDetails.offerID,
        variation_items: mainVariationFieldArray,
      };

      updateOfferVariations(_token, data)
        .then((response: any) => {
          if (response.statusCode === 200) {
            resolve(true);
          }
        })
        .catch((err) => console.log(err));
    });
  };

  const republishClickHandler = () => {
    setIsRepublishing(true);
    const userData: TokenModel = jwt_decode(_token);
    const supplier_id: string = userData._id.toString();
    // validations ================================================================== (start)
    // if validation errors detected, this handler will be terminated where the error is detected
    if (offerDetailsHasChanges) {
      if (
        createOfferFields.offerFields.offerName === "" ||
        createOfferFields.offerFields.shippingMethodID === "" ||
        createOfferFields.offerFields.deliveryLocation.id === 0
      ) {
        setIsRepublishing(false);
        if (createOfferFields.offerFields.offerName === "") {
          setOfferDetailsEmptyErr((prev) => ({ ...prev, offerName: true }));
        }
        if (createOfferFields.offerFields.shippingMethodID === "") {
          setOfferDetailsEmptyErr((prev) => ({
            ...prev,
            shippingMethod: true,
          }));
        }
        if (createOfferFields.offerFields.deliveryLocation.id === 0) {
          setOfferDetailsEmptyErr((prev) => ({
            ...prev,
            deliveryLocation: true,
          }));
        }

        return;
      }
    }
    if (offerVariationsHasChanges) {
      if (publishEmptyValidation.length !== 0) {
        setIsPublishedWithErrors(true);
        setIsRepublishing(false);
        return;
      } else if (publishComparisonValidation.length !== 0) {
        setIsPublishedWithErrors(true);
        setIsRepublishing(false);
        return;
      } else if (publishStockValidation.length !== 0) {
        setIsPublishedWithErrors(true);
        setIsRepublishing(false);
        return;
      }
    }
    // validations ================================================================== (end)

    // Update Offer API Call ======================================================== (start)
    /*
      if no validation errors detected, proceed in updating the offer

      Conditions:
      (1) Offer details changes + No offer variation changes + No empty offer details fields
      (2) No offer details changes + Offer variation changes + No variation errors
      (3) Offer details changes + Offer variation changes + No empty offer details fields + No variation errors
    */
    // check if there is an empty offer details fields
    const offerDetailsHasEmptyError =
      Object.values(offerDetailsEmptyErr).includes(true);
    if (
      offerDetailsHasChanges &&
      !offerVariationsHasChanges &&
      !offerDetailsHasEmptyError
    ) {
      updateOfferDetailsFunction(supplier_id).then((response: any) => {
        if (response) {
          setToastMessage(
            `${offerDetails.offerName} republished successfully.`
          );
          setToastType("success");
          setIsToastOpen(true);
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
          setTimeout(() => {
            getOfferList(_token, 1, supplier_id).then((data: any) => {
              dispatch(setUtilityOfferList(data));
            });
          }, 3500);
          setTimeout(() => {
            navigate("/my_offers");
          }, 4000);
        }
        return;
      });
    }

    if (
      !offerDetailsHasChanges &&
      !isPublishedWithErrors &&
      offerVariationsHasChanges
    ) {
      updateOfferVariationsFunction().then((response: any) => {
        if (response) {
          setToastMessage(`Offer variations has been updated successfully`);
          setToastType("success");
          setIsToastOpen(true);
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
          setTimeout(() => {
            getOfferList(_token, 1, supplier_id).then((data: any) => {
              dispatch(setUtilityOfferList(data));
            });
          }, 3500);
          setTimeout(() => {
            navigate("/my_offers");
          }, 4000);
        }
      });
    }
    if (
      offerDetailsHasChanges &&
      offerVariationsHasChanges &&
      !isPublishedWithErrors &&
      !offerDetailsHasEmptyError
    ) {
      updateOfferDetailsFunction(supplier_id).then((response1: any) => {
        if (response1) {
          updateOfferVariationsFunction().then((response2: any) => {
            if (response2) {
              setToastMessage(
                `Offer details and variations have been successfully updated.`
              );
              setToastType("success");
              setIsToastOpen(true);
              setTimeout(() => {
                setIsToastOpen(false);
              }, 3000);
              setTimeout(() => {
                getOfferList(_token, 1, supplier_id).then((data: any) => {
                  dispatch(setUtilityOfferList(data));
                });
              }, 3500);
              setTimeout(() => {
                navigate("/my_offers");
              }, 4000);
            }
          });
        }
      });
    }
    // Update Offer API Call ======================================================== (end)
  };

  // cja: this function will extract the product name through product id param
  const extractProductName = (product_id: string) => {
    let productName = "";
    utilities.productList.length !== 0 &&
      utilities.productList.forEach((product: any) => {
        if (product.product_id.toString() === product_id) {
          productName = product.commodity_name;
        }
      });

    return productName;
  };

  // cja: this function is responsible for displaying any error messages while publishing is in progress.
  const displayPublishErrorMessages = () => {
    if (publishEmptyValidation.length !== 0 && isPublishedWithErrors === true) {
      return (
        <div className={`flex flex-wrap justify-center mt-5`}>
          <div className="flex flex-col w-full md:w-full">
            <span className="text-red-600 text-sm text-center">
              {`Please fill in the empty variation fields under ${extractProductName(
                publishEmptyValidation[0].toString()
              )}`}
            </span>
          </div>
        </div>
      );
    } else if (
      publishComparisonValidation.length !== 0 &&
      isPublishedWithErrors === true
    ) {
      return (
        <div className={`flex flex-wrap justify-center mt-5`}>
          <div className="flex flex-col w-full md:w-full">
            <span className="text-red-600 text-sm text-center">
              {`Invalid minimum and maximum volume in ${extractProductName(
                publishComparisonValidation[0].toString()
              )} commodity. Ensure minimum is always less than maximum.`}
            </span>
          </div>
        </div>
      );
    } else if (
      publishStockValidation.length !== 0 &&
      isPublishedWithErrors === true
    ) {
      return (
        <div className={`flex flex-wrap justify-center mt-5`}>
          <div className="flex flex-col w-full md:w-full">
            <span className="text-red-600 text-sm text-center">
              {`Invalid volume in ${extractProductName(
                publishStockValidation[0].toString()
              )} commodity. Ensure minimum/maximum doesn't exceed the stock.`}
            </span>
          </div>
        </div>
      );
    } else {
      <></>;
    }
  };

  // cja: unpublish toggle blocks (start)
  useEffect(() => {
    if (toggleEnabled) {
      //alert("ready to publish");
    } else {
      setIsUnpublishWarningModelOpen(true);
    }
  }, [toggleEnabled]);

  const [isUnpublishing, setIsUnpublishing] = useState<boolean>(false);

  const warningDialogResultHandler = (result: string) => {
    if (result === "yes") {
      // call validate offer existing transactions API here
      // assuming we already validated the existing transactions of this offer
      setIsUnpublishing(true);
      let unpublishOfferData = {
        offer_id: offerDetails.offerID,
        status: 0,
      };
      unpublishOffer(_token, unpublishOfferData)
        .then((response: any) => {
          setIsUnpublishWarningModelOpen(false);
          if (response.statusCode === 200) {
            const userData: TokenModel = jwt_decode(_token);
            const supplier_id: string = userData._id.toString();

            setToastMessage(
              `${offerDetails.offerName} has been successfully unpublished.`
            );
            setToastType("success");
            setIsToastOpen(true);
            setTimeout(() => {
              setIsToastOpen(false);
            }, 3000);
            setTimeout(() => {
              getOfferList(_token, 1, supplier_id).then((data: any) => {
                dispatch(setUtilityOfferList(data));
              });
            }, 3500);
            setTimeout(() => {
              navigate("/my_offers");
            }, 4000);
          } else {
            setToggleEnabled(true);
            setToastMessage(response.data.message);
            setToastType("error");
            setIsToastOpen(true);
            setTimeout(() => {
              setIsToastOpen(false);
            }, 3000);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsUnpublishWarningModelOpen(false);
      setToggleEnabled(true);
    }
  };

  // cja: unpublish toggle blocks (end)

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            <ToasterComponent
              isOpen={isToastOpen}
              label={toastMessage}
              onClose={setIsToastOpen}
              type={toastType}
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle={offerDetails.offerName}
              republishButton={
                offerDetailsHasChanges || offerVariationsHasChanges ? (
                  <ButtonComponent
                    text={`${isRepublishing ? "Republishing" : "Republish"}`}
                    utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:mt-7 mt-2 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full ${
                      isRepublishing ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    onClick={republishClickHandler}
                    disabled={isRepublishing}
                  />
                ) : undefined
              }
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <NavTabComponent
              active_tab={activeNav}
              nav_type="primary"
              key={Math.random()}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNav}
              left_content={
                <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full">
                  <input
                    type="text"
                    name="account-number"
                    id="account-number"
                    className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                    placeholder="Search products"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5">
                    <MagnifyingGlassIcon
                      className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              }
              right_content={
                <>
                  <div className="cursor-pointer md:mt-0 mt-4 md:mb-0 mb-4 self-start md:self-end">
                    <div className="flex gap-4 justify-start md:justify-end">
                      <span className="text-sm md:text-base">
                        {toggleEnabled ? "Unpublish" : "Publish"}
                      </span>
                      <div className="">
                        <ToggleComponent
                          toggleEnabled={toggleEnabled}
                          setToggleEnabled={setToggleEnabled}
                        />
                      </div>
                    </div>
                    <div
                      className="flex gap-8 mt-2"
                      onClick={() => setSlideOver(true)}
                    >
                      {/* sold */}
                      <div className="flex gap-1">
                        <div className="my-auto">
                          <ShoppingBagIcon className="w-4 h-4" />
                        </div>
                        <div className="text-sm md:text-base">
                          {formatNumberWithComma(offerDetails.sold)} sold
                        </div>
                      </div>

                      {/* views */}
                      <div className="flex gap-1">
                        <div className="my-auto">
                          <EyeIcon className="w-4 h-4" />
                        </div>
                        <div className="text-sm md:text-base">
                          {formatNumberWithComma(offerDetails.views)} view(s)
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
            <SlideOverComponent
              slideOver={slideOver}
              setSlideOver={setSlideOver}
              bodyContent={<></>}
            />
            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                {activeNav === "Offers" && (
                  <>
                    <TableComponent
                      tableHeader={tableHeader}
                      tableData={listOfOrders}
                      currentPage={1} // DC: static for now, lets make it dynamic soon
                      rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                      hasRowClick={true}
                      idStringProperty="reverse_auction_id"
                      handleRowClick={viewReverseAuctionDetails}
                      emptyState={{
                        primaryMessage: "No orders available.",
                        secondaryMessage: "Get started by adding a new offer.",
                        buttonText: "Add an Offer",
                        buttonOnClick() {
                          navigate("/s/add_product_offer");
                        },
                      }}
                    />
                  </>
                )}
                {activeNav === "Offer Details" && (
                  <>
                    <div className="flex flex-wrap justify-center">
                      <div className="flex flex-col w-full md:pl-8 md:pr-10 md:w-1/2">
                        <label
                          htmlFor="txtoffername"
                          className="block text-sm font-medium leading-6 text-gray-900 mt-8"
                        >
                          <div className="flex gap-2">
                            <RectangleStackIcon className="-ml-0.5 h-5 w-5" />
                            <span>Offer Name</span>
                          </div>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="txtoffername"
                            id="txtoffername"
                            value={createOfferFields.offerFields.offerName}
                            placeholder="Set offer name"
                            className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ${
                              !offerDetailsEmptyErr.offerName
                                ? "ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200"
                                : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                            } placeholder:text-gray-400 text-sm sm:leading-6`}
                            onChange={(e) =>
                              fieldChangeHandler(e.target.value, "txtoffername")
                            }
                          />
                        </div>
                        {offerDetailsEmptyErr.offerName && (
                          <div className="m-2 ml-6 flex">
                            <p className="text-red-600 text-xs md:text-sm">
                              Offer name field is required
                            </p>
                          </div>
                        )}

                        <label
                          htmlFor="txtshippingmethod"
                          className="block text-sm font-medium leading-6 text-gray-900 md:mt-5 mt-6"
                        >
                          <div className="flex gap-2">
                            <TruckIcon className="-ml-0.5 h-5 w-5" />
                            <span>Shipping Method</span>
                          </div>
                        </label>
                        <div className="mt-2">
                          <select
                            id="txtshippingmethod"
                            name="txtshippingmethod"
                            value={
                              createOfferFields.offerFields.shippingMethodID
                            }
                            className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ${
                              !offerDetailsEmptyErr.shippingMethod
                                ? "ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200"
                                : "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
                            } text-sm sm:leading-6`}
                            onChange={(e) =>
                              fieldChangeHandler(
                                e.target.value,
                                "txtshippingmethod"
                              )
                            }
                          >
                            <option value="">Select shipping method</option>
                            {listOfShippingMethods.map(
                              (shipping_method: any) => {
                                return (
                                  <option
                                    key={shipping_method.id}
                                    value={shipping_method.id}
                                  >
                                    {shipping_method.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        {offerDetailsEmptyErr.shippingMethod && (
                          <div className="m-2 ml-6 flex">
                            <p className="text-red-600 text-xs md:text-sm">
                              Shipping method field is required
                            </p>
                          </div>
                        )}

                        <label
                          htmlFor="txtlocation"
                          className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                        >
                          <div className="flex gap-2">
                            <MapPinIcon className="-ml-0.5 h-5 w-5" />
                            <span>
                              Delivery Location
                              {/* {renderLocationSubLabel()} */}
                            </span>
                          </div>
                        </label>
                        <div className="mt-2">
                          <ComboboxComponent
                            hasError={offerDetailsEmptyErr.deliveryLocation}
                            items={listOfDeliveryLocations}
                            placeholder="Select Delivery Location"
                            selectedItem={
                              createOfferFields.offerFields.deliveryLocation
                            }
                            setSelectedItem={(e: DeliveryLocationModel) =>
                              fieldChangeHandler(e, "txtlocation")
                            }
                          />
                        </div>
                        {offerDetailsEmptyErr.deliveryLocation && (
                          <div className="m-2 ml-6 flex">
                            <p className="text-red-600 text-xs md:text-sm">
                              Delivery location field is required
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-full md:pl-8 md:w-1/2"></div>
                    </div>
                  </>
                )}
                {activeNav === "Offer Variations" && (
                  <>
                    {displayPublishErrorMessages()}
                    <OfferVariationTabContentComp
                      selectedProducts={selectedProducts}
                      setSelectedProducts={setSelectedProducts}
                      offerVariationsHasChanges={offerVariationsHasChanges}
                      editClickHandler={editClickHandler}
                      cancelEditClickHandler={cancelEditClickHandler}
                      mainVariationFieldArray={mainVariationFieldArray}
                      setMainVariationFieldArray={setMainVariationFieldArray}
                      setIsOfferDetailsLoading={setIsOfferDetailsLoading}
                    />
                  </>
                )}
                {activeNav === "Logistic" && (
                  <>
                    <TableComponent
                      tableHeader={logisticTableHeader}
                      tableData={possibleLogistics}
                      currentPage={1} // DC: static for now, lets make it dynamic soon
                      rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                      hasRowClick={true}
                      idStringProperty="reverse_auction_id"
                      handleRowClick={() => alert("Unavailable feature!")}
                      emptyState={{
                        hasCallToAction: false,
                        primaryMessage: "No logistics available.",
                        secondaryMessage: "",
                        buttonText: "Add an Offer",
                        buttonOnClick() {
                          navigate("/s/add_product_offer");
                        },
                      }}
                    />
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />

      <ModalComponent
        isOpen={isUnpublishWarningModelOpen}
        onCloseHandler={() => setIsUnpublishWarningModelOpen(false)}
        header={
          <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
            <RectangleStackIcon
              className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
              aria-hidden="true"
            />
            <span className="text-white inline md:text-base text-xs">
              Unpublish Confirmation
            </span>
          </div>
        }
        body={
          <>
            <h1>{`Are you sure to unpublish ${offerDetails.offerName}? `}</h1>
          </>
        }
      />

      <ModalComponent
        isOpen={isUnpublishWarningModelOpen}
        onCloseHandler={() => {
          setIsUnpublishWarningModelOpen(false);
        }}
        header={<></>}
        body={
          <>
            <div className="flex flex-wrap justify-center mt-2 mx-20">
              {/* single card (start) */}
              <div className="flex flex-col py-5 w-full md:w-full items-center">
                <ExclamationCircleIcon
                  className="mr-1.5 h-20 w-20 flex-shrink-0 text-red-600"
                  aria-hidden="true"
                />
                <span className="text-lg text-gray-500 font-bold mt-2">
                  Warning
                </span>
              </div>
              <div className="flex flex-col w-full py-2 px-5 md:w-full">
                <span className="text-lg text-gray-500 text-center">
                  Are you sure to unpublish this offer?
                </span>
              </div>
              <div className="inline-flex w-full justify-center pt-10 pb-12 md:w-full">
                <ButtonComponent
                  text={`${isUnpublishing ? "Unpublishing..." : "Yes"}`}
                  utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-1/4"
                  onClick={() => warningDialogResultHandler("yes")}
                />
                <ButtonComponent
                  text={`No`}
                  utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4"
                  onClick={() => warningDialogResultHandler("no")}
                />
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};
export default OfferDetailsComp;
