import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { generateKey } from "../../../redux/functions/function";
import { PaginationModel } from "../../../model/interface/PaginationModel";
import { ReactNode } from "react";

export default function PaginationComponent(props: PaginationModel) {
  // DC: declare variables here...
  const totalNumOfPages = Math.ceil(props.totalRows / props.rowsPerPage);

  // DC: generate paginations here...
  let pagesComponent: ReactNode[] = [];
  for (let i = 1; i <= totalNumOfPages; i++) {
    pagesComponent.push(
      <span
        key={generateKey(i.toString())}
        className={`${
          i === props.currentPage
            ? "bg-dealogikal-100 text-white hover:bg-dealogikal-200 hover:text-white ring-1 ring-inset ring-dealogikal-100"
            : "hover:bg-gray-100 ring-1 ring-inset ring-gray-300"
        } relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 focus:z-20 focus:outline-offset-0`}
      >
        {i}
      </span>
    );
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
      {/* will only display it to mobile devices */}
      <div className="flex flex-1 justify-between sm:hidden mt-4 mx-2">
        <a
          href="/"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 md:text-sm text-xs font-medium text-gray-700 hover:bg-gray-100"
        >
          Previous
        </a>
        <a
          href="/"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 md:text-sm text-xs font-medium text-gray-700 hover:bg-gray-100"
        >
          Next
        </a>
      </div>

      {/* will only display this from small to large screens */}
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mt-4">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-bold">
              {props.currentPage === 1
                ? props.currentPage * 1
                : props.currentPage * props.rowsPerPage -
                  (props.rowsPerPage - 1)}
            </span>{" "}
            to{" "}
            <span className="font-bold">
              {props.totalRows <
              props.rowsPerPage /* if total rows is lower than the rows per page */
                ? props.totalRows /* return the total rows */
                : props.currentPage * props.rowsPerPage}
            </span>{" "}
            of <span className="font-bold">{props.totalRows}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span
              className="relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-black
             ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            {pagesComponent}
            <span
              className="relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-black
             ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
}
